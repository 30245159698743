/*
import React, { useState, useEffect } from 'react';
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db } from '../../../firebase/firebase';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import './Finder.css';

function InputFinder({ searchTerm: propSearchTerm, onSearch }) {  
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState(propSearchTerm || '');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setSearchTerm(propSearchTerm || '');
    }, [propSearchTerm]);

    const saveSearchTermToFirestore = async (term) => {
        try {
            await addDoc(collection(db, 'IntesJob'), {
                term: term,
                count: 1,
                timestamp: Timestamp.now() // Use Firebase's Timestamp
            });
        } catch (error) {
            console.error("Error saving search term: ", error);
        }
    };

    function goQuery() {
        navigate(`/Search?q=${encodeURIComponent(searchTerm)}`);
        if (typeof onSearch === 'function') {
            onSearch(searchTerm);
        }
        saveSearchTermToFirestore(searchTerm); // Save search term to Firestore
    }

    function handleInputChange(event) {
        setSearchTerm(event.target.value);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            goQuery();
        }
    }

    return (
        <div className="cont-main-finder">
            <div className="con-buscador-query">
                <IoSearch className="iconSearch" size={30} />
                <input
                    type="text"
                    placeholder={t('Employment Interest')}
                    className="search-input-query"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                />
            </div>
        </div>
    );
}

export default InputFinder;

*/

import React, { useState, useEffect, useRef } from 'react';
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db } from '../../../firebase/firebase';
import { addDoc, collection, getDocs, query, where, setDoc, Timestamp } from 'firebase/firestore';
import './Finder.css';

function InputFinder({ searchTerm: propSearchTerm, onSearch }) {  
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState(propSearchTerm || '');
    const [suggestions, setSuggestions] = useState([]);
    const { t } = useTranslation();
    const wrapperRef = useRef(null);

    useEffect(() => {
        setSearchTerm(propSearchTerm || '');
    }, [propSearchTerm]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (searchTerm.trim() !== '') {
                const q = query(collection(db, 'IntesJob'), where('term', '>=', searchTerm), where('term', '<=', searchTerm + '\uf8ff'));
                const querySnapshot = await getDocs(q);
                const fetchedSuggestions = [];
                querySnapshot.forEach((doc) => {
                    fetchedSuggestions.push(doc.data().term);
                });
                setSuggestions(fetchedSuggestions);
            } else {
                setSuggestions([]);
            }
        };

        fetchSuggestions();
    }, [searchTerm]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSuggestions([]);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const saveSearchTermToFirestore = async (term) => {
        try {
            const q = query(collection(db, 'IntesJob'), where('term', '==', term));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach(async (doc) => {
                    const existingData = doc.data();
                    await setDoc(doc.ref, {
                        ...existingData,
                        count: existingData.count + 1,
                        timestamp: Timestamp.now()
                    });
                });
            } else {
                await addDoc(collection(db, 'IntesJob'), {
                    term: term,
                    count: 1,
                    timestamp: Timestamp.now()
                });
            }
        } catch (error) {
            console.error("Error saving search term: ", error);
        }
    };

    function goQuery() {
        if (searchTerm.trim() === '') {
            return; // If the search term is empty, don't do anything
        }

        navigate(`/employmentInterest?q=${encodeURIComponent(searchTerm)}`);
        
        if (typeof onSearch === 'function') {
            onSearch(searchTerm);
        }
        
        saveSearchTermToFirestore(searchTerm); // Save search term to Firestore if it's not empty
        setSearchTerm(''); // Clear the input field
        setSuggestions([]); // Clear suggestions
    }

    function handleInputChange(event) {
        setSearchTerm(event.target.value);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            goQuery();
        }
    }

    function handleSuggestionClick(suggestion) {
        setSearchTerm(suggestion);
        setSuggestions([]);
        goQuery();
    }

    return (
        <div className="cont-main-finder7" ref={wrapperRef}>
            <div className={`con-buscador-query7 ${suggestions.length > 0 ? 'suggestions-visible' : ''}`}>
                <IoSearch className="iconSearch7" size={30} />
                <input
                    type="text"
                    placeholder={t('Employment Interest')}
                    className="search-input-query7"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                />
                {suggestions.length > 0 && (
                    <ul className="autocomplete-suggestions">
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                className="suggestion-item"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default InputFinder;
