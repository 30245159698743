/*

import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import IconName from '../IconName/IconName';
import ChatBody from '../chatBody/ChatBody';
 
import './listChat.css';
import ChatInput from '../chatInput/ChatInput';
import BottonesFiles from '../bottonesFiles/BottonesFiles';

function ListChat({ currentUid, searchQuery }) {
  const [chats, setChats] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChatSelect = (chat) => {
    // Move the selected chat to the top of the list
    setChats((prevChats) => {
      const updatedChats = prevChats.filter(c => c.id !== chat.id);
      return [chat, ...updatedChats];
    });

    // Update the selected chat and chat ID
    setSelectedChat(chat);
    setSelectedChatId(chat.id);

    // Clear messages (assuming this is desired behavior)
    setMessages([]);
  };

  useEffect(() => {
    if (!currentUid) {
      console.error("No user logged in");
      return;
    }

    const processChats = (snapshot) => {
      const uniquePartnerUIDs = new Set(); // Store unique partner UIDs
      const newChats = [];
      let selfChatIncluded = false; // Flag to check if a self-chat has been added

      snapshot.forEach(doc => {
        const chat = doc.data();
        const partnerUID = chat.createdBy === currentUid ? chat.SendTo : chat.createdBy;

        // Check if both SendTo and createdBy are the same as currentUid (self-chat)
        if (chat.createdBy === currentUid && chat.SendTo === currentUid) {
          if (!selfChatIncluded) {
            newChats.push({ ...chat, id: doc.id, partnerUID: currentUid });
            selfChatIncluded = true; // Ensure only one self-chat is added
          }
          return; // Skip further processing to avoid adding duplicate self-chat
        }

        // For other chats, ensure the partnerUID is unique and not the current user
        if (!uniquePartnerUIDs.has(partnerUID)) {
          uniquePartnerUIDs.add(partnerUID); // Add to the set to ensure uniqueness
          newChats.push({ ...chat, id: doc.id, partnerUID });
        }
      });
      return newChats;
    };

    const fetchChats = () => {
      const chatQueries = [
        query(collection(db, 'chats'), where('createdBy', '==', currentUid)),
        query(collection(db, 'chats'), where('SendTo', '==', currentUid)),
      ];

      const unsubscribeHandles = chatQueries.map(chatQuery =>
        onSnapshot(chatQuery, (snapshot) => {
          const updatedChats = processChats(snapshot);
          setChats(prevChats => {
            // Ensure no duplicates and merge with existing chats
            const mergedChats = new Map([...prevChats.map(chat => [chat.partnerUID, chat]), ...updatedChats.map(chat => [chat.partnerUID, chat])]);
            return Array.from(mergedChats.values());
          });
        })
      );

      // Return a cleanup function to unsubscribe from updates
      return () => unsubscribeHandles.forEach(unsubscribe => unsubscribe());
    };

    const unsubscribe = fetchChats();
    return unsubscribe; // Cleanup on component unmount
  }, [currentUid]);

  useEffect(() => {
    // Reset selectedChat when searchQuery changes
    setSelectedChat(null);
  }, [searchQuery]);

  return (
    <div className="conChido">
      <div className="con1" style={{ maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
        {chats.length > 0 ? (
          chats.map((chat) => (
            <div key={chat.id} onClick={() => handleChatSelect(chat)} className="conlineSelec" style={{ backgroundColor: selectedChatId === chat.id ? '#5418ED' : 'initial' }}>
              <IconName 
                currentUid={currentUid} 
                profilesUids={[chat.partnerUID]} 
                searchQuery={searchQuery} 
                isMobile={isMobile} 
                selectedChat={selectedChat}
              />
            </div>
          ))
        ) : (
          <div>No chats found</div>
        )}
      </div>

      <div className="con1">
        {!selectedChat ? (
          <></>
        ) : (
          <div className="contChatInput">
            <div className="chatListCon">
              <ChatBody selectedChat={selectedChat} currentUid={currentUid} messages={messages} setMessages={setMessages} />
            </div>
            <div className="bodyMindIn">
              <BottonesFiles selectedChat={selectedChat} currentUid={currentUid} messages={messages} setMessages={setMessages} />
              <ChatInput selectedChat={selectedChat} currentUid={currentUid} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListChat;

*/
import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import IconName from '../IconName/IconName';
import ChatBody from '../chatBody/ChatBody';
import './listChat.css';
import ChatInput from '../chatInput/ChatInput';
import BottonesFiles from '../bottonesFiles/BottonesFiles';
import { FaEnvelope } from "react-icons/fa";

import MessageImage from '../../../assets/userImage.png'; 

function ListChat({ currentUid, searchQuery }) {
  const [chats, setChats] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChatSelect = (chat) => {
    setChats((prevChats) => {
      const updatedChats = prevChats.filter(c => c.id !== chat.id);
      return [chat, ...updatedChats];
    });
    setSelectedChat(chat);
    setSelectedChatId(chat.id);
    setMessages([]);
  };

  useEffect(() => {
    if (!currentUid) {
      console.error("No user logged in");
      return;
    }

    const processChats = (snapshot) => {
      const uniquePartnerUIDs = new Set(); 
      const newChats = [];
      let selfChatIncluded = false;

      snapshot.forEach(doc => {
        const chat = doc.data();
        const partnerUID = chat.createdBy === currentUid ? chat.SendTo : chat.createdBy;

        if (chat.createdBy === currentUid && chat.SendTo === currentUid) {
          if (!selfChatIncluded) {
            newChats.push({ ...chat, id: doc.id, partnerUID: currentUid });
            selfChatIncluded = true;
          }
          return;
        }

        if (!uniquePartnerUIDs.has(partnerUID)) {
          uniquePartnerUIDs.add(partnerUID);
          newChats.push({ ...chat, id: doc.id, partnerUID });
        }
      });
      return newChats;
    };

    const fetchChats = () => {
      const chatQueries = [
        query(collection(db, 'chats'), where('createdBy', '==', currentUid)),
        query(collection(db, 'chats'), where('SendTo', '==', currentUid)),
      ];

      const unsubscribeHandles = chatQueries.map(chatQuery =>
        onSnapshot(chatQuery, (snapshot) => {
          const updatedChats = processChats(snapshot);
          setChats(prevChats => {
            const mergedChats = new Map([...prevChats.map(chat => [chat.partnerUID, chat]), ...updatedChats.map(chat => [chat.partnerUID, chat])]);
            return Array.from(mergedChats.values());
          });
        })
      );

      return () => unsubscribeHandles.forEach(unsubscribe => unsubscribe());
    };

    const unsubscribe = fetchChats();
    return unsubscribe;
  }, [currentUid]);

  useEffect(() => {
    setSelectedChat(null);
  }, [searchQuery]);

  return (
    <div className="conChido">
      <div className={`con1 ${isMobile && selectedChat ? 'hidden' : ''}`} style={{ maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
        {chats.length > 0 ? (
          chats.map((chat) => (
            <div key={chat.id} onClick={() => handleChatSelect(chat)} className="conlineSelec" style={{ backgroundColor: selectedChatId === chat.id ? '#5418ED' : 'initial' }}>
              <IconName 
                currentUid={currentUid} 
                profilesUids={[chat.partnerUID]} 
                searchQuery={searchQuery} 
                isMobile={isMobile} 
                selectedChat={selectedChat}
              />
            </div>
          ))
        ) : (
          <div className="noChatsContainer">
             <img src={MessageImage} alt="Success" className="successImage" /> 
                 
                  <p className="noChatsMessage">No chats yet</p> 
                  <p className="noChatsMessage">Send a message to someone!</p> {/* Display the message */}
          </div>
        )}
      </div>
    
      <div className="con1">
        {!selectedChat ? (
          <></>
        ) : (
          <div className="contChatInput">
            {isMobile && selectedChat && (
              <button onClick={() => setSelectedChat(null)} className="goBackButton">Go Back</button>
            )}
            <div className="chatListCon">
              <ChatBody selectedChat={selectedChat} currentUid={currentUid} messages={messages} setMessages={setMessages} />
            </div>
            <div className="bodyMindIn">
              <BottonesFiles selectedChat={selectedChat} currentUid={currentUid} messages={messages} setMessages={setMessages} />
              <ChatInput selectedChat={selectedChat} currentUid={currentUid} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListChat;

