import React from 'react'
import Dashboard from '../components/dashBoard'

function Prueva() {
  return (
    <div>
        <Dashboard/> 
    </div>
  )
}

export default Prueva