/*
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Swal from 'sweetalert2';
import { db } from '../../firebase/firebase'

import HeaderAddPost from '../dashBoard/Header';
import CartaPerfiluser from '../../components/compPerfilUser/CartaPerfilUser'// is TABLE
import AddEmploye from './AddEmploye';
import EditPerfil from './EditPerfil'



function Index({ setIsAuthenticated, userUID }) {
 
  //const [employees, setEmployees] = useState( );
  const [user, setUser] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userUid, setUserUid] = useState(null);


  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen to user authentication changes
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, so set the UID to the state
        setUserUid(user.uid);
      } else {
        // No user is signed in, set the UID to null
        setUserUid(null);
      }
    });
  }, []);
 
  const handleEdit = (userData) => {
    setUser(userData); // set the user to be edited
    setIsEditing(true);
  };
 
  return (
    <div>
       { !isEditing && (
        <>
          
          <CartaPerfiluser
            handleEdit={handleEdit}
            user={user}
            userUID={userUID}
          />
         
        </>
      )}
    
      {isEditing && user && (
        <EditPerfil
        user={user}
        setUser={setUser}
        setIsEditing={setIsEditing}
        userUid={userUid}
        />
      )}
    </div>
  )
}

export default Index
 */


import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CartaPerfiluser from '../../components/compPerfilUser/CartaPerfilUser'
import EditPerfil from './EditPerfil'


function Index({ setIsAuthenticated, userUID }) {
 
  //const [employees, setEmployees] = useState( );
  const [user, setUser] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [userUid, setUserUid] = useState(null);


  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen to user authentication changes
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, so set the UID to the state
        setUserUid(user.uid);
      } else {
        // No user is signed in, set the UID to null
        setUserUid(null);
      }
    });
  }, []);
 
  const handleEdit = (userData) => {
    setUser(userData);
    setIsEditing(true);
  };
 
  return (
    <div>
       { !isEditing && (
        <>
          <CartaPerfiluser
            handleEdit={handleEdit}
            user={user}
            userUID={userUID}
          />
         
        </>
      )}
    
      {isEditing && user && (
        <EditPerfil
        user={user}
        setUser={setUser}
        setIsEditing={setIsEditing}
        userUid={userUid}
        />
      )}
    </div>
  )
}

export default Index
 