/*

 
 import React, { useState, useEffect } from 'react';
import { IoSearch, IoArrowBack } from "react-icons/io5"; 
import { useNavigate } from 'react-router-dom';
import './BuscadorFeed.css';
import { useTranslation } from 'react-i18next';
import Historial from './historial/Historial';

function BuscadorFeed({ searchTerm: propSearchTerm, onSearch }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState(propSearchTerm || '');

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        setSearchTerm(propSearchTerm || '');
    }, [propSearchTerm]);

    function goSearch() {
        navigate("/SearchJob");
    }

    function goQuery() {
        // Trim whitespace and check if the searchTerm is empty
        if (!searchTerm.trim()) {
            return; // Prevent query if empty or only whitespace
        }
        navigate(`/Search?q=${encodeURIComponent(searchTerm)}`);
        if (typeof onSearch === 'function') {
            onSearch(searchTerm);
        }
    }

    function handleInputChange(event) {
        setSearchTerm(event.target.value);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            goQuery();
        }
    }

    function handleClearSearch() {
        setSearchTerm(''); // Clear the search term
        if (typeof onSearch === 'function') {
            onSearch(''); // Trigger onSearch with an empty string
        }
    }

    return (
        <div className="cont-main-query">
            <div className="con-buscador-query">
                
                {searchTerm ? (
                    <IoArrowBack className="iconSearch" size={30} onClick={handleClearSearch} />
                ) : (
                    <IoSearch className="iconSearch" size={30} />
                )}
                <input
                    type="text"
                    placeholder={t('Search Job')}
                    className="search-input-query"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                />

                <Historial/>
            </div>
        </div>
    );
}

export default BuscadorFeed;



*/import React, { useState, useEffect } from 'react';
import { IoSearch, IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import './BuscadorFeed.css';
import { useTranslation } from 'react-i18next';
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firebase import for autocomplete
import { db } from '../../firebase/firebase'; // Firebase configuration
import Historial from './historial/Historial';

function BuscadorFeed({ searchTerm: propSearchTerm, onSearch }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState(propSearchTerm || '');
    const [suggestions, setSuggestions] = useState([]); // To store autocomplete suggestions
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        setSearchTerm(propSearchTerm || '');
    }, [propSearchTerm]);

    // Fetch autocomplete suggestions
    async function fetchSuggestions(queryTerm) {
        if (!queryTerm.trim()) {
            setSuggestions([]); // Clear suggestions if the input is empty
            return;
        }

        // Query Firestore to get suggestions that match the input term
        const q = query(collection(db, 'history'), where('term', '>=', queryTerm), where('term', '<=', queryTerm + '\uf8ff'));
        const querySnapshot = await getDocs(q);

        const suggestionList = [];
        querySnapshot.forEach((doc) => {
            suggestionList.push(doc.data().term); // Collect matched terms
        });
        setSuggestions(suggestionList);
    }

    // Triggered when input changes
    function handleInputChange(event) {
        const value = event.target.value;
        setSearchTerm(value);
        fetchSuggestions(value); // Fetch suggestions based on input
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            goQuery();
        }
    }

    function handleClearSearch() {
        setSearchTerm('');
        setSuggestions([]); // Clear suggestions
        if (typeof onSearch === 'function') {
            onSearch('');
        }
    }

    function goQuery() {
        if (!searchTerm.trim()) {
            return;
        }
        navigate(`/Search?q=${encodeURIComponent(searchTerm)}`);
        if (typeof onSearch === 'function') {
            onSearch(searchTerm);
        }
    }

    // Set search term and clear suggestions when a suggestion is clicked
    function handleSuggestionClick(suggestion) {
        setSearchTerm(suggestion);
        setSuggestions([]); // Clear suggestions once a term is selected
        goQuery(); // Optionally trigger the search immediately
    }

    // Modal control
    function openModal() {
        setIsModalOpen(true);
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    return (
        <div className="cont-main-query">
        <div className={`con-buscador-query ${suggestions.length > 0 ? 'suggestions-visible' : ''}`}>
            {/* Search Icon or Back Icon */}
            {searchTerm ? (
                <IoArrowBack className="iconSearch" size={30} onClick={handleClearSearch} />
            ) : (
                <IoSearch className="iconSearch" size={30} />
            )}
    
            {/* Search Input */}
            <input
                type="text"
                placeholder={t('Search Job')}
                className="search-input-query"
                value={searchTerm}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
            />
    
            {/* Autocomplete Suggestions */}
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
    
            {/* Button to Open Modal for Trending Search History */}
            <button className="historial-button" onClick={openModal}>
                {t('Trend')}
            </button>
        </div>
    
        {/* Modal for Historial (Trending Search History) */}
        {isModalOpen && (
            <div className="modal-overlay" onClick={closeModal}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <Historial onTermClick={(term) => {
                        setSearchTerm(term);
                        closeModal();
                    }} />
                </div>
            </div>
        )}
    </div>
    
    );
}

export default BuscadorFeed;
