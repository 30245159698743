/*
import React from 'react';

function Payments() {
  const stripeUrl = 'https://buy.stripe.com/test_4gw9BK6Vf3Us7cYbII';

  return (
    <div>
      <h2>Payments</h2>
      <a href={stripeUrl} target="_blank" rel="noopener noreferrer">Proceed to Payment</a>
    </div>
  );
}

export default Payments;
*/

import React from 'react';

function Payments() {
  const stripeUrl = 'https://buy.stripe.com/fZedTFeH81zzgtq145';

  return (
    <div>
      <h2>Payments</h2>
      <i className="fas fa-coins"></i> {/* This renders a coin icon */}
      <a href={stripeUrl} target="_blank" rel="noopener noreferrer">Proceed to Payment</a>
    </div>
  );
}

export default Payments;

