 import React from 'react'
 import { BsFacebook, BsWhatsapp, BsInstagram } from "react-icons/bs";
 import { FaTiktok } from "react-icons/fa6";
 import { IconContext } from "react-icons";
 import './ContactUs.css'
 
 
 function ContactUs() {

   return (
    <div className='backgraund3'> 
      <div className='contenedorForm'>
        <form style={{  maxWidth: 500, margin: "auto" }} >
          <div className='headerBlock__infoText'>

            <div>
               <h1 className="text-center">Contact us</h1>
            </div>

           <div className="ContextoTitulo">
                <a  className="ContextoTitulo" target="Facebook" href="https://www.facebook.com/profile.php?id=61557744654215&locale=es_LA"> 
                    <IconContext.Provider value={{ size: '60px' }}>
                  
                        <BsFacebook/> 
                          <h4 className="textoSupTitulo3">Facebook</h4>
                            
                    
                    </IconContext.Provider>
                </a>
            </div>
           
            <div className="ContextoTitulo">
                <a target="Whatsappd" href="https://wa.me/message/X6COOHPWVUMSK1"> 
                <IconContext.Provider value={{ size: '60px' }}>
                            <BsWhatsapp/> 
                                <h4 className="textoSupTitul3">Whatsapp</h4>
                  </IconContext.Provider>
                  </a>

              </div>
           

              <div className="ContextoTitulo">
                  <a target="Instagram" href="https://www.instagram.com/laboor_inc?igsh=b2l1eWNmNTg3djh0&utm_source=qr"> 
                      <IconContext.Provider value={{ size: '60px' }}>
                      
                      <BsInstagram/> 
                          <h4 className="textoSupTitulo3">Instagram</h4>
                      
                      </IconContext.Provider>
                  </a>
              </div>




              <div className="ContextoTitulo">
                    <a target="Tiktok" href="https://www.tiktok.com/@laboor7?_t=8lyc25R75VN&_r=1"> 
                        <IconContext.Provider value={{ size: '60px' }}>
                          <div className="supTitulo1">
                            <FaTiktok/> 
                                <h4 className="textoSupTitulo3">Tiktok</h4>
                            </div>
                        </IconContext.Provider>
                    </a>
              </div>



            <div className="contDescription">
               <h4 className="textoSupTitulo">Llamanos al (+52) (663) 114 - 8462 o al (+52) (663) 105 - 7354</h4>
            </div>
             
                <div className="campos">
                     
              </div>
          </div>
        </form>
     </div>
   </div>
  );
};
   
 
 export default ContactUs