import React, { useEffect, useState } from 'react';
import './Header.css';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Header = () => {
  const [isCompanyUser, setIsCompanyUser] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const checkUserCollection = async () => {
      try {
        // Get the current authenticated user
        const user = auth.currentUser;

        if (user) {
          // Extract uid from the authenticated user
          const uid = user.uid;

          // Check if the user exists in the 'company' collection
          const companyQuery = query(collection(db, 'company'), where('uid', '==', uid));
          const companySnapshot = await getDocs(companyQuery);
          if (!companySnapshot.empty) {
            setIsCompanyUser(true);
            return;
          }

          // Check if the user exists in the 'infoUser' collection
          const infoUserQuery = query(collection(db, 'infoUser'), where('uid', '==', uid));
          const infoUserSnapshot = await getDocs(infoUserQuery);
          if (!infoUserSnapshot.empty) {
            setIsCompanyUser(false);
          }
        } else {
          console.error('No user is signed in');
        }
      } catch (error) {
        console.error('Error checking user collection:', error);
      }
    };

    checkUserCollection();
  }, [auth]);

  const handleNavigation = () => {
    if (isCompanyUser === true) {
      navigate('/CompanyContact'); // Navigate to CompanyContact page
    } else if (isCompanyUser === false) {
      navigate('/Cv'); // Navigate to Cv page
    }
  };

  return (
    <header className="header-container">
      <div>
        {isCompanyUser !== null && (
          <button className="buttoAddJob" onClick={handleNavigation}>
            {isCompanyUser ? 'Company Information' : 'Curriculum'}
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
