/*

import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../firebase/firebase'; // Import your Firebase configuration
import SuscriptionOffer from '../../payments/suscriptionOffer/suscriptionOffer'
import PostUser from '../../post/user/user'
import PostCompany from '../../post/company/company'
import FreeTrial from '../../Suscription/FreeTrial/FreeTrial'
import Login from '../../Login';

function verificationEditAccount() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);
  const [currentUid, setCurrentUid] = useState(null); // Add this line

  useEffect(() => {
    const fetchData = async () => {
       setCurrentUid (auth.currentUser.uid); // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, [currentUid]);

  return (
    <div>
      {formType === null && <Login/>}
      {formType === 'company' &&  <div>company EditAccount</div>}
      {formType === 'infoUser' &&  <div>infoUser post</div>}
    </div>
  );
}

export default verificationEditAccount;

*/ import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../firebase/firebase'; // Import your Firebase configuration
import  EditAccountCompany from '../../../pages/EditAccount/company/company'

import  EditAccountUser from '../../../pages/EditAccount/user/user'

import Login from '../../Login';

function VerificationEditAccount() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);
  const [currentUid, setCurrentUid] = useState(null); // Add this line

  useEffect(() => {
    const fetchData = async () => {
       setCurrentUid (auth.currentUser.uid); // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, [currentUid]);

  return (
    <div>
      {formType === 'company' &&  <EditAccountCompany currentUid={currentUid} user={formData} setUser={setFormData}/>}
      {formType === 'infoUser' &&  <EditAccountUser currentUid={currentUid} user={formData} setUser={setFormData} />}
    </div>
  );
}

export default VerificationEditAccount;
