import React from 'react'
import Logo from '../../../assets/LOGOBlack.png'
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import './currenSuscriptioScreem.css'

function currenSuscriptioScreem() {

    const navigateToSubscription = () => {
        window.location.href = 'https://billing.stripe.com/p/login/4gwcQ865A3H33aE5kk';
      };

    return (
        <div className="containerSuscriptionff">
     
    
          <div className="columnCH" style={{backgroundColor: '#fff'}}>
    
            
            <div  className="conlogoSuscr">
               <Link to="/SearchJob">
                   <img className="logoSuscr"  src={Logo} alt="Logo" /> 
               </Link>
            </div>
    
            <div className="conttRecruitment">
               
               <div className="ttRecruitment33"> <IoMdCheckmarkCircle className="iiconsus" size={30} color="#4F8EF7" />Active subscription</div>
             
                
                <button onClick={navigateToSubscription} className="BUTONttcancel">
                    <div className="ttcanceld">Manage Subscription</div>
                </button>
            </div>
         
    
           
    
          </div>
          <div className="columnCH" style={{backgroundColor: '#FFF'}}>
    
    
            <div className="coludd">
                <IoMdCheckmarkCircle className="iiconsus" size={20} color="#4F8EF7" />
                <view className="mindd">
                    <div className="ttRe11">Direct Hiring of Prospects</div>
                    <div className="ttmm">Speak dirrect with the hiring prospect Laboor is a platform designed for job seekers 
                     and recruiters alike. It provides a direct, efficient, and effective way to connect talent with 
                     opportunities. Job seekers can easily search for jobs that match their skills and preferences, apply directly 
                     through the platform, and track their application status in real time. 
                     Recruiters, on the other hand, can post job vacancies, screen applicants, and manage the recruitment process 
                     all in one place. With Iteration Direct, looking for a job or filling a vacancy has never been easier.</div>
                </view>
            </div>
    
            <div className="coludd">
                <IoMdCheckmarkCircle className="iiconsus" size={20} color="#4F8EF7" />
                <view className="mindd">
                    <div className="ttRe11">Team of reclutin</div>
                    <div className="ttmm">Laboor is a comprehensive platform designed to simplify the hiring process for your team. It provides a centralized system where all members involved in hiring can collaborate and manage their tasks efficiently.
                    With role-based access, each team member can effectively perform their responsibilities. 
                    Whether it’s posting a vacancy or managing prospects, Laboor makes the process seamless.
                    This platform is more than just a tool; it’s a solution that transforms the way your team hires, 
                    making the process more efficient and effective. It’s a centralized, public platform for people looking for jobs.</div>
                </view>
            </div>
    
    
            <div className="coludd">
                <IoMdCheckmarkCircle className="iiconsus" size={20} color="#4F8EF7" />
                <view className="mindd">
                    <div className="ttRe11">On all devices</div>
                    <div className="ttmm">Stay connected and informed, whether you’re using a desktop at the office, 
                     a laptop at home, or a mobile device on the go.</div>
                </view>
            </div>
    
            <div className="coludd">
                <IoMdCheckmarkCircle className="iiconsus" size={20} color="#4F8EF7" />
                <view className="mindd">
                    <div className="ttRe11">Full Focus</div>
                    <div className="ttmm">With an intuitive interface, Laboor lets you explore job listings, submit applications, and communicate with potential employers effortlessly. </div>
                </view>
            </div>
    
            <div className="coludd">
                <IoMdCheckmarkCircle className="iiconsus" size={20} color="#4F8EF7" />
                <view className="mindd">
                    <div className="ttRe11">Documenst</div>
                    <div className="ttmm">Laboor is a comprehensive tool designed to streamline your hiring process. It provides you with all the necessary documents you need when hiring new employees. Simplify your hiring process and ensure compliance.</div>
                </view>
            </div>
          </div>
        </div>
      )
    }

export default currenSuscriptioScreem

/* <a className="dddsfs" href="https://buy.stripe.com/test_9AQ6py4N776E0OA9AE" target="_blank" rel="noopener noreferrer">
<div className="conBBSuscr">
    <button className="glow-on-hoverSS">
                <div className="SubscribeNOW">Subscribe Now</div>          
    </button>
</div>
</a>
*/