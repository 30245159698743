/*



import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

function ProfileImage({ queryPost }) {
  const [infoUserData, setInfoUserData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Query for infoUser data
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', queryPost));
        const querySnapshot1 = await getDocs(q1);
        const userData = querySnapshot1.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInfoUserData(userData);

        // Query for company data
        const q2 = query(collection(db, 'company'), where('uid', '==', queryPost));
        const querySnapshot2 = await getDocs(q2);
        const companyData = querySnapshot2.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCompanyData(companyData);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (queryPost) {
      fetchData();
    }
  }, [queryPost]);

  return (
    <div className="cityMONUNcon">
      
          {infoUserData.map(user => (
            <div className="post-column1">
             <img className="imgPostUser" src={user?.imgPerfil} alt="User Profile" />
             <div className="userSpa" key={user.id}>{user.firstName}</div>
            </div>
          ))}
     
     
          {companyData.map(company => (
            <div className="post-column1">
                 
                 <img className="imgPostUser" src={company?.imgPerfil} alt="User Profile" />
                 <div className="userSpa" key={company.id}>{company.nameCompany}</div>
            </div>
          ))}
     
    </div>
  );
}

export default ProfileImage;




*/

import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { FaUser } from 'react-icons/fa'; // Import FaUser icon

function ProfileImage({ queryPost }) {
  const [infoUserData, setInfoUserData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Query for infoUser data
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', queryPost));
        const querySnapshot1 = await getDocs(q1);
        const userData = querySnapshot1.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInfoUserData(userData);

        // Query for company data
        const q2 = query(collection(db, 'company'), where('uid', '==', queryPost));
        const querySnapshot2 = await getDocs(q2);
        const companyData = querySnapshot2.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCompanyData(companyData);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (queryPost) {
      fetchData();
    }
  }, [queryPost]);

  return (
    <div className="cityMONUNcon">
      {/* User Profile Section */}
      {infoUserData.map(user => (
        <div className="post-column1" key={user.id}>
          {user?.imgPerfil ? (
            <img 
              className="imgPostUser" 
              src={user?.imgPerfil} 
              alt="User Profile" 
            />
          ) : (
            <FaUser className="defaultUserIcon" /> // Render FaUser if imgPerfil doesn't exist
          )}
          <div className="userSpa">{user.firstName}</div>
        </div>
      ))}

      {/* Company Profile Section */}
      {companyData.map(company => (
        <div className="post-column1" key={company.id}>
          {company?.imgPerfil ? (
            <img 
              className="imgPostUser" 
              src={company?.imgPerfil} 
              alt="Company Profile" 
            />
          ) : (
            <FaUser className="defaultUserIcon" /> // Render FaUser if imgPerfil doesn't exist
          )}
          <div className="userSpa">{company.nameCompany}</div>
        </div>
      ))}
    </div>
  );
}

export default ProfileImage;
