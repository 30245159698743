/*


import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../../firebase/firebase';
import './perfilUserPost.css';
import SendMessage from '../../comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import NoDataUi from '../NoDataUi/NoDataUi';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const { uid } = useParams(); // UID from the URL
  const navigate = useNavigate(); // Hook to navigate to different routes
  const auth = getAuth(); // Initialize Firebase Auth
  const currentUser = auth.currentUser; // Get the currently logged-in user's data

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const toggleText = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const handleNavigateToSearchJob = useCallback((postId) => {
    navigate(`/SearchJob`, { state: { postId } });
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (uid && (!currentUser || currentUser.uid !== uid)) {
        const usersCollection = collection(db, 'employeesPost');
        const userQuery = query(usersCollection, where('uid', '==', uid));
        try {
          const querySnapshot = await getDocs(userQuery);
          if (!querySnapshot.empty) {
            setUserData(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
          } else {
            setUserData([]);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUserData(null); // or handle as needed
      }
    };

    fetchUserData();
  }, [uid, currentUser]);

  if (userData === null) {
    return <p> </p>;
  }

  return (
    <div className="posPro3may">
      {uid ? (
        <div className="conInPro">
          {Array.isArray(userData) && userData.length > 0 ? (
            <div className="posts-container1">
              {userData.map((userItem) => (
                <div key={userItem.id} className="postPr3" onClick={() => handleNavigateToSearchJob(userItem.id)}>
                  <div className="post-info">
                    {userItem.imgPost && (
                      <img className="post-image-3" src={userItem.imgPost} alt="Profile" />
                    )}
                    {userItem.selectedImage && (
                      <img className="post-image-3" src={userItem.selectedImage} alt="Profile" />
                    )}
                    {userItem.videoPost && (
                      <video
                        className="post-video-3"
                        controls
                        onMouseOver={(e) => e.target.play()}
                        onMouseOut={(e) => e.target.pause()}
                        loop
                      >
                        <source src={userItem.videoPost} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <div className="post-info1">
                      {userItem.title && (
                        <div className="post-title">{userItem.title}</div>
                      )}
                      <div className="cityMONUNcon">
                        {userItem.salary && (
                          <div className="aaa55m">
                            <span className="Weeklyt">Weekly</span>
                            <div className="post-description1">${userItem.salary}</div>
                          </div>
                        )}
                        {userItem.city && (
                          <div className="aaa55m">
                            <span className="Weeklyt">{userItem.workModality}</span>
                            <span className="post-likes">{userItem.city}</span>
                          </div>
                        )}
                      </div>
                      <div className="cityMONUNcon">
                        {userItem.jobType && (
                          <div className="aaa55m">
                            <span className="Weeklyt">Job Type</span>
                            <span className="post-likes">{userItem.jobType}</span>
                          </div>
                        )}
                        {userItem.suitableFor && (
                          <div className="aaa55m">
                            <span className="Weeklyt">Suitable For</span>
                            <span className="post-likes">{userItem.suitableFor}</span>
                          </div>
                        )}
                      </div>
                      {userItem.shift && (
                        <div className="aaa55m">
                          <span className="Weeklyt">Shift</span>
                          <span className="post-likes">{userItem.shift}</span>
                        </div>
                      )}
                      {userItem.inputText && (
                        <div className="containerText">
                          <p className="post-title">
                            {isTextExpanded
                              ? userItem.inputText
                              : `${userItem.inputText.substring(0, 400)}...`}
                          </p>
                          {userItem.inputText.length > 100 && (
                            <button className="expandButton" onClick={toggleText}>
                              {isTextExpanded ? 'Show Less' : 'Show More'}
                            </button>
                          )}
                        </div>
                      )}
                      
                      {userItem.salary && (
                        <div className="conButtonApply">
                          <SendMessageCompany uidThePost={uid} currentUid={currentUser.uid}/>
                        </div>
                      )}
                      {userItem.inputText && (
                        <SendMessage uidThePost={uid} currentUid={currentUser.uid} />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoDataUi/>
          )}
        </div>
      ) : (
        <p>Please provide a UID in the URL.</p>
      )}
    </div>
  );
};

export default UserProfile;



 */

import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../../firebase/firebase';
import './perfilUserPost.css';
import SendMessage from '../../comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import NoDataUi from '../NoDataUi/NoDataUi';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const { uid } = useParams(); // UID from the URL
  const navigate = useNavigate(); // Hook to navigate to different routes
  const auth = getAuth(); // Initialize Firebase Auth
  const currentUser = auth.currentUser; // Get the currently logged-in user's data

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const toggleText = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const handleNavigateToSearchJob = useCallback((postId) => {
    navigate(`/SearchJob`, { state: { postId } });
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (uid && (!currentUser || currentUser.uid !== uid)) {
        const usersCollection = collection(db, 'employeesPost');
        const userQuery = query(usersCollection, where('uid', '==', uid));
        try {
          const querySnapshot = await getDocs(userQuery);
          if (!querySnapshot.empty) {
            setUserData(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
          } else {
            setUserData([]);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUserData(null); // or handle as needed
      }
    };

    fetchUserData();
  }, [uid, currentUser]);

  if (userData === null) {
    return <p> </p>;
  }

  return (
    < >
      {uid ? (
        <div className="posts-container65">
          {Array.isArray(userData) && userData.length > 0 ? (
            <div className="">
              {userData.map((userItem) => (
                <div key={userItem.id} className="post12" onClick={() => handleNavigateToSearchJob(userItem.id)}>
                  <div className="post-info">
                    {userItem.imgPost && (
                      <img className="post-image-3" src={userItem.imgPost} alt="Profile" />
                    )}
                    {userItem.selectedImage && (
                      <img className="post-image-3" src={userItem.selectedImage} alt="Profile" />
                    )}
                    {userItem.videoPost && (
                      <video
                        className="post-video-3"
                        controls
                        onMouseOver={(e) => e.target.play()}
                        onMouseOut={(e) => e.target.pause()}
                        loop
                      >
                        <source src={userItem.videoPost} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <div className="post-info1">
                      {userItem.title && (
                        <div className="post-title">{userItem.title}</div>
                      )}
                      <div className="cityMONUNcon">
                        {userItem.salary && (
                          <div className="aaa55m">
                            <span className="Weeklyt">Weekly</span>
                            <div className="post-description1">${userItem.salary}</div>
                          </div>
                        )}
                        {userItem.city && (
                          <div className="aaa55m">
                            <span className="Weeklyt">{userItem.workModality}</span>
                            <span className="post-likes">{userItem.city}</span>
                          </div>
                        )}
                      </div>
                       
                     
                      {userItem.inputText && (
                        <div className="containerText">
                          <p className="post-title">
                            {isTextExpanded
                              ? userItem.inputText
                              : `${userItem.inputText.substring(0, 400)}...`}
                          </p>
                          {userItem.inputText.length > 100 && (
                            <button className="expandButton" onClick={toggleText}>
                              {isTextExpanded ? 'Show Less' : 'Show More'}
                            </button>
                          )}
                        </div>
                      )}
                      
                      {userItem.salary && (
                        <div className="conButtonApply">
                          <SendMessageCompany uidThePost={uid} currentUid={currentUser.uid}/>
                        </div>
                      )}
                      {userItem.inputText && (
                        <SendMessage uidThePost={uid} currentUid={currentUser.uid} />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoDataUi/>
          )}
        </div>
      ) : (
        <p>Please provide a UID in the URL.</p>
      )}
    </ >
  );
};

export default UserProfile;

