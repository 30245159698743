import { Navigate } from "react-router-dom";
import { useContext } from "react";

import { AuthContext } from '../../backEndFireBase/AuthContext';

export function Protected({children}){
    const {user} = useContext(AuthContext); // Aquí está el cambio

    if(!user){
        return <Navigate to="/SignUp" replace/>
    }else{
        return children;
    }
}

 

 