/*import React, { useState, useEffect } from 'react';
import './PerfilUser.css'
import { getAuth } from 'firebase/auth';
import  PerfilUserIndex from '../../components/compPerfilUser/'
import Sidenav from '../../components/navigation/Sidenav'
import PerfilUserPost from '../../components/compPerfilUser/perfilUserPost/perfilUserPost'
import { useParams } from 'react-router-dom';
import AddEmploye from '../../components/dashBoard/Header'
import Dashboard from '../../components/dashBoard/index';  
import './PerfilUser.css'

function PerfilUser() {
  const { uid } = useParams();

  const [currentUserUID, setCurrentUserUID] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUserUID(user.uid);
      } else {
        setCurrentUserUID(null);
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  if (uid !== currentUserUID) {
    // Optionally handle the case where the UIDs don't match
    // e.g., redirect to a different page or show an error message
  }


  return (
    <div className="homepage">
      <div className="homepage__navWraper">
      <Sidenav />
      </div>
      <div className="homepage__timeline">
        
      <PerfilUserIndex uid={uid} />
         {uid === currentUserUID && (  
        <div>
     
       <Dashboard/>
     
       </div>
       )}
        <PerfilUserPost uid={uid}  /> 
      </div>
    </div>
  )
}
//
export default PerfilUser*/
import React, { useState, useEffect } from 'react';
import './PerfilUser.css';
import { getAuth } from 'firebase/auth';
import PerfilUserIndex from '../../components/compPerfilUser/';
import Sidenav from '../../components/navigation/Sidenav';
import PerfilUserPost from '../../components/compPerfilUser/perfilUserPost/perfilUserPost';
import { useParams } from 'react-router-dom';
import Dashboard from '../../components/dashBoard/index';  
import './PerfilUser.css';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from "re-resizable";

function PerfilUser() {
  const { uid } = useParams();
  const [currentUserUID, setCurrentUserUID] = useState(null);
  const auth = getAuth();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  useEffect(() => {
    // Reset state variables at the start
    setCurrentUserUID(null);

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUserUID(user.uid);
      } else {
        setCurrentUserUID(null);
      }
    });

    // Clean up subscription on unmount
    return () => {
      unsubscribe();
      setCurrentUserUID(null); // Ensure state is cleaned up
    };
  }, [auth, uid]); // Add uid to dependency array to reset when uid changes

  if (uid !== currentUserUID) {
    // Optionally handle the case where the UIDs don't match
    // e.g., redirect to a different page or show an error message
  }

  return (
    <div className="conProCh" style={{ display: 'flex', height: '100vh' }}>
      {isMobile ? (
        <div className="sidenita">
          <Sidenav />
        </div>
      ) : (
        <Resizable
          className="sidenita"
          defaultSize={{ width: '30%', height: '100%' }}
          minWidth="20%"
          maxWidth="70%"
          enable={{ right: true }}
        >
          <Sidenav />
        </Resizable>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <div className="segundoCont" style={{ flex: 1 }}>
          <PerfilUserIndex uid={uid} />
        </div>
        {uid === currentUserUID && (
          <div className="segundoCont" style={{ flex: 1 }}>
            <Dashboard />
          </div>
        )}
        <div className="segundoCont4" style={{ flex: 1 }}>
          <PerfilUserPost uid={uid} />
        </div>
      </div>
    </div>
  );
}

export default PerfilUser;
