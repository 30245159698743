import React from 'react';
import "./QueSomos.css";
import { useNavigate } from "react-router-dom";

function QueSomos() {

    const navigate = useNavigate();

    function goContactUs() {
        navigate("/WeNeedPeople");
    }
    
    return (
        <div className='contenedorQuesomo'>
            <div className="conCONTACT">
                <h3 className="textTi">POLÍTICA DE PRIVACIDAD</h3>
            </div>
            <div>
                <p className="ttCotratoD">
                    Información que Recopilamos
                </p>
                <p className="supTitulo2">
                    Recopilamos diferentes tipos de información personal para proporcionar y mejorar nuestros servicios:
                    <ul>
                        <li className="list-item"><strong className="list-tt">Información de Contacto:</strong> Nombre, apellidos, número de teléfono, dirección de correo electrónico.</li>
                        <li className="list-item"><strong className="list-tt">Datos de Perfil:</strong> Ciudad, habilidades, foto de perfil, imagen de fondo, género, nacionalidad, nivel de inglés, descripción de habilidades, posición laboral, experiencia laboral.</li>
                        <li className="list-item"><strong className="list-tt">Información de Cuenta:</strong> Nombre de usuario, UID (Identificador de Usuario), contraseña (almacenada de manera segura).</li>
                        <li className="list-item"><strong className="list-tt">Información de Ubicación:</strong> Podemos recopilar información de la ciudad en la que te encuentras.</li>
                        <li className="list-item"><strong className="list-tt">Datos del Dispositivo:</strong> Información sobre el dispositivo móvil, incluyendo el identificador único del dispositivo, sistema operativo y versión, y datos de red.</li>
                        <li className="list-item"><strong className="list-tt">Contenido Multimedia:</strong> Videos y mensajes que envías a través de la aplicación.</li>
                        <li className="list-item"><strong className="list-tt">Edad y Género:</strong> Datos necesarios para cumplir con políticas de restricción de edad y personalización de servicios.</li>
                    </ul>
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Cómo Utilizamos la Información
                </p>
                <p className="supTitulo2">
                    Utilizamos la información recopilada para:
                    <ul>
                        <li className="list-item">Proporcionar y mejorar nuestros servicios.</li>
                        <li className="list-item">Personalizar tu experiencia en la aplicación.</li>
                        <li className="list-item">Comunicarnos contigo respecto a actualizaciones, soporte y notificaciones importantes.</li>
                        <li className="list-item">Cumplir con nuestras obligaciones legales y regulatorias.</li>
                    </ul>
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Compartición de Información
                </p>
                <p className="supTitulo2">
                    No compartimos tu información personal con terceros, excepto en los siguientes casos:
                    <ul>
                        <li className="list-item"><strong className="list-tt">Proveedores de Servicios:</strong> Compartimos información con proveedores que realizan servicios en nuestro nombre, como análisis de datos y soporte técnico.</li>
                        <li className="list-item"><strong className="list-tt">Cumplimiento Legal:</strong> Podemos compartir información si es requerido por ley, o para proteger nuestros derechos, tu seguridad, o la seguridad de otros.</li>
                        <li className="list-item"><strong className="list-tt">Transferencias Empresariales:</strong> En caso de una fusión, adquisición, o venta de activos, tu información podría ser transferida a la nueva entidad.</li>
                    </ul>
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Seguridad de la Información
                </p>
                <p className="supTitulo2">
                    Nos comprometemos a proteger la información personal mediante medidas de seguridad técnicas y organizativas. Sin embargo, ningún método de transmisión o almacenamiento es 100% seguro, por lo que no podemos garantizar la seguridad absoluta.
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Retención de Datos
                </p>
                <p className="supTitulo2">
                    Retenemos tu información personal mientras sea necesario para proporcionar nuestros servicios, cumplir con nuestras obligaciones legales, resolver disputas, y hacer cumplir nuestros acuerdos.
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Tus Derechos
                </p>
                <p className="supTitulo2">
                    Tienes el derecho de:
                    <ul>
                        <li className="list-item">Acceder, corregir, o eliminar tu información personal.</li>
                        <li className="list-item">Oponerte al procesamiento de tus datos en ciertas circunstancias.</li>
                        <li className="list-item">Retirar tu consentimiento cuando sea aplicable.</li>
                    </ul>
                    
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Cambios a esta Política de Privacidad
                </p>
                <p className="supTitulo2">
                    Podemos actualizar esta política de privacidad ocasionalmente. Te notificaremos sobre cualquier cambio importante mediante la publicación de la nueva política en nuestra aplicación y/o a través de un aviso directo.
                </p>
            </div>

            <div>
                <p className="ttCotratoD">
                    Contacto
                </p>
                <p className="supTitulo2">
                    Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, puedes contactarnos en:
                    <br />
                    <strong  className="list-tt">Atención al Cliente:</strong> (+52) (663) 105 7354<br />
                    <strong  className="list-tt">Correo Electrónico:</strong> jobs@laboor.website 
                </p>
            </div>

          
        </div>
    )
}

export default QueSomos;
