/*

import React from 'react';
import { useLocation } from 'react-router-dom';
import './PageSearch.css';
import Sidenav from '../../components/navigation/Sidenav';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from 're-resizable';
import SwipeableCard from './SwipeableCard/SwipeableCard';

function SearchJobMain() {
  const location = useLocation();
  const { postId } = location.state || {};
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div className="homepage5" style={{ display: 'flex', height: '100vh' }}>
      {isMobile ? (
        <div className="homepage__navWraper5">
          <Sidenav />
        </div>
      ) : (
        <Resizable
          className="homepage__navWraper5"
          defaultSize={{ width: '30%', height: '100%' }}
          minWidth="20%"
          maxWidth="70%"
          enable={{ right: true }}
        >
          <Sidenav />
        </Resizable>
      )}
      <div style={{ flex: 1 }}>
        <SwipeableCard postId={postId} />
      </div>
    </div>
  );
}

export default SearchJobMain;
*/
import React from 'react';
import { useLocation } from 'react-router-dom';
import './PageSearch.css';
import Sidenav from '../../components/navigation/Sidenav';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from 're-resizable';
import SwipeableCard from './SwipeableCard/SwipeableCard';

function SearchJobMain() {
  const location = useLocation();
  const { postId } = location.state || {};
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div className="homepage5" style={{ display: 'flex', height: '100vh', }}>
      {isMobile ? (
        <div className="homepage__navWraper5">
          <Sidenav />
        </div>
      ) : (
        <Resizable
          className="homepage__navWraper5"
          defaultSize={{ width: '30%', height: '100%' }}
          minWidth="20%"
          maxWidth="70%"
          enable={{ right: true }}
        >
          <Sidenav />
        </Resizable>
      )}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',  }}>
            <SwipeableCard postId={postId} />
        </div>
    </div>
  );
}

export default SearchJobMain;