// src/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationES from '../locales/es/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

const getCurrentLanguage = () => {
  const savedLanguage = localStorage.getItem('language');
  return savedLanguage || 'en';
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: getCurrentLanguage(), // Default language
    fallbackLng: 'en', // Fallback language if the current language does not have a translation
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
