import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person'; // Original icon
import Typography from '@material-ui/core/Typography';
import { red, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: theme.spacing(4),
    borderRadius: '8px',
    boxShadow: theme.shadows[2], // Add a subtle shadow
    margin: theme.spacing(2), // Add margin
  },
  icon: {
    fontSize: 80,
    color: '#999', // Change color to red for the Person icon
    marginBottom: theme.spacing(1),
  },
  warningIcon: {
    fontSize: 60,
    color: blue[500], // Change color to blue for the Warning icon
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: '1 rem',
    fontWeight: 'bold', // Make the text bold
    color: '#999', // Use primary text color for better contrast
  },
}));

function NoDataUi() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography    className={classes.text}>
          User don't have post yet
      </Typography>
    </div>
  );
}

export default NoDataUi; // Ensure this is a default export






