
/*
import './index.css';
import CloseIcon from '@material-ui/icons/Close'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//isMenuOpen, setIsMenuOpen
 
const Navbar = ({ showSidebar, setShowSidebar }) => {

  const navigate = useNavigate();
  function handleClick() {
    navigate("/SearchJob");
  }

  const { i18n } = useTranslation();
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Save the selected language to localStorage
  };

  return (
    <div className='header2'>
      <div className='header__logo'>
         <button className="bottonIcon"  onClick={handleClick}>
         Laboor
         </button>
         

         <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('es')}>Español</button>
       
      </div>
            
    </div>
  );
};

export default Navbar;
*/import './index.css';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

const Navbar = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [showLanguageButtons, setShowLanguageButtons] = useState(false);

  const handleClick = () => {
    navigate('/SearchJob');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const toggleLanguageButtons = () => {
    setShowLanguageButtons(!showLanguageButtons);
  };

  return (
    <div className='header2'>
      <div className='header__logo'>
        <button style={{ color: '#fff' }} className="bottonIcon" onClick={handleClick}>
          laboor
        </button>

        <div className="icon-container">
          <IconButton onClick={toggleLanguageButtons}   color="inherit" aria-label="language">
            {showLanguageButtons ? <CloseIcon style={{ fill: '#fff', fontSize: '28px' }}/> : <LanguageIcon style={{ fill: '#fff', fontSize: '28px' }}/>}
          </IconButton>
        </div>
      
        {showLanguageButtons && (
          <div className="language-buttons">
            <button style={{ color: '#fff' }} className="idiomas" onClick={() => changeLanguage('en')}>English</button>
            <button style={{ color: '#fff' }} className="idiomas" onClick={() => changeLanguage('es')}>Español</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;




 







  