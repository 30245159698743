import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '200px',
  padding: '20px',
  borderRadius: '20px',
  position: 'relative', // Add this for positioning the button
};

const defaultCenter = {
  lat: 32.5149, // Default location (Tijuana)
  lng: -117.0382,
};

const MapsLocation = ({ lat, lng }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCbM2Gx_OMitbo_IeZ1LA2MEHGuT6bZ2zk', // Replace with your Google Maps API Key
  });

  if (!isLoaded) return <div>Loading Map...</div>;

  // Function to handle navigation
  const handleNavigate = () => {
    if (lat && lng) {
      const navigationUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      console.log(`Navigating to: ${navigationUrl}`); // Debug log for navigation URL
      window.open(navigationUrl, '_blank'); // Opens the Google Maps URL in a new tab
    } else {
      console.log("Lat/Lng not available");
    }
  };

  return (
    <div style={{ position: 'relative', padding:'10px' }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={{ lat: lat || defaultCenter.lat, lng: lng || defaultCenter.lng }}
      >
        {lat && lng ? (
          <Marker
            position={{ lat, lng }}
            onClick={handleNavigate} // Navigate when the marker is clicked
          />
        ) : (
          <div>No location available</div> // Show message if no lat/lng provided
        )}
      </GoogleMap>
      {/* Navigation Button */}
      <button
        onClick={handleNavigate}
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          padding: '10px 20px',
          backgroundColor: '#000',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          zIndex: 1, // Ensure the button is on top of the map
        }}
      >
        how to get there
      </button>
    </div>
  );
};

export default MapsLocation;
