 import React, { useState } from 'react'
 
 import Navbar from '../components/Navbar';

 function SliderBar({ children }) {
    const [showSidebar, setShowSidebar] = useState(false);

   return (
    <>
      <main className={showSidebar ? 'blur-content' : ''}>
       <Navbar setShowSidebar={setShowSidebar}/> 
        {children}
      </main>
    </>
    
   )
 }
 
 export default SliderBar