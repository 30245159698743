/*



import React, { useEffect, useState, useCallback, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore'; 
import { db, auth } from '../../firebase/firebase';
import { FaUser, FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import arrow icons for the buttons
import './postProfiles.css';
import SendMessage from '../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../../src/components/comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import { useNavigate } from 'react-router-dom';
import Follow from '../Follow/Follow';

function PostProfiles() {
    const [data, setData] = useState({ company: [], infoUser: [] });
    const [currentUid, setCurrentUid] = useState(null);
    const navigate = useNavigate();
    const scrollWrapperRef = useRef(null); // Reference for scrollable container

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    const handleNavigateToSearchJob = useCallback((postId) => {
        navigate(`/SearchJob`, { state: { postId } });
    }, [navigate]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUid(user.uid);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const companyData = await getDocs(collection(db, 'company'));
            const infoUserData = await getDocs(collection(db, 'infoUser'));

            setData({
                company: companyData.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                infoUser: infoUserData.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            });
        };

        fetchData();
    }, []);

    // Handle scroll buttons for horizontal scrolling
    const handleScroll = (direction) => {
        const scrollWrapper = scrollWrapperRef.current;
        if (scrollWrapper) {
            const scrollAmount = direction === 'left' ? -200 : 200; // Adjust scroll amount
            scrollWrapper.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return (
        <div className="container123">
            <button className="scrollButton left" onClick={() => handleScroll('left')}>
                <FaArrowLeft />
            </button>
            <button className="scrollButton right" onClick={() => handleScroll('right')}>
                <FaArrowRight />
            </button>

            <div className="scrollWrapper" ref={scrollWrapperRef}>
                {data.infoUser.map((user) => (
                    <div
                        className="post456"
                        key={user.id}
                        onClick={() => handleNavigateToSearchJob(user.id)}
                    >
                        <button className="navigateBtn789" onClick={(e) => {
                            e.stopPropagation();
                            handleNavigateToProfile(user.uid);
                        }}>
                            <div className="column011">
                                {user?.imgPerfil ? (
                                    <img className="imageProfile212" src={user.imgPerfil} alt="User Profile" />
                                ) : (
                                    <FaUser size={25} className="searchIcon987" />
                                )}
                                <div className="userText543"> {user.firstName}</div>
                            </div>
                        </button>
                        <p className="description246">
                            {user.positionJob?.split(' ').slice(0, 5).join(' ') || ''}{user.positionJob?.split(' ').length > 10 ? '...' : ''}
                        </p>


                        <div className="columnUser852">
                            <div className="likesSection370">
                                {user.city ? user.city.slice(0, 17) : 'City'}
                            </div>
                            <Follow/>
                            <SendMessage uidThePost={user.uid} currentUid={currentUid} />
                        </div>
                    </div>
                ))}

                {data.company.map((company) => (
                    <div
                        className="post456"
                        key={company.id}
                        onClick={() => handleNavigateToSearchJob(company.id)}
                    >
                        <button className="navigateBtn789">
                            <div className="column011">
                                {company?.imgPerfil ? (
                                    <img className="imageProfile212" src={company.imgPerfil} alt="Company Profile" />
                                ) : (
                                    <FaUser size={25} className="searchIcon987" />
                                )}
                                <div className="userText543"> {company.nameCompany}</div>
                            </div>
                        </button>
                        <p className="description246"> {company.descriptionCompany}</p>
                        <div className="columnUser852">
                            <div className="likesSection370">{company.city}</div>
                         
                            <SendMessageCompany uidThePost={company.uid} currentUid={currentUid} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PostProfiles;




*/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore'; 
import { db, auth } from '../../firebase/firebase';
import { FaUser, FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import arrow icons for the buttons
import './postProfiles.css';
import SendMessage from '../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../../src/components/comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import { useNavigate } from 'react-router-dom';
import Follow from '../Follow/Follow';

function PostProfiles() {
    const [data, setData] = useState({ company: [], infoUser: [] });
    const [currentUid, setCurrentUid] = useState(null);
    const [follows, setFollows] = useState({});
    const navigate = useNavigate();
    const scrollWrapperRef = useRef(null); // Reference for scrollable container

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    const handleNavigateToSearchJob = useCallback((postId) => {
        navigate(`/SearchJob`, { state: { postId } });
    }, [navigate]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUid(user.uid);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const companyData = await getDocs(collection(db, 'company'));
            const infoUserData = await getDocs(collection(db, 'infoUser'));

            setData({
                company: companyData.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                infoUser: infoUserData.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            });
        };

        fetchData();
    }, []);

    // Handle scroll buttons for horizontal scrolling
    const handleScroll = (direction) => {
        const scrollWrapper = scrollWrapperRef.current;
        if (scrollWrapper) {
            const scrollAmount = direction === 'left' ? -200 : 200; // Adjust scroll amount
            scrollWrapper.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const handleFollowToggle = (followeeUid, isFollowed) => {
        setFollows(prevFollows => ({
            ...prevFollows,
            [followeeUid]: isFollowed // Update follow status for the followee
        }));
    };

    return (
        <div className="container123">
            <button className="scrollButton left" onClick={() => handleScroll('left')}>
                <FaArrowLeft />
            </button>
            <button className="scrollButton right" onClick={() => handleScroll('right')}>
                <FaArrowRight />
            </button>

            <div className="scrollWrapper" ref={scrollWrapperRef}>
                {data.infoUser.map((user) => (
                    <div
                        className="post456"
                        key={user.id}
                       
                    >
                        <button className="navigateBtn789" onClick={(e) => {
                            e.stopPropagation();
                            handleNavigateToProfile(user.uid);
                        }}>
                            <div className="column011">
                                {user?.imgPerfil ? (
                                    <img  onClick={() => handleNavigateToSearchJob(user.id)} className="imageProfile212" src={user.imgPerfil} alt="User Profile" />
                                ) : (
                                    <FaUser size={25} className="searchIcon987" />
                                )}
                                <div className="userText543"> {user.firstName}</div>

                                {currentUid && (
                                <Follow 
                                    followerUid={currentUid} // Current logged-in user
                                    followeeUid={user.uid} // User being followed
                                    initialFollowed={!!follows[user.uid]} // Check follow status
                                    onFollowToggle={handleFollowToggle} // Handle follow toggle
                                    />
                                 )}
                            </div>
                        </button>
                        <p className="description246">
                            {user.positionJob?.split(' ').slice(0, 5).join(' ') || ''}{user.positionJob?.split(' ').length > 10 ? '...' : ''}
                        </p>


                        <div className="columnUser852">
                            <div className="likesSection370">
                                {user.city ? user.city.slice(0, 17) : 'City'}
                            </div>
                           
                            <SendMessage uidThePost={user.uid} currentUid={currentUid} />
                        </div>
                    </div>
                ))}

                {data.company.map((company) => (
                    <div
                        className="post456"
                        key={company.id}>
                            
                        <button className="navigateBtn789">
                            <div className="column011">
                                {company?.imgPerfil ? (
                                    <img   onClick={() => handleNavigateToSearchJob(company.id)} className="imageProfile212" src={company.imgPerfil} alt="Company Profile" />
                                ) : (
                                    <FaUser size={25} className="searchIcon987" />
                                )}
                                <div className="userText543"> {company.nameCompany}</div>

                                {currentUid && (
                                <Follow 
                                    followerUid={currentUid} // Current logged-in user
                                    followeeUid={company.uid} // User being followed
                                    initialFollowed={!!follows[company.uid]} // Check follow status
                                    onFollowToggle={handleFollowToggle} // Handle follow toggle
                                    />
                                 )}
                            </div>
                        </button>
                        <p className="description246"> {company.descriptionCompany}</p>
                        <div className="columnUser852">
                            <div className="likesSection370">{company.city}</div>
                            <SendMessageCompany uidThePost={company.uid} currentUid={currentUid} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PostProfiles;

