/*

import React, { useState } from 'react';
import { doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase'; // Make sure to import your Firestore config

function Follow({ followerUid, followeeUid, initialFollowed = false, onFollowToggle }) {
    const [isFollowed, setIsFollowed] = useState(initialFollowed);

    const handleToggleFollow = async () => {
        const newFollowStatus = !isFollowed;
        setIsFollowed(newFollowStatus);
        
        // Call the parent callback to update follow status in UI
        onFollowToggle(followeeUid, newFollowStatus);

        try {
            if (newFollowStatus) {
                // Follow: Add a document to the `follows` collection in Firestore
                await setDoc(doc(db, 'follows', `${followerUid}_${followeeUid}`), {
                    followerUid: followerUid,
                    followeeUid: followeeUid,
                    followedAt: new Date(),
                });
                console.log('User followed successfully!');
            } else {
                // Unfollow: Remove the document from the `follows` collection
                await deleteDoc(doc(db, 'follows', `${followerUid}_${followeeUid}`));
                console.log('User unfollowed successfully!');
            }
        } catch (error) {
            console.error('Error updating follow status: ', error);
        }
    };

    return (
        <button onClick={handleToggleFollow}>
            {isFollowed ? 'Unfollow' : 'Follow'}
        </button>
    );
}

export default Follow;

*/
import React, { useEffect, useState } from 'react';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase'; // Make sure to import your Firestore config
import './Follow.css';

function Follow({ followerUid, followeeUid, initialFollowed = false, onFollowToggle }) {
    const [isFollowed, setIsFollowed] = useState(initialFollowed); // Manage follow state
    const [loading, setLoading] = useState(true); // Manage loading state

    // Function to check if the follow document exists in Firestore
    const checkFollowStatus = async () => {
        try {
            const followDoc = await getDoc(doc(db, 'follows', `${followerUid}_${followeeUid}`));
            if (followDoc.exists()) {
                setIsFollowed(true);
            } else {
                setIsFollowed(false);
            }
        } catch (error) {
            console.error('Error checking follow status: ', error);
        } finally {
            setLoading(false); // Stop loading when follow status is checked
        }
    };

    // Fetch the follow status when the component mounts
    useEffect(() => {
        if (followerUid && followeeUid) {
            checkFollowStatus();
        }
    }, [followerUid, followeeUid]);

    const handleToggleFollow = async () => {
        const newFollowStatus = !isFollowed;
        setIsFollowed(newFollowStatus);

        // Call the parent callback to update the UI
        onFollowToggle(followeeUid, newFollowStatus);

        try {
            if (newFollowStatus) {
                // Follow: Add the document to Firestore
                await setDoc(doc(db, 'follows', `${followerUid}_${followeeUid}`), {
                    followerUid: followerUid,
                    followeeUid: followeeUid,
                    followedAt: new Date(),
                });
                console.log('User followed successfully!');
            } else {
                // Unfollow: Remove the document from Firestore
                await deleteDoc(doc(db, 'follows', `${followerUid}_${followeeUid}`));
                console.log('User unfollowed successfully!');
            }
        } catch (error) {
            console.error('Error updating follow status: ', error);
        }
    };

    // Show loading state while checking the follow status
    if (loading) {
        return <button className="follow-button" disabled>Loading...</button>
    }

    return (
        <button className="follow-button" onClick={handleToggleFollow}>
            {isFollowed ? 'Unfollow' : 'Follow'}
        </button>
    );
}

export default Follow;
