import React from 'react';
import ComChat from '../../components/comChat/ComChat';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase'; // Update this path to your firebase.js file
import './Chat.css'
import { useNavigate } from 'react-router-dom';

function Chat() {
  const [user] = useAuthState(auth);

 
  const currentUserId = user ? user.uid : null;
  const navigate = useNavigate();
 
  if (!currentUserId) {
    
    return <div className="registration-container">
    <text>START YOUR JOURNEY WITH US</text>
    <div className="sidenav__more"> 
      <button className="glow-on-hover1" onClick={() => navigate('/login')}>
        <div className="texREGISTER">
           REGISTER
        </div>
      </button>
    </div>
  </div>
  }

  return (
    <>
        <ComChat postingUserId={currentUserId} />
    </>
  );
}

export default Chat;

