 /*


import React, { useEffect, useRef } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // Ensure you have the correct path to your Firebase config
import './ChatBody.css';

function ChatBody({ selectedChat, currentUid, messages, setMessages }) {
  const messageContainerRef = useRef(null);
  const messageEndRef = useRef(null);

  useEffect(() => {
    if (!selectedChat?.id || !currentUid) {
      console.error("Selected chat and current user ID must be defined.");
      return;
    }

    const messagesQuery = query(
      collection(db, `chats/${selectedChat.id}/conversacion`),
      orderBy('timestamp', 'desc'),
      limit(500)
    );

    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const newMessages = querySnapshot.docChanges().map(change => ({
        ...change.doc.data(),
        id: change.doc.id
      })).reverse(); // Reverse the messages to display in correct order

      setMessages(prevMessages => {
        const merged = new Map([...prevMessages, ...newMessages].map(msg => [msg.id, msg]));
        return Array.from(merged.values());
      });
    });

    return () => {
      unsubscribe();
    };
  }, [selectedChat, currentUid, setMessages]);

  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
      const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 1;
      if (isScrolledToBottom || !messages.length) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="conChatChido1" ref={messageContainerRef}>
      {selectedChat ? (
        <div className="messageContainer55">
          {messages
            .sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0)) // Ensure sorting by timestamp
            .map((message, index, arr) => {
              const currentDate = new Date(message.timestamp?.seconds * 1000).toLocaleDateString();
              const previousDate = index > 0 ? new Date(arr[index - 1].timestamp?.seconds * 1000).toLocaleDateString() : null;

              return (
                <React.Fragment key={message.id}>
                  <div className={`message1 ${message.sentBy === currentUid ? 'sentByYou' : 'sentByPartner'}`}>
                    {message.imageUrl ? (
                      <img src={message.imageUrl} alt="Sent image" className="chat-image" />
                    ) : message.videoUrl ? (
                      <video controls className="chat-video">
                        <source src={message.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : message.pdfUrl ? (
                      <div className="chat-pdf">
                        <a href={message.pdfUrl} target="_blank" rel="noopener noreferrer">View PDF</a>
                      </div>
                    ) : (
                      <span dangerouslySetInnerHTML={{ __html: message.Text }} />
                    )}
                  </div>
                  {(index === 0 || currentDate !== previousDate) && (
                    <div className="date">{currentDate}</div>
                  )}
                </React.Fragment>
              );
            })}
          <div ref={messageEndRef} />
        </div>
      ) : (
        <p>No chat selected.</p>
      )}
    </div>
  );
}

export default ChatBody;
 
*/
import React, { useEffect, useRef } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // Ensure you have the correct path to your Firebase config
import './ChatBody.css';

function ChatBody({ selectedChat, currentUid, messages, setMessages }) {
  const messageContainerRef = useRef(null);
  const messageEndRef = useRef(null);

  useEffect(() => {
    if (!selectedChat?.id || !currentUid) {
      console.error("Selected chat and current user ID must be defined.");
      return;
    }

    const messagesQuery = query(
      collection(db, `chats/${selectedChat.id}/conversacion`),
      orderBy('timestamp', 'desc'),
      limit(500)
    );

    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const newMessages = querySnapshot.docChanges().map(change => ({
        ...change.doc.data(),
        id: change.doc.id
      })).reverse(); // Reverse the messages to display in correct order

      setMessages(prevMessages => {
        const merged = new Map([...prevMessages, ...newMessages].map(msg => [msg.id, msg]));
        return Array.from(merged.values());
      });
    });

    return () => {
      unsubscribe();
    };
  }, [selectedChat, currentUid, setMessages]);

  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
      const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 1;
      if (isScrolledToBottom || !messages.length) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Helper function to render a map
  const renderMap = (lat, lng) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}&z=15&output=embed`;
    return (
      <iframe
        title="location"
        width="250"
        height="200"
        style={{ border: 0 }}
        src={url}
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <div className="conChatChido1" ref={messageContainerRef}>
      {selectedChat ? (
        <div className="messageContainer55">
          {messages
            .sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0)) // Ensure sorting by timestamp
            .map((message, index, arr) => {
              const currentDate = new Date(message.timestamp?.seconds * 1000).toLocaleDateString();
              const previousDate = index > 0 ? new Date(arr[index - 1].timestamp?.seconds * 1000).toLocaleDateString() : null;

              return (
                <React.Fragment key={message.id}>
                  <div className={`message1 ${message.sentBy === currentUid ? 'sentByYou' : 'sentByPartner'}`}>
                    {message.imageUrl ? (
                      <img src={message.imageUrl} alt="Sent image" className="chat-image" />
                    ) : message.videoUrl ? (
                      <video controls className="chat-video">
                        <source src={message.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : message.pdfUrl ? (
                      <div className="chat-pdf">
                        <a href={message.pdfUrl} target="_blank" rel="noopener noreferrer">View PDF</a>
                      </div>
                    ) : message.location ? ( // Check if the message contains a location
                      <div className="chat-location">
                        {renderMap(message.location.latitude, message.location.longitude)}
                      </div>
                    ) : (
                      <span dangerouslySetInnerHTML={{ __html: message.Text }} />
                    )}
                  </div>
                  {(index === 0 || currentDate !== previousDate) && (
                    <div className="date">{currentDate}</div>
                  )}
                </React.Fragment>
              );
            })}
          <div ref={messageEndRef} />
        </div>
      ) : (
        <p>No chat selected.</p>
      )}
    </div>
  );
}

export default ChatBody;
