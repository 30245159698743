/*
import React from 'react'

function ImgProfile({useruid}) {
  return (
    <div>I</div>
  )
}

export default ImgProfile
*/
import React, { useState, useEffect } from 'react';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../../../../firebase/firebase'; // Ensure Firebase is configured correctly
import { FaUser } from 'react-icons/fa';
import './imgProfile.css'

function ProfileImage({ useruid }) {
  const [userInfo, setUserInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch from the infoUser collection
        const userQuery = query(collection(db, 'infoUser'), where('uid', '==', useruid));
        const userSnapshot = await getDocs(userQuery);
        const userData = userSnapshot.docs.map(doc => doc.data())[0]; // Assuming one user matches
        console.log('User Data:', userData);

        // Fetch from the company collection
        const companyQuery = query(collection(db, 'company'), where('uid', '==', useruid));
        const companySnapshot = await getDocs(companyQuery);
        const companyData = companySnapshot.docs.map(doc => doc.data())[0]; // Assuming one company matches
        console.log('Company Data:', companyData);

        setUserInfo(userData || null);
        setCompanyInfo(companyData || null);
      } catch (err) {
        console.error('Error fetching user or company data:', err);
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    if (useruid) {
      fetchUserData();
    } else {
      setError('Invalid UID.');
      setLoading(false);
    }
  }, [useruid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!userInfo && !companyInfo) {
    return <div>No data found for this user.</div>;
  }

  return (
<div className="comments-profile-container">
  {userInfo && (
    <div className="comments-profile-info">
      {userInfo.imgPerfil ? (
        <img className="comments-profile-image" src={userInfo.imgPerfil} alt="User Profile" />
      ) : (
        <FaUser size={50} className="comments-default-icon" />
      )}

      {userInfo.firstName ? (
        <div className="comments-profile-name">{userInfo.firstName}</div>
      ) : null}
    </div>
  )}
  {companyInfo && (
    <div className="comments-company-info">
      {companyInfo.imgPerfil ? (
        <img className="comments-profile-image" src={companyInfo.imgPerfil} alt="Company Profile" />
      ) : (
        <FaUser size={50} className="comments-default-icon" />
      )}

      {companyInfo.nameCompany ? (
        <div className="comments-company-name">{companyInfo.nameCompany}</div>
      ) : null}
    </div>
  )}
</div>
  );
}

export default ProfileImage;