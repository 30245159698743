/* 

import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth  } from '../../firebase/firebase';
import Buscador from './buscador/Buscador';
import ListChat from './listChat/listChat'
import './ComChat.css'
import { useNavigate } from 'react-router-dom';
import Logo from '../../../src/assets/logo.png'

function ComChat( ) {
  const [chats, setChats] = useState([]);
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUserUID) {
      console.error("No user logged in");
      return;
    }

    
    const chatsMap = new Map();

    const fetchChats = () => {
      const chatsRef = collection(db, 'chats');
      const createdByQuery = query(chatsRef, where('createdBy', '==', currentUserUID));
      const sendToQuery = query(chatsRef, where('SendTo', '==', currentUserUID));

      // Function to handle new chat data and update the map
      const handleNewChatData = (snapshot) => {
        snapshot.docs.forEach(doc => {
          chatsMap.set(doc.id, { id: doc.id, ...doc.data() });
        });
        // Convert the map to an array and update the state
        setChats(Array.from(chatsMap.values()));
      };

      const unsubscribeCreatedBy = onSnapshot(createdByQuery, handleNewChatData);
      const unsubscribeSendTo = onSnapshot(sendToQuery, handleNewChatData);

      // Return the unsubscribe functions to clean up the listeners
      return () => {
        unsubscribeCreatedBy();
        unsubscribeSendTo();
      };
    };

    const unsubscribe = fetchChats();

    // Unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentUserUID]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
 
  return (
   <div className="chatContainer1">
      <div className="stickySearch">
        <div onClick={() => navigate('/')} className="appleButton">
        <img src={Logo} className="center-image" alt="description" /> 
      </div> 
        <Buscador onSearch={handleSearch}/>
      </div>
        <div className="chatList">
          <ListChat  currentUid={currentUserUID} searchQuery={searchQuery} />
        </div>
    </div>
  );
}


export default ComChat;
*/

import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth  } from '../../firebase/firebase';
import Buscador from './buscador/Buscador';
import ListChat from './listChat/listChat'
import './ComChat.css'
import { useNavigate } from 'react-router-dom';
import Logo from '../../../src/assets/logo.png'


function ComChat( ) {
  const [chats, setChats] = useState([]);
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUserUID) {
      console.error("No user logged in");
      return;
    }

    
    const chatsMap = new Map();

    const fetchChats = () => {
      const chatsRef = collection(db, 'chats');
      const createdByQuery = query(chatsRef, where('createdBy', '==', currentUserUID));
      const sendToQuery = query(chatsRef, where('SendTo', '==', currentUserUID));

      // Function to handle new chat data and update the map
      const handleNewChatData = (snapshot) => {
        snapshot.docs.forEach(doc => {
          chatsMap.set(doc.id, { id: doc.id, ...doc.data() });
        });
        // Convert the map to an array and update the state
        setChats(Array.from(chatsMap.values()));
      };

      const unsubscribeCreatedBy = onSnapshot(createdByQuery, handleNewChatData);
      const unsubscribeSendTo = onSnapshot(sendToQuery, handleNewChatData);

      // Return the unsubscribe functions to clean up the listeners
      return () => {
        unsubscribeCreatedBy();
        unsubscribeSendTo();
      };
    };

    const unsubscribe = fetchChats();

    // Unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentUserUID]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
 
  return (
   <div className="chatContainer1">
      <div className="stickySearch">
        <div onClick={() => navigate('/')} className="appleButton">
        <img src={Logo} className="center-image" alt="description" /> 
      </div> 
        <Buscador onSearch={handleSearch}/>
      </div>
        <div className="chatList">
          <ListChat  currentUid={currentUserUID} searchQuery={searchQuery} />
        </div>
    </div>
  );
}


export default ComChat;

