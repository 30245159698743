 /*

import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"; // Import storage functionalities
import { storage, db } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './EditPerfil.css'


function EditPerfil({ user, setUser, setIsEditing, userUid }) {
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [city, setCity] = useState(user.city);
    const [phone, setPhone] = useState(user.phone);
    const [positionJob, setPositionJob] = useState(user.positionJob);
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(user.imgPerfil || '');
    const [imagePreviewUrlBack, setImagePreviewUrlBack] = useState(user.imgBack || '');   // State to hold the preview URL
    const imageInputRef = useRef();

    const [userEmail, setUserEmail] = useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // If an image is selected, update the preview
            setImage(file);
            setImagePreviewUrl(URL.createObjectURL(file));
        } else {
            // If no image is selected, clear the preview
            setImage(null);
            setImagePreviewUrl('');
        }
    };

    const handleImageChangeBack = (e) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            setImage(file);
            setImagePreviewUrlBack(URL.createObjectURL(file));
        }
    };


    const handleUpdate = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName || !city || !phone || !positionJob) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        // Upload the image to Firebase Storage and get the URL
        let imgUrl = user.imgPerfil; // Default to the old image URL if no new image is selected
        if (image) {
            const imageRef = ref(storage, `profile_images/${userUid}/${image.name}`);
            const snapshot = await uploadBytes(imageRef, image);
            imgUrl = await getDownloadURL(snapshot.ref);
        }

        // Construct the query with the user's UID
        const q = query(collection(db, "infoUser"), where("uid", "==", userUid));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: `No user profile found for UID: ${userUid}`,
                    showConfirmButton: true,
                });
                return;
            }

            // Assume the first document is the correct one
            const userDocRef = querySnapshot.docs[0].ref;
            await updateDoc(userDocRef, {
                firstName,
                lastName,
                city,
                phone,
                positionJob,
                imgPerfil: imgUrl  // Update the imgPerfil field with new image URL
            });

            setUser({ ...user, firstName, lastName, city, phone, positionJob, imgPerfil: imgUrl });
            setIsEditing(false);

            Swal.fire({
                icon: 'success',
                title: 'Updated!',
                text: `${firstName} ${lastName}'s data has been updated.`,
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error("Error updating document: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Update Failed!',
                text: 'There was a problem updating your profile.',
                showConfirmButton: true,
            });
        }
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserEmail(user.email);
            } else {
                // User is signed out
                setUserEmail('');
            }
        });
    
        return () => unsubscribe(); // Cleanup function
    }, []);

    return (
    <div className="profiles-postC">
        <div className="twitter-Post">
          
    

    <div className="posCon1Edit">


    <input
    id="profileImageBack"
    type="file"
    ref={imageInputRef}
    onChange={handleImageChangeBack}
    style={{ display: 'none' }} // Hide the file input
        />
        <div onClick={() => imageInputRef.current.click()}>
            {imagePreviewUrlBack && <img src={imagePreviewUrlBack} alt="Profile" className="profile-back33" />}
        </div>



        <input
    id="profileImage"
    type="file"
    ref={imageInputRef}
    onChange={handleImageChange}
    style={{ display: 'none' }} // Hide the file input
/>
<div onClick={() => imageInputRef.current.click()}>
    {imagePreviewUrl && <img src={imagePreviewUrl} alt="Profile" className="profile-preview33" />}
</div>
    </div>
 


    <div className="posCon2Edit">


    <div className="form-row">
                <label htmlFor="email" className="input-label">{userEmail}</label>
                 
            </div>


      <label htmlFor="firstName" className="input-label">First Name</label>
        <input
            id="firstName"
            type="text"
            name="firstName"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            className="entrada"
            required
        />
     
   

    <div className="form-row">
        <label htmlFor="lastName" className="input-label">Last Name</label>
        <input
                className="entrada"
                required
                id="lastName"
                type="text"
                name="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
            />
         
    </div>

    <div className="form-row">
        <label htmlFor="city" className="input-label">City</label>
        <input
                className="entrada"
                id="city"
                type="text"
                name="city"
                value={city}
                onChange={e => setCity(e.target.value)}
            />
          
          
    </div>
    <div className="form-row">
         <label htmlFor="phone" className="input-label">Phone</label>
        <input
        className="entrada"
            id="phone"
            type="text"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            />
          
         
    </div>
    <div className="form-row">
         <label htmlFor="positionJob" className="input-label">Position Job</label>
        <input
        className="entrada"
        id="positionJob"
        type="text"
        name="positionJob"
        value={positionJob}
        onChange={e => setPositionJob(e.target.value)}
            />     
        </div>

     

        <div className="form-actions">
            <button className="buttonSudmitAdd" onClick={() => setIsEditing(false)}>Cancel</button>
            <button className="buttonSudmitAdd" onClick={handleUpdate} >Add</button>
        </div>


        </div>
  
        </div>
    </div>
    );
}

export default EditPerfil;
*/
 
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"; // Import storage functionalities
import { storage, db } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './EditPerfil.css'
import { IoIosImages } from "react-icons/io";


function EditPerfil({ user, setUser, setIsEditing, userUid }) {
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [city, setCity] = useState(user.city);
    const [phone, setPhone] = useState(user.phone);
    const [positionJob, setPositionJob] = useState(user.positionJob);
    const [imageProfile, setImageProfile] = useState(null); // For profile image
    const [imageBack, setImageBack] = useState(null);

    const [imagePreviewUrl, setImagePreviewUrl] = useState(user.imgPerfil || '');
    const [imagePreviewUrlBack, setImagePreviewUrlBack] = useState(user.imgBack || '');   // State to hold the preview URL
    const [userEmail, setUserEmail] = useState('');

    const imageInputRef = useRef(); // For profile image
    const imageBackInputRef = useRef(); // For background image

   
const handleImageProfile = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImageProfile(file);
        setImagePreviewUrl(URL.createObjectURL(file));
    } else {
        setImageProfile(null);
        setImagePreviewUrl('');
    }
};

const handleImageChangeBack = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImageBack(file);
        setImagePreviewUrlBack(URL.createObjectURL(file));
    } else {
        setImageBack(null);
        setImagePreviewUrlBack('');
    }
};


    const handleUpdate = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName || !city || !phone || !positionJob) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        // Upload the image to Firebase Storage and get the URL
        let imgUrl = user.imgPerfil; // Default to the old image URL if no new image is selected
        if (imageProfile) { // Use imageProfile here
            const imageRef = ref(storage, `profile_images/${userUid}/${imageProfile.name}`);
            const snapshot = await uploadBytes(imageRef, imageProfile); // Use imageProfile here
            imgUrl = await getDownloadURL(snapshot.ref);
        }
        
        let imgBackUrl = user.imgBack; // Default to the old image URL if no new image is selected
        if (imageBack) { // Use imageBack here
            const imageBackRef = ref(storage, `background_images/${userUid}/${imageBack.name}`);
            const snapshot = await uploadBytes(imageBackRef, imageBack); // Use imageBack here
            imgBackUrl = await getDownloadURL(snapshot.ref);
        }
        

        // Construct the query with the user's UID
        const q = query(collection(db, "infoUser"), where("uid", "==", userUid));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: `No user profile found for UID: ${userUid}`,
                    showConfirmButton: true,
                });
                return;
            }

            // Assume the first document is the correct one
            const userDocRef = querySnapshot.docs[0].ref;
            await updateDoc(userDocRef, {
                firstName,
                lastName,
                city,
                phone,
                positionJob,
                imgPerfil: imgUrl, // Update the imgPerfil field with new image URL
                imgBack: imgBackUrl,
            });

            setUser({ ...user, firstName, lastName, city, phone, positionJob, imgPerfil: imgUrl, imgBack: imgBackUrl });
            setIsEditing(false);

            Swal.fire({
                icon: 'success',
                title: 'Updated!',
                text: `${firstName} ${lastName}'s data has been updated.`,
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error("Error updating document: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Update Failed!',
                text: 'There was a problem updating your profile.',
                showConfirmButton: true,
            });
        }
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserEmail(user.email);
            } else {
                // User is signed out
                setUserEmail('');
            }
        });
    
        return () => unsubscribe(); // Cleanup function
    }, []);

    return (
    <div className="profiles-postC7">
  
  

        <div className="posCon1Edit">
        <div className="form-actions">
            <button className="buttonSudmitCansel7" onClick={() => setIsEditing(false)}>Cancel</button>
            <button className="buttonSudmitAdd" onClick={handleUpdate} >Add</button>
        </div>
             <input
                    id="profileImageBack"
                    type="file"
                    ref={imageBackInputRef} // Use the ref for the background image
                    onChange={handleImageChangeBack}
                    style={{ display: 'none' }}
                />
                <div lassName="cont-Image-ch" onClick={() => imageBackInputRef.current.click()}>
                    {imagePreviewUrlBack ? (
                     <div className="parent-container">
                        <img src={imagePreviewUrlBack} alt="Profile" className="profile-back33" />
                      </div>
                      
                    ) : (
                        <div className="parent-container">
                          <div className="default-div">
                            <IoIosImages className="search-icon" size={40}/>
                            <div className="t34">Choose one image</div>
                          </div>
                        </div>
                    )}
                </div>


                <input
                    id="profileImage"
                    type="file"
                    ref={imageInputRef} // Use the ref for the profile image
                    onChange={handleImageProfile}
                    style={{ display: 'none' }}
                />
                <div onClick={() => imageInputRef.current.click()}>
                    {imagePreviewUrl ? (
                         <div className="parent-container">
                             <img src={imagePreviewUrl} alt="Profile" className="profile-preview33" />
                        </div>
                    ) : (
                        <div className="parent-container">
                             <div className="default-div-profile">
                                <IoIosImages className="search-icon" size={40}/>
                                <div className="t34">Choose one</div>
                             </div>
                        </div>
                    )}
                </div>
                </div>

    <div className="posCon2Edit">


        <div className="form-row">
            <label htmlFor="firstName" className="input-label">Owner</label>
            <label htmlFor="email" className="input-label"> {userEmail}</label>
         </div>


      <label htmlFor="firstName" className="input-label">First Name</label>
        <input
            id="firstName"
            type="text"
            name="firstName"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            className="entrada"
            required
        />
     
   

    <div className="form-row">
        <label htmlFor="lastName" className="input-label">Last Name</label>
        <input
                className="entrada"
                required
                id="lastName"
                type="text"
                name="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
            />
         
    </div>

    <div className="form-row">
        <label htmlFor="city" className="input-label">City</label>
        <input
                className="entrada"
                id="city"
                type="text"
                name="city"
                value={city}
                onChange={e => setCity(e.target.value)}
            />
          
          
    </div>
    <div className="form-row">
         <label htmlFor="phone" className="input-label">Phone</label>
        <input
        className="entrada"
            id="phone"
            type="text"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            />
          
         
    </div>
    <div className="form-row">
         <label htmlFor="positionJob" className="input-label">Position Job</label>
        <input
        className="entrada"
        id="positionJob"
        type="text"
        name="positionJob"
        value={positionJob}
        onChange={e => setPositionJob(e.target.value)}
            />     
        </div>

        </div>
  
        </div>
 
    );
}

export default EditPerfil;




