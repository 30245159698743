/*

import React, { useState, useEffect } from "react";
import { storage, db, auth } from "../firebase/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import "./SetUpAccount.css";
import { GrImage } from "react-icons/gr";
import { IconContext } from "react-icons";
import { useTranslation } from 'react-i18next';

function StoreImageTextFirebase() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [imgPerfil, setImgPerfil] = useState('');
  const [imgBack, setImgBack] = useState('');
  const [imgPerfilPreview, setImgPerfilPreview] = useState('');
  const [imgBackPreview, setImgBackPreview] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [positionJob, setPositionJob] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState(''); 
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const [profileProgress, setProfileProgress] = useState(0);
  const [backgroundProgress, setBackgroundProgress] = useState(0);
  const [formProgress, setFormProgress] = useState(0); 
  const [totalProgress, setTotalProgress] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/Login'); 
      }
    });

    return () => unsubscribe(); 
  }, [navigate]);

  useEffect(() => {
    let completedFields = 0;
    if (firstName) completedFields++;
    if (lastName) completedFields++;
    if (city) completedFields++;
    if (positionJob) completedFields++;
    if (phone) completedFields++;
    if (username) completedFields++;

    const formProgress = (completedFields / 6) * 100; 
    setFormProgress(formProgress);

    const isValid = completedFields === 6;
    setAllFieldsValid(isValid);
  }, [firstName, lastName, city, positionJob, phone, username]);

  useEffect(() => {
    const totalProgress = (formProgress + profileProgress + backgroundProgress) / 3;
    setTotalProgress(totalProgress); 
  }, [formProgress, profileProgress, backgroundProgress]);

  const handleUpload = async (file, imageType) => {
    const fileRef = ref(storage, `FotosPerfilUser/${uuidv4()}-${imageType}`);
    try {
      const snapshot = await uploadBytes(fileRef, file);
      const url = await getDownloadURL(snapshot.ref);
      if (imageType === 'profile') {
        setImgPerfil(url);
        setImgPerfilPreview(url);
        setProfileProgress(100); 
      } else if (imageType === 'background') {
        setImgBack(url);
        setImgBackPreview(url);
        setBackgroundProgress(100); 
      }
    } catch (error) {
      console.error(`Error uploading ${imageType} image:`, error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Error',
        text: `There was a problem uploading the ${imageType} image.`,
      });
      if (imageType === 'profile') setProfileProgress(0);
      if (imageType === 'background') setBackgroundProgress(0);
    }
  };

  const handleImageChange = (event, imageType) => {
    const file = event.target.files[0];
    if (file) {
      if (imageType === 'profile') setProfileProgress(50); 
      if (imageType === 'background') setBackgroundProgress(50); 
      handleUpload(file, imageType);
    }
  };

  const triggerFileInput = (imageType) => {
    if (imageType === 'profile') {
      document.getElementById('profile-image-upload').click();
    } else if (imageType === 'background') {
      document.getElementById('back-image-upload').click();
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!allFieldsValid) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: true,
      });
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No user is signed in.',
      });
      return;
    }

    const usernameWithSuffix = `${username}@laboor`;

    const dataUser = {
      imgBack,
      imgPerfil,
      firstName,
      lastName,
      city,
      positionJob,
      phone,
      username: usernameWithSuffix, 
      uid: user.uid,
    };

    try {
      await addDoc(collection(db, "infoUser"), dataUser);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: `${firstName}'s data has been added.`,
        timer: 1500,
      });
      navigate("/SearchJob");
    } catch (error) {
      console.error("Error adding document to Firestore:", error);
      Swal.fire({
        icon: 'error',
        title: 'Database Error',
        text: 'Failed to add the data to the database.',
      });
    }
  };

  return (
    <div className='background10'>
      <div className='containerForm'>
        <div className="progress-bar-container">
          <div className="progressSet" style={{ width: `${totalProgress}%` }}>
            {Math.round(totalProgress)}%
          </div>
        </div>
        
        <form onSubmit={handleAdd} style={{ maxWidth: 500, margin: "auto" }}>
          <div className='aling-conte-input'>
            <input
              type="text"
              className="inputTexSet"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={t("First Name")}
            />
              
              <input
              className="inputTexSet"
                type="text" 
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t("Last Name")}
              />
              <input
                type="text"
                className="inputTexSet"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder={t("City")}
              />
              
              <input
                type="text"
                className="inputTexSet"
                value={positionJob}
                onChange={(e) => setPositionJob(e.target.value)}
                placeholder={t("Description of your skills")}
              />
              <input
                type="text"
                className="inputTexSet"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder={t("Phone")}
              />
              <input
                type="text"
                className="inputTexSet"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={t("Username")}
              />
          </div>

          <div className="sd1">{t("Adding images to your profile")}</div>

          <div className="profile-container6">
            <div className="background-image-container1" onClick={() => triggerFileInput('background')}>
              {imgBackPreview ? (
                <img src={imgBackPreview} alt="Background" className="background-image" />
              ) : (
                <div className="no-image-back">
                  <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                    <GrImage />
                    <div className="ndd">Profile Feed</div>
                  </IconContext.Provider>
                </div>
              )}
              <input
                id="back-image-upload"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, 'background')}
                style={{ display: 'none' }}
              />
            </div>

            <div className="conButtonApply1">
              <div className="profile-image-container1" onClick={() => triggerFileInput('profile')}>
                {imgPerfilPreview ? (
                  <div className="ceterl">
                    <img src={imgPerfilPreview} alt="Profile" className="profile-image" />
                  </div>
                ) : (
                  <div className="no-image-profile">
                    <IconContext.Provider value={{ size: '30px' }}>
                      <GrImage />
                    </IconContext.Provider>
                    <div className="ndd">Profile Image</div>
                  </div>
                )}
                <input
                  id="profile-image-upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 'profile')}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>

          <div className="conButtonApply1">
            <button type="submit" className="buttonApply1">{t("Save Information")}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StoreImageTextFirebase;
 

 */
import React, { useState, useEffect } from "react";
import { storage, db, auth } from "../firebase/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import "./SetUpAccount.css";
import { FaPhone, FaCity, FaBriefcase, FaUser, FaImage } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function StoreImageTextFirebase() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [imgPerfil, setImgPerfil] = useState('');
  const [imgBack, setImgBack] = useState('');
  const [imgPerfilPreview, setImgPerfilPreview] = useState('');
  const [imgBackPreview, setImgBackPreview] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [positionJob, setPositionJob] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState(''); 
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const [profileProgress, setProfileProgress] = useState(0);
  const [backgroundProgress, setBackgroundProgress] = useState(0);
  const [formProgress, setFormProgress] = useState(0); 
  const [totalProgress, setTotalProgress] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/Login'); 
      }
    });

    return () => unsubscribe(); 
  }, [navigate]);

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };


  useEffect(() => {
    let completedFields = 0;
    if (firstName) completedFields++;
    if (lastName) completedFields++;
    if (city) completedFields++;
    if (positionJob) completedFields++;
    if (phone) completedFields++;
    if (username) completedFields++;

    const formProgress = (completedFields / 6) * 100; 
    setFormProgress(formProgress);

    const isValid = completedFields === 6;
    setAllFieldsValid(isValid);
  }, [firstName, lastName, city, positionJob, phone, username]);

  useEffect(() => {
    const totalProgress = (formProgress + profileProgress + backgroundProgress) / 3;
    setTotalProgress(totalProgress); 
  }, [formProgress, profileProgress, backgroundProgress]);

  const handleUpload = async (file, imageType) => {
    const fileRef = ref(storage, `FotosPerfilUser/${uuidv4()}-${imageType}`);
    try {
      const snapshot = await uploadBytes(fileRef, file);
      const url = await getDownloadURL(snapshot.ref);
      if (imageType === 'profile') {
        setImgPerfil(url);
        setImgPerfilPreview(url);
        setProfileProgress(100); 
      } else if (imageType === 'background') {
        setImgBack(url);
        setImgBackPreview(url);
        setBackgroundProgress(100); 
      }
    } catch (error) {
      console.error(`Error uploading ${imageType} image:`, error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Error',
        text: `There was a problem uploading the ${imageType} image.`,
      });
      if (imageType === 'profile') setProfileProgress(0);
      if (imageType === 'background') setBackgroundProgress(0);
    }
  };

  const handleImageChange = (event, imageType) => {
    const file = event.target.files[0];
    if (file) {
      if (imageType === 'profile') setProfileProgress(50); 
      if (imageType === 'background') setBackgroundProgress(50); 
      handleUpload(file, imageType);
    }
  };

  const triggerFileInput = (imageType) => {
    if (imageType === 'profile') {
      document.getElementById('profile-image-upload').click();
    } else if (imageType === 'background') {
      document.getElementById('back-image-upload').click();
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!allFieldsValid) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: true,
      });
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No user is signed in.',
      });
      return;
    }

    const usernameWithSuffix = `${username}@laboor`;

    const dataUser = {
      imgBack,
      imgPerfil,
      firstName,
      lastName,
      city,
      positionJob,
      phone,
      username: usernameWithSuffix, 
      uid: user.uid,
    };

    try {
      await addDoc(collection(db, "infoUser"), dataUser);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: `${firstName}'s data has been added.`,
        timer: 1500,
      });
      navigate("/employmentInterest");
    } catch (error) {
      console.error("Error adding document to Firestore:", error);
      Swal.fire({
        icon: 'error',
        title: 'Database Error',
        text: 'Failed to add the data to the database.',
      });
    }
  };

  return (
    <div className='backGraudCompany'>

          <button onClick={handleGoBack}  className="goback78">
              <div className="g223g">
                {t("go back")}
              </div>
            </button>    

      <div className='containerFormCompany'>
        <div className="progress-bar-container">
          <div className="progressSet" style={{ width: `${totalProgress}%` }}>
            {Math.round(totalProgress)}%
          </div>
        </div>

        <form onSubmit={handleAdd}>
          <div className="c790">{t("User Account Setup")}</div>
          <div className='form-layout'>
            <div className='conteCompany'>

            <div className="input-with-icon">
                <FaUser className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder={t("Username")}
                />
              </div>

              <div className="input-with-icon">
                <FaUser className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={t("First Name")}
                />
              </div>

              <div className="input-with-icon">
                <FaUser className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={t("Last Name")}
                />
              </div>

              <div className="input-with-icon">
                <FaCity className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder={t("City")}
                />
              </div>

              <div className="input-with-icon">
                <FaBriefcase className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={positionJob}
                  onChange={(e) => setPositionJob(e.target.value)}
                  placeholder={t("Description of your skills")}
                />
              </div>

              <div className="input-with-icon">
                <FaPhone className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder={t("Phone")}
                />
              </div>

           
            </div>

            <div className="profile-container">
              <div className="background-image-container1" onClick={() => triggerFileInput('background')}>
                {imgBackPreview ? (
                  <img src={imgBackPreview} alt="Background" className="background-image"/>
                ) : (
                  <div className="no-image-back">
                    <FaImage className="icon79" />
                  </div>
                )}
                <input
                  type="file"
                  id="profile-image-upload"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 'background')}
                  style={{ display: "none" }}
                />
              </div>

              <div className="profile-image-container" onClick={() => triggerFileInput('profile')}>
                {imgPerfilPreview ? (
                  <img src={imgPerfilPreview} alt="Profile" className="profile-image"/>
                ) : (
                  <div className="no-image-profile">
                    <FaUser className="icon79" />
                  </div>
                )}
                <input
                  type="file"
                  id="back-image-upload"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 'profile')}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            </div>

          <div className="conButtonApply1">
          <button type="submit" disabled={!allFieldsValid} className="b798">
            <div className="t768">
              {t("Next")}
            </div>
          </button>    
        </div>    
        </form>
      </div>
    </div>
  );
}

export default StoreImageTextFirebase;
