import React from 'react'
import './HeaderBlock.css'
import teslaVid from '../assets/TijuanaV.mp4'
import { useNavigate } from "react-router-dom";
 

function HeaderBlock() {

  const navigate = useNavigate();
  
  function handleClick() {
    navigate("/add");
  }

  function handleClick2() {
    navigate("/WeNeedPeople");
  }
   

  return (
    <div>
    <video className="video-background" autoPlay muted loop>
      <source src={teslaVid} type="video/mp4" />
    </video>
    <div className='headerBlock__info'>
        <div className='headerBlock__infoText'>
          <h2 className='titulo1'>THE NUMBER ONE GIVING JOBS IN MEXICO</h2>
          <h3 className='titulo2'>
            Somos una empresa que proporocina trabajo para bilingues en <span className='titulo3'>Tijuana/Cdmx</span>
          </h3>
        </div>
        <div className='headerBlock__actions'>
          <button className='headerBlock__buttonPrimary'  onClick={handleClick2}>I NEED PEOPLE</button>
          <button className='headerBlock__buttonSecondary' onClick={handleClick} >
            i need job           
          </button>
        </div>
      </div>
  </div>
  )
}

export default HeaderBlock



/*
 <div className='headerBlock'>
       
      <div className='headerBlock__info'>
        <div className='headerBlock__infoText'>
          <h1>THE NUMBER ONE GIVING JOBS IN MEXICO</h1>
          <h4>
            Somos una empresa que proporocina trabajo para bilingues en<span>Tijuana</span>
          </h4>
        </div>
        <div className='headerBlock__actions'>
          <button className='headerBlock__buttonPrimary'>I NEED PEOPLE</button>
          <button className='headerBlock__buttonSecondary'>
            i need job           
          </button>
        </div>
      </div>
    </div>*/