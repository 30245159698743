/*
import React from 'react';

function ImgProfile({ selectedChat }) {
  // Destructure properties from selectedChat object
  const {   createdBy  } = selectedChat;

  return (
    <div>
 
      <p>CreatedBy: {createdBy}</p>
    
    </div>
  );
}

export default ImgProfile;
*/
/*

import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../../firebase/firebase';
import './imgProfile.css';

function ImgProfile({ selectedChat, currentUid }) {
  const [userProfiles, setUserProfiles] = useState([]);
  const [companyProfiles, setCompanyProfiles] = useState([]);
  const [currentUid, setCurrentUid] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, set the current UID
        setCurrentUid(user.uid);
      } else {
        // No user is signed in, reset current UID
        setCurrentUid('');
      }
    });

    return () => {
      // Unsubscribe from the auth state listener when the component unmounts
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userQuery;
        let companyQuery;
  
        if (selectedChat) {
          if (selectedChat.createdBy === currentUid) {
            // If the current user created the chat, query by SendTo for users and createdBy for companies
            userQuery = query(collection(db, 'infoUser'), where('SendTo', '==', currentUid));
            companyQuery = query(collection(db, 'company'), where('createdBy', '==', currentUid));
          } else if (selectedChat.SendTo === currentUid) {
            // If the current user is the recipient, query by createdBy for users and SendTo for companies
            userQuery = query(collection(db, 'infoUser'), where('uid', '==', selectedChat.createdBy));
            companyQuery = query(collection(db, 'company'), where('SendTo', '==', selectedChat.createdBy));
          }
  
          const userDataSnapshot = await getDocs(userQuery);
          const companyDataSnapshot = await getDocs(companyQuery);
  
          // Map the data from the query snapshots and store them in userProfiles and companyProfiles states
          const userProfilesData = userDataSnapshot.docs.map((doc) => doc.data());
          setUserProfiles(userProfilesData);
  
          const companyProfilesData = companyDataSnapshot.docs.map((doc) => doc.data());
          setCompanyProfiles(companyProfilesData);
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };
  
    fetchData();
  
    return () => {
      setUserProfiles([]);
      setCompanyProfiles([]);
    };
  }, [selectedChat, currentUid]);
  

  return (
    <div>
      {userProfiles.map((profile, index) => (
        <div key={`user-${index}`}>
          <img src={profile.imgPerfil} alt="Profile Image" className="profile-image1" />
          <p>{profile.firstName}</p>
        </div>
      ))}
    
      {companyProfiles.map((profile, index) => (
        <div key={`company-${index}`}>
          <img src={profile.imgPerfil} alt="Profile Image" className="profile-image1" />
          <p>{profile.companyName}</p>
        </div>
      ))}
    </div>
  );
}

export default ImgProfile;

*/

 
/*
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {  db } from '../../../../firebase/firebase';
import './imgProfile.css';

function ImgProfile({ selectedChat, currentUid }) {
  const [userProfiles, setUserProfiles] = useState([]);
  const [companyProfiles, setCompanyProfiles] = useState([]);
  
  const recipient = selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userQuery;
        let companyQuery;
  
        if (selectedChat) {
          if (selectedChat.createdBy === currentUid) {
            // If the current user created the chat, query by SendTo for users and createdBy for companies
            userQuery = query(collection(db, 'infoUser'), where('SendTo', '==', recipient));
            companyQuery = query(collection(db, 'company'), where('createdBy', '==', recipient));
          } else if (selectedChat.SendTo === currentUid) {
            // If the current user is the recipient, query by createdBy for users and SendTo for companies
            userQuery = query(collection(db, 'infoUser'), where('uid', '==', recipient));
            companyQuery = query(collection(db, 'company'), where('SendTo', '==', recipient));
          }
  
          const userDataSnapshot = await getDocs(userQuery);
          const companyDataSnapshot = await getDocs(companyQuery);
  
          // Map the data from the query snapshots and store them in userProfiles and companyProfiles states
          const userProfilesData = userDataSnapshot.docs.map((doc) => doc.data());
          setUserProfiles(userProfilesData);
  
          const companyProfilesData = companyDataSnapshot.docs.map((doc) => doc.data());
          setCompanyProfiles(companyProfilesData);
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };
  
    fetchData();
  
    return () => {
      setUserProfiles([]);
      setCompanyProfiles([]);
    };
  }, [selectedChat, currentUid, recipient]);
  

  return (
    <div>
      {userProfiles.map((profile, index) => (
        <div key={`user-${index}`}>
          <img src={profile.imgPerfil} alt="Profile Image" className="profile-image1" />
          <p>{profile.firstName}</p>
        </div>
      ))}
    
      {companyProfiles.map((profile, index) => (
        <div key={`company-${index}`}>
          <img src={profile.imgPerfil} alt="Profile Image" className="profile-image1" />
          <p>{profile.companyName}</p>
        </div>
      ))}
    </div>
  );
}

export default ImgProfile;
*/

/*
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import './imgProfile.css';

function ImgProfile({ selectedChat, currentUid }) {
  const [userProfiles, setUserProfiles] = useState([]);

  const recipient = selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;

   

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userQuery;
        let companyQuery;

        if (selectedChat) {
          if (selectedChat.createdBy === currentUid) {
            // If the current user created the chat, query by SendTo for users and createdBy for companies
            userQuery = query(collection(db, 'infoUser'), where('SendTo', '==', recipient));
            companyQuery = query(collection(db, 'company'), where('createdBy', '==', recipient));
          } else if (selectedChat.SendTo === currentUid) {
            // If the current user is the recipient, query by createdBy for users and SendTo for companies
            userQuery = query(collection(db, 'infoUser'), where('uid', '==', recipient));
            companyQuery = query(collection(db, 'company'), where('SendTo', '==', recipient));
          }

          const [userDataSnapshot, companyDataSnapshot] = await Promise.all([
            getDocs(userQuery),
            getDocs(companyQuery)
          ]);

          // Map the data from the query snapshots and store them in userProfiles state
          const userProfilesData = userDataSnapshot.docs.map((doc) => doc.data());
          const companyProfilesData = companyDataSnapshot.docs.map((doc) => doc.data());

          // Merge user and company profiles into a single array
          const allProfiles = [...userProfilesData, ...companyProfilesData];

          // Set the merged profiles to the state
          setUserProfiles(allProfiles);
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchData();

    return () => {
      setUserProfiles([]);
    };
  }, [selectedChat, currentUid, recipient]);

  return (
    <div>
      {userProfiles.map((profile, index) => (
        <div key={`user-${index}`}>
          <img src={profile.imgPerfil} alt="Profile Image" className="profile-image1" />
          <p>{profile.firstName}</p> 
          <p>{profile.nameCompany}</p>
          {recipient}
        </div>
      ))}
    </div>
  );
}

export default ImgProfile;

*/

/*
import React from 'react';

function ImgProfile({ selectedChat, currentUid }) {
  // Destructure properties from selectedChat object
 

  const recipient = selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;

  return (
    <div>
        <p>CreatedBy: {recipient}</p>
    </div>
  );
}

export default ImgProfile;

*/
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import './imgProfile.css';
import { FaUser } from 'react-icons/fa';

function ImgProfile({ selectedChat, currentUid }) {
  const [userData, setUserData] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  const recipient = selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userQuery = query(collection(db, 'infoUser'), where('uid', '==', recipient));
        const userQuerySnapshot = await getDocs(userQuery);
        const userData = userQuerySnapshot.docs.map(doc => doc.data());
        setUserData(userData[0]); // Assuming there's only one user document for a given UID
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchCompanyData = async () => {
      try {
        const companyQuery = query(collection(db, 'company'), where('uid', '==', recipient));
        const companyQuerySnapshot = await getDocs(companyQuery);
        const companyData = companyQuerySnapshot.docs.map(doc => doc.data());
        setCompanyData(companyData[0]); // Assuming there's only one company document for a given UID
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchUserData();
    fetchCompanyData();
  }, [recipient]);

  return (
    <div>
      {userData && (
        <div className="profile-container5">
          <div className="profile-item4">
          {userData.imgPerfil ? (
            <img 
              className="profile-image1 " 
              src={userData.imgPerfil} 
              alt="Profile Image" 
              onError={(e) => {
                e.target.onerror = null;
                e.target.src=`${FaUser}`; // Replace with your default image path
              }}
            />
          ) : (
            <FaUser size={32} className="search-icon" />
          )}

          </div>
          <div className="profile-item4">
              <p  style={{ color: '#f2f2f2', fontWeight: 'bold', marginTop:5 }} >{userData.firstName}</p>
              <p  style={{ color: '#f2f2f2', fontWeight: 'bold' }}>{userData.lastName}</p>
          </div>
          <div className="profile-item41">
              <p  style={{ color: '#9B9A99'}} >{userData.phone}</p>
          </div>
        </div>
      )}

      {companyData && (
      <div className="profile-container5">
          <div className="profile-item4">
          {companyData.imgPerfil ? (
              <img 
                className="profile-image1" 
                src={companyData.imgPerfil} 
                alt="Profile Image" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src=`${FaUser}`; // Replace with your default image path
                }}
              />
            ) : (
              <FaUser size={32} className="search-icon" />
            )}

          </div>
          <div className="profile-item4">
            <div style={{ color: '#f2f2f2', fontWeight: 'bold', marginTop:5  }}>{companyData.nameCompany}</div> 
          </div>
          <div className="profile-item41">
            <p style={{ color: '#9B9A99' }} >{companyData.phone}</p> 
          </div>
      </div>
    
      )}
    </div>
  );
}

export default ImgProfile;
