import React, { useEffect, useState } from 'react';
import { auth } from '../../../firebase/firebase';
import SuscriptionOffer from '../suscriptionOffer/suscriptionOffer'
import { onAuthStateChanged } from 'firebase/auth';
import { getDocs, collection, query, where, getFirestore } from 'firebase/firestore';
import CurrenSuscriptioScreem from '../currenSuscriptioScreem/currenSuscriptioScreem'

function BossManager() {
  const [showOffer, setShowOffer] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const db = getFirestore();
        const paymentsRef = collection(db, 'subscriptions');
        const q = query(paymentsRef, where('customerEmail', '==', user.email));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setShowOffer(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      {showOffer && <SuscriptionOffer />}

      {!showOffer &&   <CurrenSuscriptioScreem/> }
    </div>
  );
}

export default BossManager;