import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import ProfileImage from '../profileImage/profileImage';

// Function to calculate Levenshtein distance
function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function QueryName({ searchTerm, uids, employeesPostData, setNoResultsCount }) {
    const [user, loading, error] = useAuthState(auth);
    const [searchResults, setSearchResults] = useState([]);
    const [currentUid, setCurrentUid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Set currentUid state when user object changes
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'infoUser'));
            const querySnapshot = await getDocs(q);
            const results = [];
        
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.lastName) { // Check if inputText is defined
                    const lastName = data.lastName.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), lastName) / Math.max(searchTerm.length, lastName.length)) * 100;
                    if (similarity >= 60) {
                        results.push(data);
                    }
                }
            });
            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };  
        fetchData();
    }, [searchTerm]);

    return (
        <>
            {searchResults.length > 0 ? (
                <div className="container-query-maind">
                    {searchResults.map((result, index) => (
                        <div className="item-query" key={index}>
                            {/* Image Background */}
                            {result.imgBack && <img src={result.imgBack} alt="Post" className="post-back-query" />}
    
                        
                            {(result.firstName || result.lastName) && (
                                <div className="post-00ADD">
                                    {result.firstName} {result.lastName}
                                </div>
                            )}
    
                            {/* User Information - Username and Location */}
                            <div className="cityMONUNcon">
                                {/* Username */}
                                {result.username && (
                                    <div className="aaa55m">
                                        <span className="Weeklyt">User</span>
                                        <div className="post-description1">{result.username}</div>
                                    </div>
                                )}
    
                                {/* Location/City */}
                                {result.city && (
                                    <div className="aaa55m">
                                        <span className="Weeklyt">Location</span>
                                        <span className="post-likes">{result.city}</span>
                                    </div>
                                )}
                            </div>
    
                            {/* Description Skills */}
                            {result.descriptionSkills && (
                                <div className="aaaNI">
                                    <div className="PSdescription">{result.descriptionSkills}</div>
                                </div>
                            )}
    
                            {/* Profile Navigation and Messaging */}
                            <div className="cityMONUNcon3">
                                {/* Navigate to Profile */}
                                {result.uid && (
                                    <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(result.uid)}>
                                        <ProfileImage queryPost={result.uid} currentUid={currentUid} />
                                    </button>
                                )}
    
                                {/* Send Message Button */}
                                {result.uid && (
                                    <div className="CCS4">
                                        <SendMessage uidThePost={result.uid} currentUid={currentUid} />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                null // Show nothing if no search results
            )}
        </>
    );
    
}

export default QueryName;
