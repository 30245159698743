/*


import React, { useState, useEffect } from 'react';
import { db } from '../../../../src/firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FaPhone, FaEnvelope, FaUser, FaLanguage, FaTransgender, FaWrench, FaBriefcase } from 'react-icons/fa';
import './UserCv.css';

function UserCv({ uid }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const q = query(collection(db, 'userCv'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setUserData(doc.data());
        } else {
          console.log('No such document in userCv!');
        }
      } catch (error) {
        console.error('Error getting userCv document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return null;
  }

  const truncateDescription = (description) => {
    if (!description) {
      return 'No information provided';
    }
    const words = description.split(' ');
    return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : description;
  };
  

  return (
    <div className="user-cv-container">
      <div className="user-cv-header">
        <FaUser className="header-icon" />
        <div className="user-cv-title">{userData.fullName || 'User Information'}</div>
      </div>
      <div className="user-cv-content">
        <div className="user-cv-panel section-1">
          <p><strong>Age:</strong></p>
          <p>{userData.age}</p>
        </div>
        <div className="user-cv-panel section-1">
          <FaTransgender/>
          <p><strong>Gender:</strong></p>
          <p>{userData.gender}</p>
        </div>
        <div className="user-cv-panel section-1">
          <FaLanguage />
          <p><strong>Nationality:</strong></p>
          <p>{userData.nationality}</p>
        </div>
        <div className="user-cv-panel section-1">
          <FaLanguage/>
          <p><strong>English Level:</strong></p>
          <p>{userData.englishLevel}</p>
        </div>
        <div className="user-cv-panel section-2">
          <FaPhone className="detail-icon" />
          <p><strong className="minTwhite">Phone:</strong></p>
          <p className="DTwhite">{userData.phone}</p>
        </div>
        <div className="user-cv-panel section-2">
          <FaEnvelope className="detail-icon" />
          <p><strong className="minTwhite">Email:</strong></p>
          <p className="DTwhite">{userData.email}</p>
        </div>
        <div className="user-cv-panel section-2">
          <p><strong className="minTwhite">Postal Code:</strong></p>
          <p className="DTwhite">{userData.postalCode}</p>
        </div>
        <div className="user-cv-panel section-3">
          <FaWrench className="detail-icon" />
          <p><strong className="minTwhite">Skills:</strong></p>
          <p className="DTwhite">{truncateDescription(userData.skills)}</p>
        </div>
        <div className="user-cv-panel section-3">
          <FaBriefcase className="detail-icon" />
          <p><strong className="minTwhite">Work Experience:</strong></p>
          <p className="DTwhite">{truncateDescription(userData.workExperience)}</p>
        </div>
      </div>
    </div>
  );
}

export default UserCv;




*/
import React, { useState, useEffect } from 'react';
import { db } from '../../../../src/firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FaPhone, FaEnvelope, FaUser, FaLanguage, FaTransgender, FaWrench, FaBriefcase } from 'react-icons/fa';
import './UserCv.css';

function UserCv({ uid }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const q = query(collection(db, 'userCv'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setUserData(doc.data());
        } else {
          console.log('No such document in userCv!');
        }
      } catch (error) {
        console.error('Error getting userCv document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid]);

  // Helper function to check if userData has any meaningful data
  const hasData = (data) => {
    if (!data) return false;
    const { age, gender, nationality, englishLevel, phone, email, postalCode, skills, workExperience } = data;
    return age || gender || nationality || englishLevel || phone || email || postalCode || skills || workExperience;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if there is no data
  if (!userData || !hasData(userData)) {
    return null; // Don't render anything if no data
  }

  const truncateDescription = (description) => {
    if (!description) {
      return 'No information provided';
    }
    const words = description.split(' ');
    return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : description;
  };

  return (
    <div className="user-cv-container">
     
      <div className="user-cv-content">
        {userData.age && (
          <div className="user-cv-panel section-1">
            <p><strong>Age:</strong></p>
            <p>{userData.age}</p>
          </div>
        )}
        {userData.gender && (
          <div className="user-cv-panel section-1">
            <FaTransgender />
            <p><strong>Gender:</strong></p>
            <p>{userData.gender}</p>
          </div>
        )}
        {userData.nationality && (
          <div className="user-cv-panel section-1">
            <FaLanguage />
            <p><strong>Nationality:</strong></p>
            <p>{userData.nationality}</p>
          </div>
        )}
        {userData.englishLevel && (
          <div className="user-cv-panel section-1">
            <FaLanguage />
            <p><strong>English Level:</strong></p>
            <p>{userData.englishLevel}</p>
          </div>
        )}
        {userData.phone && (
          <div className="user-cv-panel section-2">
            <FaPhone className="detail-icon" />
            <p><strong className="minTwhite">Phone:</strong></p>
            <p className="DTwhite">{userData.phone}</p>
          </div>
        )}
        {userData.email && (
          <div className="user-cv-panel section-2">
            <FaEnvelope className="detail-icon" />
            <p><strong className="minTwhite">Email:</strong></p>
            <p className="DTwhite">{userData.email}</p>
          </div>
        )}
        {userData.postalCode && (
          <div className="user-cv-panel section-2">
            <p><strong className="minTwhite">Postal Code:</strong></p>
            <p className="DTwhite">{userData.postalCode}</p>
          </div>
        )}
        {userData.skills && (
          <div className="user-cv-panel section-3">
            <FaWrench className="detail-icon" />
            <p><strong className="minTwhite">Skills:</strong></p>
            <p className="DTwhite">{truncateDescription(userData.skills)}</p>
          </div>
        )}
        {userData.workExperience && (
          <div className="user-cv-panel section-3">
            <FaBriefcase className="detail-icon" />
            <p><strong className="minTwhite">Work Experience:</strong></p>
            <p className="DTwhite">{truncateDescription(userData.workExperience)}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserCv;

