/*import React from 'react'
import { db } from '../../../firebase/firebase'; 

function CountFollow() {
  return (
    <div>countFollow</div>
  )
}

export default CountFollow
*/
import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './countFollow.css'

function CountFollow({ userUid }) {
  const [followerCount, setFollowerCount] = useState(0);
  const [followeeCount, setFolloweeCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Query to get the number of people following the user (followers)
        const followersQuery = query(
          collection(db, 'follows'),
          where('followeeUid', '==', userUid)
        );
        const followersSnapshot = await getDocs(followersQuery);
        setFollowerCount(followersSnapshot.size); // Count the number of followers

        // Query to get the number of people the user is following (followees)
        const followeesQuery = query(
          collection(db, 'follows'),
          where('followerUid', '==', userUid)
        );
        const followeesSnapshot = await getDocs(followeesQuery);
        setFolloweeCount(followeesSnapshot.size); // Count the number of followees
      } catch (error) {
        console.error("Error fetching follow data:", error);
      }
    };

    fetchCounts();
  }, [userUid]);

  return (
    <div className="follow-container aesthetic">
    <div className="follow-block">
      <span className="count">{followerCount}</span>
      <p className="follow-text">Followers</p>
    </div>
    <div className="follow-block">
      <span className="count">{followeeCount}</span>
      <p className="follow-text">Following</p>
    </div>
  </div>
  );
}

export default CountFollow;
