import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import './queryFeedUser.css';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import ProfileImage from '../profileImage/profileImage';

function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function QueryFeed({ searchTerm, uids, employeesPostData, setNoResultsCount }) {
    const [user] = useAuthState(auth);
    const [searchResults, setSearchResults] = useState([]);
    const [currentUid, setCurrentUid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'infoUser'));
            const querySnapshot = await getDocs(q);
            const results = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.username) {
                    const username = data.username.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), username) / Math.max(searchTerm.length, username.length)) * 100;
                    if (similarity >= 60) {
                        results.push(data);
                    }
                }
            });

            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };

        fetchData();
    }, [searchTerm, setNoResultsCount]);

    return (
        <>
        {searchResults.length > 0 ? (
            <div className="container-query-maind">
                {searchResults.map((result, index) => (
                    <div className="item-query" key={index}>

                        <div className="actions">
                            <button onClick={() => handleNavigateToProfile(result.uid)}>
                                {result.uid && (
                                    <ProfileImage queryPost={result.uid} currentUid={currentUid} />
                                )}
                            </button>
                            {result.uid && (
                                <SendMessage uidThePost={result.uid} currentUid={currentUid} />
                            )}
                        </div>

                        <div className="info32">
                            {result.username && (
                                <div className="item11">
                                    <div className="colored-text2">{result.username}</div>
                                </div>
                            )}
                            {result.city && (
                                <div className="item11">
                                    <span className="colored-text">Location</span>
                                    <div className="colored-text2">{result.city}</div>
                                </div>
                            )}
                        </div>

                        {result.descriptionSkills && (
                            <div className="description">{result.descriptionSkills}</div>
                        )}

                    </div>
                ))}
            </div>
        ) : (
           null
        )}
    </>
    );
}

export default QueryFeed;

