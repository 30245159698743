/*

import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import './Historial.css';
import { FaUser } from 'react-icons/fa'; 

function Historial({ onTermClick }) {
    const [searchHistory, setSearchHistory] = useState([]);

    useEffect(() => {
        // Query to order by 'count' in descending order (most searched terms first)
        const q = query(collection(db, 'history'), orderBy('count', 'desc'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const history = [];
            querySnapshot.forEach((doc) => {
                history.push({ term: doc.data().term, count: doc.data().count });
            });
            setSearchHistory(history);
        });

        return () => unsubscribe();  // Clean up the listener on component unmount
    }, []);

    return (
        <div className="historial-container">
            <div className="more-searched">
                 More searched 
            </div>

            <ul className="historial-list">
                {searchHistory.length > 0 ? (
                    searchHistory.slice(0, 10).map((entry, index) => (  // Limit to top 10 most searched terms
                        <li 
                            key={index} 
                            className="historial-item" 
                            onClick={() => onTermClick(entry.term)} // Call onTermClick with the term when clicked
                        >
                            #{entry.term} 
                            <span className="term-count">{entry.count} <FaUser className="userSear" /></span>
                        </li>
                    ))
                ) : (
                    <li className="no-history">No search history available.</li>
                )}
            </ul>
        </div>
    );
}

export default Historial;


*/

import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import './Historial.css';
import { FaUser } from 'react-icons/fa'; 

function Historial({ onTermClick }) {
    const [searchHistory, setSearchHistory] = useState([]);

    useEffect(() => {
        const q = query(collection(db, 'history'), orderBy('count', 'desc'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const history = [];
            querySnapshot.forEach((doc) => {
                history.push({ term: doc.data().term, count: doc.data().count });
            });
            setSearchHistory(history);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className="historial-container">
            <div className="more-searched">
                More searched
            </div>

            <ul className="historial-list">
                {searchHistory.length > 0 ? (
                    searchHistory.slice(0, 10).map((entry, index) => (
                        <li 
                            key={index} 
                            className="historial-item" 
                            onClick={() => onTermClick(entry.term)}  // Call onTermClick when an item is clicked
                        >
                            #{entry.term}
                            <span className="term-count">
                                {entry.count} <FaUser className="userSear" />
                            </span>
                        </li>
                    ))
                ) : (
                    <li className="no-history">No search history available.</li>
                )}
            </ul>
        </div>
    );
}

export default Historial;
