/*import React from 'react'

function Company() {
  return (
    <div>Company</div>
  )
}

export default Company
*/
/*
import React from 'react'

function User() {
  return (
    <div>User</div>
  )
}

export default User
*/
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"; // Import storage functionalities
import { storage, db } from '../../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { IoIosImages } from "react-icons/io";

import { useNavigate } from 'react-router-dom';

function EditAccountUser({ user, setUser, currentUid }) {
    const [nameCompany, setNameCompany] = useState(user.nameCompany);
  
    const [city, setCity] = useState(user.city);
    const [phone, setPhone] = useState(user.phone);
    const [descriptionCompany, setDescriptionCompany] = useState(user.descriptionCompany);
  
    const [imageProfile, setImageProfile] = useState(null); // For profile image
    const [imageBack, setImageBack] = useState(null);

    const [imagePreviewUrl, setImagePreviewUrl] = useState(user.imgPerfil || '');
    const [imagePreviewUrlBack, setImagePreviewUrlBack] = useState(user.imgBack || '');   // State to hold the preview URL
    const [userEmail, setUserEmail] = useState('');

    const imageInputRef = useRef(); // For profile image
    const imageBackInputRef = useRef(); // For background image

const navigate = useNavigate();
   
const handleImageProfile = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImageProfile(file);
        setImagePreviewUrl(URL.createObjectURL(file));
    } else {
        setImageProfile(null);
        setImagePreviewUrl('');
    }
};

const handleImageChangeBack = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImageBack(file);
        setImagePreviewUrlBack(URL.createObjectURL(file));
    } else {
        setImageBack(null);
        setImagePreviewUrlBack('');
    }
};

function goQuery() {
    navigate(`/Perfil/${currentUid}`);
}

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (!nameCompany  || !city || !phone || !descriptionCompany) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        // Upload the image to Firebase Storage and get the URL
        let imgUrl = user.imgPerfil; // Default to the old image URL if no new image is selected
        if (imageProfile) { // Use imageProfile here
            const imageRef = ref(storage, `profile_images/${currentUid}/${imageProfile.name}`);
            const snapshot = await uploadBytes(imageRef, imageProfile); // Use imageProfile here
            imgUrl = await getDownloadURL(snapshot.ref);
        }
        
        let imgBackUrl = user.imgBack; // Default to the old image URL if no new image is selected
        if (imageBack) { // Use imageBack here
            const imageBackRef = ref(storage, `background_images/${currentUid}/${imageBack.name}`);
            const snapshot = await uploadBytes(imageBackRef, imageBack); // Use imageBack here
            imgBackUrl = await getDownloadURL(snapshot.ref);
        }
        

        // Construct the query with the user's UID
        const q = query(collection(db, "company"), where("uid", "==", currentUid));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: `No user profile found for UID: ${currentUid}`,
                    showConfirmButton: true,
                });
                return;
            }

            // Assume the first document is the correct one
            const userDocRef = querySnapshot.docs[0].ref;
            await updateDoc(userDocRef, {
                nameCompany,
                city,
                phone,
                descriptionCompany,
                imgPerfil: imgUrl, // Update the imgPerfil field with new image URL
                imgBack: imgBackUrl,
            });

            setUser({ ...user, nameCompany,   city, phone, descriptionCompany, imgPerfil: imgUrl, imgBack: imgBackUrl });
            

            Swal.fire({
                icon: 'success',
                title: 'Updated!',
                text: `${nameCompany}'s data has been updated.`,
                showConfirmButton: false,
                timer: 1500,
            });

            navigate(`/Perfil/${currentUid}`);
        } catch (error) {
            console.error("Error updating document: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Update Failed!',
                text: 'There was a problem updating your profile.',
                showConfirmButton: true,
            });
        }
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserEmail(user.email);
            } else {
                // User is signed out
                setUserEmail('');
            }
        });
        return () => unsubscribe(); // Cleanup function
    }, []);

    return (
    <div className="profiles-postC7">
  
        <div className="posCon1Edit">
        <div className="form-actions">
            <button className="buttonSudmitCansel7" onClick={goQuery}>Cancel</button>


            <button className="buttonSudmitAdd" onClick={handleUpdate} >Add</button>
        </div>
             <input
                    id="profileImageBack"
                    type="file"
                    ref={imageBackInputRef} // Use the ref for the background image
                    onChange={handleImageChangeBack}
                    style={{ display: 'none' }}
                />
                <div lassName="cont-Image-ch" onClick={() => imageBackInputRef.current.click()}>
                    {imagePreviewUrlBack ? (
                     <div className="parent-container">
                        <img src={imagePreviewUrlBack} alt="Profile" className="profile-back33" />
                      </div>
                      
                    ) : (
                        <div className="parent-container">
                          <div className="default-div">
                            <IoIosImages className="search-icon" size={40}/>
                            <div className="t34">Choose one image</div>
                          </div>
                        </div>
                    )}
                </div>


                <input
                    id="profileImage"
                    type="file"
                    ref={imageInputRef} // Use the ref for the profile image
                    onChange={handleImageProfile}
                    style={{ display: 'none' }}
                />
                <div onClick={() => imageInputRef.current.click()}>
                    {imagePreviewUrl ? (
                         <div className="parent-container">
                             <img src={imagePreviewUrl} alt="Profile" className="profile-preview33" />
                        </div>
                    ) : (
                        <div className="parent-container">
                             <div className="default-div-profile">
                                <IoIosImages className="search-icon" size={40}/>
                                <div className="t34">Choose one</div>
                             </div>
                        </div>
                    )}
                </div>
                </div>

    <div className="posCon2Edit">


        <div className="form-row">
            <label htmlFor="firstName" className="input-label">Owner</label>
            <label htmlFor="email" className="input-label"> {userEmail}</label>
         </div>


      <label htmlFor="nameCompany" className="input-label">Company name</label>
        <input
            id="nameCompany"
            type="text"
            name="nameCompany"
            value={nameCompany}
            onChange={e => setNameCompany(e.target.value)}
            className="entrada"
            required
        />
     
   

    <div className="form-row">
        <label htmlFor="city" className="input-label">City</label>
        <input
                className="entrada"
                id="city"
                type="text"
                name="city"
                value={city}
                onChange={e => setCity(e.target.value)}
            />
          
          
    </div>
    <div className="form-row">
         <label htmlFor="phone" className="input-label">Phone</label>
        <input
        className="entrada"
            id="phone"
            type="text"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            />
         
    </div>
    <div className="form-row">
         <label htmlFor="descriptionCompany" className="input-label">Company description</label>
        <input
        className="entrada"
        id="descriptionCompany"
        type="text"
        name="descriptionCompany"
        value={descriptionCompany}
        onChange={e => setDescriptionCompany(e.target.value)}
            />     
        </div>

        </div>

        
  
        </div>
 
    );
}

export default EditAccountUser;
