/*
import React from 'react'
import SecurityTipeAccount from '../securityTipeAccount/securityTipeAccount'
import { db }  from '../../../../src/firebase/firebase'

import Company from './company/company'
import User from './user/user'
 

function verificationAccount() {
  return (
    <div>

        <Company></Company>
        
        <User></User>


        <SecurityTipeAccount/>
    </div>
  )
}

export default verificationAccount
*/
/*
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth} from '../../../../src/firebase/firebase'; // Import your Firebase configuration
import Company from './company/company';
import User from './user/user';
import SecurityTipeAccount from '../securityTipeAccount/securityTipeAccount';

function VerificationAccount() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const currentUid = auth.currentUser.uid; // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {formType === 'company' && <Company />}
      {formType === 'infoUser' && <User />}
      <SecurityTipeAccount/>
    </div>
  );
}

export default VerificationAccount;
*/

import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase'; // Import your Firebase configuration
import TipeAccount from '../../../../src/pages/TipeAccount';
 
import NotFound from '../../../../src/pages/NotFound'

function VerificationAccount() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const currentUid = auth.currentUser.uid; // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, []);

  return (
    <div>
      {formType === null && <TipeAccount/>}
      {formType === 'company' && <NotFound />}
      {formType === 'infoUser' && <NotFound />}
    </div>
  );
}

export default VerificationAccount;
