/*import React from 'react';

import ChatListFeed from '../ChatListFeed';

function SendMessage({ uidThePost, currentUid }) {
  return (
    <>
    <button className="bMessage">
      <div className="tMessage">Message</div>
      
    </button>

    <ChatListFeed uidThePost={uidThePost} currentUid2={currentUid}/> 
</>
  );
}
export default SendMessage;

*/

/*
import React from 'react';

import ChatListFeed from '../ChatListFeed';

function SendMessage({ uidThePost, currentUid }) {
  return (
    <>
    <button className="bMessage">
      <div className="tMessage">Message</div>
      <div className="tMessage">{uidThePost}</div>
      <div className="tMessage">{currentUid}</div>
    </button>
</>
  );
}
export default SendMessage;

*/
import React from 'react';
import { db, auth } from '../../../../../firebase/firebase';
import { collection, addDoc, getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

async function sendMessage({ uidThePost, currentUid, inputMessage, setInputMessage }) {
  try {
    // Create a chat ID by sorting and joining the user IDs
    const chatId = [currentUid, uidThePost].sort().join('_');
    const chatDocRef = doc(db, 'chats', chatId);
    const chatDoc = await getDoc(chatDocRef);
 

    if (!chatDoc.exists()) {
      // If a document doesn't exist, create a new one
      await setDoc(chatDocRef, {
        createdBy: currentUid,
        SendTo: uidThePost,
        createdAt: serverTimestamp(),
      });
    }

    // Add the message to the 'conversacion' subcollection
    await addDoc(collection(chatDocRef, 'conversacion'), {
      sentBy: currentUid,
      Text: inputMessage,
      timestamp: serverTimestamp(),
    });

    setInputMessage(''); // Clear input after sending
  } catch (error) {
    console.error('Error sending message:', error);
  }
}

function SendMessage({ uidThePost, currentUid, inputMessage, setInputMessage }) {
  const navigate = useNavigate(); // Change this line
  const {t} = useTranslation();

  const handleSendMessage = async () => {
    if (!auth.currentUser) {
      // If the user is not authenticated, navigate to the signup page
      navigate('/signup');
      return;
    }

    await sendMessage({ uidThePost, currentUid, inputMessage, setInputMessage });
    if (window.innerWidth <= 768) {
      navigate('/chat'); // Navigate to /chat on mobile devices
    }
  };

  return (
    <>
      <button className="bMessage" onClick={handleSendMessage}>
        <div className="tMessage">{t('Message')}</div>
      </button>
    </>
  );
}

export default SendMessage;






