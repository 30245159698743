// In src/pages/NotFound.js
import React from 'react';
import Logo from '../assets/LOGOBlack.png'
import  './NotFound.css'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="not-found-container">

        <Link to="/SearchJob">
             <img className="logoSuscr"  src={Logo}/> 

         </Link>    


        <h1 className="not-found-title">This page is not available</h1>
        <div className="not-found-sub">
                <p className="not-found-text">
                    The link you selected may be broken 
                </p>
                <p className="not-found-text">
                or the page may have been deleted.
                </p>
        </div>
    </div>
    );
};

export default NotFound;

