/*
import React, { useState } from 'react';
import './SolicitudJob.css';
import { db } from '../../firebase/firebase';
import { collection, addDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../src/firebase/firebase'; 

import { GrCheckmark } from 'react-icons/gr';

function SolicitudJob({ isOpen, onClose }) {

  const [formSubmitted, setFormSubmitted] = useState(false); 

  const [fullName, setFullName] = useState('');
  const [curp, setCurp] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [age, setAge] = useState('');
  const [nationality, setNationality] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [rfc, setRfc] = useState('');
  const [skills, setSkills] = useState('');
  const [englishLevel, setEnglishLevel] = useState('');

 
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : null;

  if (!isOpen) return null;

  const handleRoomSubmit = async (e) => {
    e.preventDefault();

 
    const roomsRef = collection(db, "solicitud de empleo");
 
    const newRoomData = {
      fullName,
      curp,
      gender,
      email,
      phone,
      workExperience,
      age,
      nationality,
      postalCode,
      socialSecurityNumber,
      rfc,
      skills,
      englishLevel,
      createdBy: userId,
      createdAt: new Date()
    };

    try {
    
      const docRef = await addDoc(roomsRef, newRoomData);
      console.log("Room created with ID: ", docRef.id);
      setFormSubmitted(true);
    
    } catch (error) {
      console.error("Error creating room: ", error);
    }
  };

 

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div className="modal-content">
        
        {!formSubmitted ? (
          <form className="solicitudJob" onSubmit={handleRoomSubmit}>
            <div>Job application</div>
            <div className="SubTexAplication">If you do not have any information, you can fill it out and update it at another time.</div>
            <div className="SubTexAplication" >You will find a job before the rooster crows</div>
            
          
            <input className='inputTextForm' type="text" onChange={(e) => setCurp(e.target.value)} placeholder="CURP"/>
            <input className='inputTextForm' type="text" onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" />
        
            <input className='inputTextForm' type="text" onChange={(e) => setWorkExperience(e.target.value)} placeholder="Work Experience" />
            <input className='inputTextForm' type="text" onChange={(e) => setGender(e.target.value)} placeholder="Gender"/>
            
            <input className='inputTextForm' type="text" onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
            <input className='inputTextForm' type="text" onChange={(e) => setAge(e.target.value)} placeholder="Age" />
           
            
            <input className='inputTextForm' type="text" onChange={(e) => setPostalCode(e.target.value)} placeholder="Postal Code" />
            <input className='inputTextForm' type="text" onChange={(e) => setEnglishLevel(e.target.value)} placeholder="English Level" />
           
           
            <input className='inputTextForm' type="text" onChange={(e) => setSocialSecurityNumber(e.target.value)} placeholder="Social Security Number" />
            <input className="emailSolicitudJob" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email to contact you" />

            <input className='inputTextForm' type="text" onChange={(e) => setPhone(e.target.value)} placeholder="Phone to contact you" />

            <input className='inputTextForm' type="text" onChange={(e) => setRfc(e.target.value)} placeholder="RFC" />
            <button className='btAplication'  type="submit">Submit Application</button>

            <input className='inputTextForm' type="text" onChange={(e) => setSkills(e.target.value)} placeholder="Describe your skills" />
            
            
          </form>

            ) : (
              // The second section to show after form is submitted
              
              <div className="submission-success">



                <div className="cheek">  ✔  </div>
                <h2>Thank you for your application!</h2>

               
                <p>Your information has been received and we will be in touch shortly.</p>
               
               
              
              </div>
            )}
           
        </div>
      </div>
    </div>
  );
}

export default SolicitudJob;*/

//<button onClick={onClose} className='btAplication'>Close</button>


import React, { useState } from 'react';
import { db } from '../../firebase/firebase';
import { collection, addDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../src/firebase/firebase'; 

import { GrCheckmark } from 'react-icons/gr';

function SolicitudJob({ isOpen, onClose, postingUserId }) {

  const [formSubmitted, setFormSubmitted] = useState(false); 

  const [fullName, setFullName] = useState('');
  const [curp, setCurp] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [age, setAge] = useState('');
  const [nationality, setNationality] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [rfc, setRfc] = useState('');
  const [skills, setSkills] = useState('');
  const [englishLevel, setEnglishLevel] = useState('');

 
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : null;

  if (!isOpen) return null;

  const handleRoomSubmit = async (e) => {
    e.preventDefault();

 
    const roomsRef = collection(db, "solicitud de empleo");
 
    const newRoomData = {
      fullName,
      curp,
      gender,
      email,
      phone,
      workExperience,
      age,
      nationality,
      postalCode,
      socialSecurityNumber,
      rfc,
      skills,
      englishLevel,
      createdBy: userId,
      postingUser: postingUserId, // Add this line
      createdAt: new Date()
    };

    try {
    
      const docRef = await addDoc(roomsRef, newRoomData);
      console.log("Room created with ID: ", docRef.id);
      setFormSubmitted(true);
    
    } catch (error) {
      console.error("Error creating room: ", error);
    }
  };

 

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div className="modal-content">
        
        {!formSubmitted ? (
          <form className="solicitudJob" onSubmit={handleRoomSubmit}>
            <div>Job application</div>
            <div className="SubTexAplication">If you do not have any information, you can fill it out and update it at another time.</div>
            <div className="SubTexAplication" >You will find a job before the rooster crows</div>
            
          
            <input className='inputTextForm' type="text" onChange={(e) => setCurp(e.target.value)} placeholder="CURP"/>
            <input className='inputTextForm' type="text" onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" />
        
            <input className='inputTextForm' type="text" onChange={(e) => setWorkExperience(e.target.value)} placeholder="Work Experience" />
            <input className='inputTextForm' type="text" onChange={(e) => setGender(e.target.value)} placeholder="Gender"/>
            
            <input className='inputTextForm' type="text" onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
            <input className='inputTextForm' type="text" onChange={(e) => setAge(e.target.value)} placeholder="Age" />
           
            
            <input className='inputTextForm' type="text" onChange={(e) => setPostalCode(e.target.value)} placeholder="Postal Code" />
            <input className='inputTextForm' type="text" onChange={(e) => setEnglishLevel(e.target.value)} placeholder="English Level" />
           
           
            <input className='inputTextForm' type="text" onChange={(e) => setSocialSecurityNumber(e.target.value)} placeholder="Social Security Number" />
            <input className="emailSolicitudJob" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email to contact you" />

            <input className='inputTextForm' type="text" onChange={(e) => setPhone(e.target.value)} placeholder="Phone to contact you" />

            <input className='inputTextForm' type="text" onChange={(e) => setRfc(e.target.value)} placeholder="RFC" />
            <button className='btAplication'  type="submit">Submit Application</button>

            <input className='inputTextForm' type="text" onChange={(e) => setSkills(e.target.value)} placeholder="Describe your skills" />
            
            
          </form>

            ) : (
              // The second section to show after form is submitted
              
              <div className="submission-success">



                <div className="cheek">  ✔  </div>
                <h2>Thank you for your application!</h2>

               
                <p>Your information has been received and we will be in touch shortly.</p>
               
               
              
              </div>
            )}
           
        </div>
      </div>
    </div>
  );
}

export default SolicitudJob;