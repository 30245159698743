/*


import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../../../firebase/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import IconName from '../../IconName/IconName';
import ChatOneToOneFeed from '../chatOneToOneFeed/chatOneToOneFeed';
import { useNavigate } from 'react-router-dom';
import ChatInputFeed from '../chatInputFeed/ChatInputFeed'
import './ChatListFeed.css'
import { FaArrowLeft } from "react-icons/fa";
import ImgProfile from '../imgProfile/imgProfile';
import BottonesFiles from '../../bottonesFiles/BottonesFiles';


function ChatListFeed({ currentUid}) {

  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showIconName, setShowIconName] = useState(true);

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    setShowIconName(false); // Hide IconName when a chat is selected
  };

  const handleShowIconName = () => {
    setSelectedChat(null); // Reset selectedChat when showing IconName
    setShowIconName(true);
  };

  const handleNavigateToProfile = useCallback((uid) => {
    navigate(`/Perfil/${uid}`);
  }, [navigate]);


  useEffect(() => {
    if (!currentUid) {
      console.error("No user logged in");
      return;
    }

    const processChats = (snapshot) => {
      const uniquePartnerUIDs = new Set();  
      const newChats = [];
      let selfChatIncluded = false;  

      snapshot.forEach(doc => {
        const chat = doc.data();
        const partnerUID = chat.createdBy === currentUid ? chat.SendTo : chat.createdBy;

       
        if (chat.createdBy === currentUid && chat.SendTo === currentUid) {
          if (!selfChatIncluded) {
            newChats.push({ ...chat, id: doc.id, partnerUID: currentUid });
            selfChatIncluded = true; // Ensure only one self-chat is added
          }
          return;
        }

       
        if (!uniquePartnerUIDs.has(partnerUID)) {
          uniquePartnerUIDs.add(partnerUID); // Add to the set to ensure uniqueness
          newChats.push({ ...chat, id: doc.id, partnerUID });
        }
      });
      return newChats;
    };

    const fetchChats = () => {
      const chatQueries = [
        query(collection(db, 'chats'), where('createdBy', '==', currentUid)),
        query(collection(db, 'chats'), where('SendTo', '==', currentUid)),
      ];
    
      const unsubscribeHandles = chatQueries.map(chatQuery =>
        onSnapshot(chatQuery, (snapshot) => {
          const updatedChats = processChats(snapshot);
          setChats(prevChats => {
            // Ensure no duplicates and merge with existing chats
            const mergedChats = new Map([...prevChats.map(chat => [chat.partnerUID, chat]), ...updatedChats.map(chat => [chat.partnerUID, chat])]);
            const mergedChatsArray = Array.from(mergedChats.values());
            
            // Check if there are new chats
            const newChats = mergedChatsArray.filter(chat => !prevChats.find(prevChat => prevChat.id === chat.id));
            if (newChats.length > 0) {
              // Activate handleChatSelect with the first new chat
              handleChatSelect(newChats[0]);
            }
    
            return mergedChatsArray;
          });
        })
      );
      // Return a cleanup function to unsubscribe from updates
      return () => unsubscribeHandles.forEach(unsubscribe => unsubscribe());
    };
    
  
    // Call fetchChats and return the unsubscribe cleanup function
    const unsubscribe = fetchChats();
  
    return unsubscribe; // Cleanup on component unmount
  }, [currentUid]);

  return (
    <div>
      {selectedChat ? (
     <div style={{     }}>
     <div style={{position: 'absolute',   backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
       <div className="cabeseraChat">
         <div className="chatIconContainer">
           <FaArrowLeft onClick={handleShowIconName} style={{ fill: '#fff' }} />
         </div>
         <div className="imgProfileContainer">

         <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(selectedChat.partnerUID)}>
            <ImgProfile selectedChat={selectedChat} currentUid={currentUid} />
          </button>
          
         </div>
       </div>
     </div>
   
     <div style={{ overflowY: 'auto', marginBottom:'20px'  }}>
       <ChatOneToOneFeed selectedChat={selectedChat} currentUid={currentUid} />
     </div>
   
     <div style={{ position: 'absolute', bottom: '15px', width: '93%' }}>
       <BottonesFiles selectedChat={selectedChat} currentUid={currentUid} />
       <ChatInputFeed selectedChat={selectedChat} currentUid={currentUid} />
     </div>
   </div>
   
      ) : (
        <div>
          {showIconName && ( // Conditionally render IconName only if showIconName is true
            <div >
              {chats.length > 0 ? (
                chats.map((chat) => (
                  <div key={chat.id} onClick={() => handleChatSelect(chat)}>
                    <IconName profilesUids={[chat.partnerUID]} currentUid={currentUid}/>
                  </div>
                ))
              ) : (
                null
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ChatListFeed;


*/import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../../../firebase/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import IconName from '../../IconName/IconName';
import ChatOneToOneFeed from '../chatOneToOneFeed/chatOneToOneFeed';
import { useNavigate } from 'react-router-dom';
import ChatInputFeed from '../chatInputFeed/ChatInputFeed';
import './ChatListFeed.css';
import { FaArrowLeft } from "react-icons/fa"; // Import the envelope icon
import ImgProfile from '../imgProfile/imgProfile';
import BottonesFiles from '../../bottonesFiles/BottonesFiles';

import MessageImage from '../../../../assets/userImage.png'; 

function ChatListFeed({ currentUid }) {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showIconName, setShowIconName] = useState(true);

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    setShowIconName(false); // Hide IconName when a chat is selected
  };

  const handleShowIconName = () => {
    setSelectedChat(null); // Reset selectedChat when showing IconName
    setShowIconName(true);
  };

  const handleNavigateToProfile = useCallback((uid) => {
    navigate(`/Perfil/${uid}`);
  }, [navigate]);

  useEffect(() => {
    if (!currentUid) {
      console.error("No user logged in");
      return;
    }

    const processChats = (snapshot) => {
      const uniquePartnerUIDs = new Set();  
      const newChats = [];
      let selfChatIncluded = false;  

      snapshot.forEach(doc => {
        const chat = doc.data();
        const partnerUID = chat.createdBy === currentUid ? chat.SendTo : chat.createdBy;

        if (chat.createdBy === currentUid && chat.SendTo === currentUid) {
          if (!selfChatIncluded) {
            newChats.push({ ...chat, id: doc.id, partnerUID: currentUid });
            selfChatIncluded = true; // Ensure only one self-chat is added
          }
          return;
        }

        if (!uniquePartnerUIDs.has(partnerUID)) {
          uniquePartnerUIDs.add(partnerUID); // Add to the set to ensure uniqueness
          newChats.push({ ...chat, id: doc.id, partnerUID });
        }
      });
      return newChats;
    };

    const fetchChats = () => {
      const chatQueries = [
        query(collection(db, 'chats'), where('createdBy', '==', currentUid)),
        query(collection(db, 'chats'), where('SendTo', '==', currentUid)),
      ];

      const unsubscribeHandles = chatQueries.map(chatQuery =>
        onSnapshot(chatQuery, (snapshot) => {
          const updatedChats = processChats(snapshot);
          setChats(prevChats => {
            const mergedChats = new Map([...prevChats.map(chat => [chat.partnerUID, chat]), ...updatedChats.map(chat => [chat.partnerUID, chat])]);
            const mergedChatsArray = Array.from(mergedChats.values());
            
            const newChats = mergedChatsArray.filter(chat => !prevChats.find(prevChat => prevChat.id === chat.id));
            if (newChats.length > 0) {
              handleChatSelect(newChats[0]);
            }

            return mergedChatsArray;
          });
        })
      );

      return () => unsubscribeHandles.forEach(unsubscribe => unsubscribe());
    };

    const unsubscribe = fetchChats();

    return unsubscribe; // Cleanup on component unmount
  }, [currentUid]);

  return (
    <div>
      {selectedChat ? (
        <div>
          <div style={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
            <div className="cabeseraChat">
              <div className="chatIconContainer">
                <FaArrowLeft onClick={handleShowIconName} style={{ fill: '#fff' }} />
              </div>
              <div className="imgProfileContainer">
                <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(selectedChat.partnerUID)}>
                  <ImgProfile selectedChat={selectedChat} currentUid={currentUid} />
                </button>
              </div>
            </div>
          </div>
          <div style={{ overflowY: 'auto', marginBottom: '20px' }}>
            <ChatOneToOneFeed selectedChat={selectedChat} currentUid={currentUid} />
          </div>
          <div style={{ position: 'absolute', bottom: '15px', width: '93%' }}>
            <BottonesFiles selectedChat={selectedChat} currentUid={currentUid} />
            <ChatInputFeed selectedChat={selectedChat} currentUid={currentUid} />
          </div>
        </div>
      ) : (
        <div>
          {showIconName && (
            <div>
              {chats.length > 0 ? (
                chats.map((chat) => (
                  <div key={chat.id} onClick={() => handleChatSelect(chat)}>
                    <IconName profilesUids={[chat.partnerUID]} currentUid={currentUid} />
                  </div>
                ))
              ) : (
                <div className="noChatsContainer">
                  <img src={MessageImage} alt="Success" className="successImage" /> 
                  
                  <div className="noChatsMessage">No chats yet</div> 
                  <div className="noChatsMessage">Send a message to someone!</div> 
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ChatListFeed;
