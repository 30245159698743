/*

import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../../firebase/firebase.js'
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
//import Location from './locatin/Location.js';
import './user.css'
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Company = () => {
 
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();
  
    // Function to adjust input width
    const adjustHeight = (element) => {
      element.style.height = "auto";  // Reset height to recalculate
      element.style.height = element.scrollHeight + "px"; // Set new height
    };
     
    const [selectedImage, setSelectedImage] = useState('');
    
    const [inputText, setInputText] = useState('');

    const [isSubmitted, setIsSubmitted] = React.useState(false);


    const handleAdd = async (e) => { 
      e.preventDefault();

      if (isSubmitted) {
        return;
    }
  
      if (!inputText ) {
        return Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'All fields are required.',
          showConfirmButton: true,
        });
      }
  
      const user = auth.currentUser; // Get the currently authenticated user
      if (!user) {
        // Handle the case where the user is not authenticated
        return;
      }
      console.log(user)
       
      const newEmployee = {
        uid: auth.currentUser.uid,
        inputText,
        selectedImage,
        createdAt: new Date()
      };
  
      try{
        await addDoc(collection(db, "employeesPost" ), {
          ...newEmployee
         });

         setIsSubmitted(true);

         navigate("/SearchJob");
  
      } catch (error) {
        console.log(error)
      }
  
      Swal.fire({
        icon: 'success',
        title: 'Added!',
        text: `${inputText}'s data has been Added.`,
        showConfirmButton: false,
        timer: 1500,
      });
    };
  
    const handleUpload = async (e) => {
      const file = e.target.files[0];
      if (!file) return;
    
      const fileRef = ref(storage, `postJobs/${v4()}`);
      try {
        const uploadResult = await uploadBytes(fileRef, file);
        const downloadUrl = await getDownloadURL(uploadResult.ref);
        setSelectedImage(downloadUrl);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    
  
  
  const [image, setImage] = useState(null);
    const hiddenFileInput = useRef(null);

    const {t} = useTranslation();
  
    const handleImageChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // Set the image directly without resizing
        setImage(file);
      }
    };
  
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };
  
  
    const handleDescriptionChange = (e) => {
        setInputText(e.target.value);
      adjustHeight(e.target); // Adjust height as user types
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
      
      };
      

  return (
    <div className="profiles-postC">
            <div className="mainPostCont">
                <div className="rowContainer">
                    <input
                        className="buttonCancel" 
                        type="button"
                        value= {t('Cancel')}
                        onClick={handleCancel}
                    />
                    
                    <div className="contbuttonSudmitAdd">
                       <button className="buttonSudmitAdd" onClick={handleAdd}>
                          {t('Add')}
                        </button>                   
                   </div>  
                  </div>

                                  <div className="rowContainer9">
                                
                                      <textarea
                                            ref={descriptionRef}
                                            className="inputPostChido98"
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            value={inputText}
                                            onChange={handleDescriptionChange}
                                            placeholder={t('What do you have in mind')}
                                           
                                          />
                                </div>

                       
                 <div className="con-image-post" onClick={handleClick} style={{ cursor: "pointer" }}>
                   {image ? (
                     <img src={URL.createObjectURL(image)} alt="upload image" className="image-post-add" />
                   ) : (
                     <div className="con-sin-image-user-post"> 
                         <IconContext.Provider   style={{color: 'blue', padding: '5px'}} value={{ size: '35px'  }}>
                             <GrImage/> 
                       </IconContext.Provider>
                     </div>
                   )}
                   <input
                     id="image-upload-input"
                     type="file"
                     onChange={(e) => { handleImageChange(e); handleUpload(e); }}
                     ref={hiddenFileInput}
                     style={{ display: "none" }}
                   />
                 </div> 
     
            </div>
            </div>
        
      );
    };
    
    export default Company;

*/
import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../../firebase/firebase.js';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './user.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const User = () => {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [inputText, setInputText] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    const handleAdd = async (e) => { 
        e.preventDefault();

        if (isSubmitted) {
            return;
        }

        if (!inputText) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const newEmployee = {
            uid: user.uid,
            inputText,
            selectedImage,
            videoPost: videoUrl, // Save the video URL
            createdAt: new Date()
        };

        try {
            await addDoc(collection(db, "employeesPost"), newEmployee);

            setIsSubmitted(true);
            navigate("/SearchJob");
        } catch (error) {
            console.log(error);
        }

        Swal.fire({
            icon: 'success',
            title: 'Added!',
            text: `${inputText}'s data has been Added.`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `postJobs/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                setSelectedImage(downloadUrl);

                if (file.type.startsWith('video/')) {
                    setVideoUrl(downloadUrl);
                } else {
                    setVideoUrl(null);
                }
            }
        );
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedImage(file);
        }
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setInputText(e.target.value);
        adjustHeight(e.target);
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setSelectedImage(null);
        setVideoUrl(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container333">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />

                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>
                </div>

                <div className="rowContainer9">
                    <textarea
                        ref={descriptionRef}
                        className="inputPostChido98"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={inputText}
                        onChange={handleDescriptionChange}
                        placeholder={t('What do you have in mind')}
                    />
                </div>
            </div>

            <div className="column333 right-column333"> 
            {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                )}
                
            <div className="con-image-post43" style={{ cursor: "pointer" }}>
                    {videoUrl ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <video controls className="video-resize">
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        selectedImage ? (
                            <div className="media-container">
                                <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                                <img src={typeof selectedImage === 'string' ? selectedImage : URL.createObjectURL(selectedImage)} alt="upload image" className="image-post-add33" />
                            </div>
                        ) : (
                            <div className="con-sin-image-user-post" onClick={handleClick}>
                                <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                    <GrImage />
                                </IconContext.Provider>
                            </div>
                        )
                    )}
                    <input
                        id="image-upload-input"
                        type="file"
                        onChange={(e) => { handleImageChange(e); handleUpload(e); }}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>

             

            </div>
        </div>
    );
};

export default User;
