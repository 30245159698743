/*
import React from 'react';
import './Buscador.css'; // Make sure to import the CSS file for styles

function Buscador() {
  return (
    <div className="search-containe1">
      <input
        type="text"
        className="search-input1"
        placeholder="Search"
      />
    </div>
  );
}

export default Buscador;
*/
import React, { useState } from 'react';
import './Buscador.css'; // Make sure to import the CSS file for styles
import { useTranslation } from 'react-i18next'; 

function Buscador({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    onSearch(event.target.value); // Notify parent component of search query change
  };

  return (
    <div className="search-containe1">
      <input 
        type="text"
        className="search-input1"
        placeholder={t('Search')}
        value={searchQuery}
        onChange={handleSearchInputChange}
      />
    </div>
  );
}

export default Buscador;

