/*

import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import SolicitudJob from '../../../../src/components/SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import ProfileImage from '../profileImage/profileImage';
import { useTranslation } from 'react-i18next';
 
// Function to calculate Levenshtein distance
function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function QueryFeed({searchTerm, uids, employeesPostData,   setNoResultsCount}) {
    const [searchResults, setSearchResults] = useState([]);
    const [user, loading, error] = useAuthState(auth);
    const [currentUid, setCurrentUid] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const {t} = useTranslation();

    const navigate = useNavigate();

    const openModal = useCallback((uid) => {
        if (user) {
          setSelectedUserId(uid);
          setModalOpen(true);
        } else {
          navigate('/SignUp');
        }
      }, [user, navigate]);
    
      const closeModal = useCallback(() => {
        setModalOpen(false);
        setSelectedUserId(null);
      }, []);

    useEffect(() => {
        // Set currentUid state when user object changes
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'employeesPost'));
            const querySnapshot = await getDocs(q);
            const results = [];
        
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.title) { // Check if inputText is defined
                    const title = data.title.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), title) / Math.max(searchTerm.length, title.length)) * 100;
                    if (similarity >= 50) {
                        results.push(data);
                    }
                }
            });
        
            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };
        

        fetchData();
    }, [searchTerm]);

   

    return (
        <>
        <div className="container-query-maind">

        
                {searchResults.map((result, index) => (
                    <div className="item-query" key={index}>


                        {result.imgPost && <img src={result.imgPost} alt="Post" className="image-all-sizes" />}

                        <div className="post-00ADD">{result.title}</div> 


                        <div className="cityMONUNcon"> 
                        <div className="aaa55m">
                        <>
                            <span className="Weeklyt">{t('Weekly salary')}</span>
                            <div className="post-description1">
                            ${result.salary}
                            </div>
                        </>
                        </div>
                        <div className="aaa55m">
                            <span className="Weeklyt">{t('On-site')}</span>
                            <span className="post-likes">{result.city}</span>
                        </div>
                    </div>

                    <div className="aaaNI">
                          <div className="PSdescription">{result.description}</div>
                   </div>



                        

                        <div className="cityMONUNcon3">
                            <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(employeesPostData[index]?.uid)} >
                                <ProfileImage queryPost={result?.uid} currentUid={currentUid}/>
                         </button>

                         {result.salary && (
                  <button  onClick={() => openModal(employeesPostData[index]?.uid)} className="glow-on-hover">
                    <div className="texANOW">{t('Apply Now')}</div>
                  </button>
                )}
                  </div>                                                    
             </div>
                    ))
                 }
        </div>

        {isModalOpen && <SolicitudJob isOpen={isModalOpen} onClose={closeModal}  postingUserId={selectedUserId} userId={selectedUserId} />}
        </>
    );
}

export default QueryFeed;

*/import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import SolicitudJob from '../../../../src/components/SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import ProfileImage from '../profileImage/profileImage';
import { useTranslation } from 'react-i18next';

// Function to calculate Levenshtein distance
function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function QueryFeed({ searchTerm, uids, employeesPostData, setNoResultsCount }) {
    const [searchResults, setSearchResults] = useState([]);
    const [user] = useAuthState(auth);
    const [currentUid, setCurrentUid] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const openModal = useCallback((uid) => {
        if (user) {
            setSelectedUserId(uid);
            setModalOpen(true);
        } else {
            navigate('/SignUp');
        }
    }, [user, navigate]);

    const closeModal = useCallback(() => {
        setModalOpen(false);
        setSelectedUserId(null);
    }, []);

    useEffect(() => {
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'employeesPost'));
            const querySnapshot = await getDocs(q);
            const results = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.title) {
                    const title = data.title.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), title) / Math.max(searchTerm.length, title.length)) * 100;
                    if (similarity >= 50) {
                        results.push(data);
                    }
                }
            });

            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };

        fetchData();
    }, [searchTerm, setNoResultsCount]);

    return (
        <>
        {searchResults.length > 0 ? (
            <div className="container-query-maind">
                {searchResults.map((result, index) => (
                    <div className="item-query" key={index}>
                        {result.imgPost && <img src={result.imgPost} alt="Post" className="image-all-sizes" />}

                        {result.videoPost && (
                            <div className="video-container">
                                <video className="video-all-sizes" controls>
                                    <source src={result.videoPost} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}

                        <div className="post-00ADD">{result.title}</div>

                        <div className="cityMONUNcon">
                            <div className="aaa55m">
                                <>
                                    <span className="Weeklyt">{t('Weekly salary')}</span>
                                    <div className="post-description1">
                                        ${result.salary}
                                    </div>
                                </>
                            </div>
                            <div className="aaa55m">
                                <span className="Weeklyt">{t('On-site')}</span>
                                <span className="post-likes">{result.city}</span>
                            </div>
                        </div>

                        <div className="aaaNI">
                            <div className="PSdescription">{result.description}</div>
                        </div>

                        <div className="cityMONUNcon3">
                            <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(result.uid)}>
                                <ProfileImage queryPost={result.uid} currentUid={currentUid} />
                            </button>

                            {result.salary && (
                                <button onClick={() => openModal(result.uid)} className="glow-on-hover">
                                    <div className="texANOW">{t('Apply Now')}</div>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        ) : (
            null
        )}

       
    </>
    );
}

export default QueryFeed;
