import './Login.css'
import SignIn from "../components/auth/SignIn";
 
const Add = () => {
  
  return (
    <div  className='login343'> 
      <div className='headerBlock__infoText'>
        <div className='contenedorForm'>
        
          <SignIn/>            
                  
          </div>       
     </div>    
   </div>
  );
};

export default Add;