import React, { useState, useEffect } from 'react';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore'; // Firestore methods
import { db } from '../../../../firebase/firebase'; // Your Firebase setup file
import './save.css';
import { FaRegBookmark, FaBookmark } from 'react-icons/fa';

function Save({ postId, currentUid }) {
  const [isSaved, setIsSaved] = useState(false);

  // Check if the post is already saved when component loads
  useEffect(() => {
    const checkIfSaved = async () => {
      if (!currentUid) return; // Prevent the function from running if user is not authenticated

      try {
        const docRef = doc(db, 'savePost', currentUid, 'savedPosts', postId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsSaved(true); // Post is already saved
        }
      } catch (error) {
        console.error('Error checking saved post:', error);
      }
    };
    checkIfSaved();
  }, [postId, currentUid]);

  // Function to save or unsave the post
  const handleSave = async () => {
    if (!currentUid) {
      alert('You must be logged in to save posts.'); // Show a message if user is not authenticated
      return;
    }

    try {
      const docRef = doc(db, 'savePost', currentUid, 'savedPosts', postId);

      if (isSaved) {
        // If post is already saved, remove it
        await deleteDoc(docRef);
        setIsSaved(false);
      } else {
        // Save the post under user's saved posts
        await setDoc(docRef, { postId, savedAt: new Date() });
        setIsSaved(true);
      }
    } catch (error) {
      console.error('Error saving/unsaving post:', error);
    }
  };

  return (
    <div className="cont54">
      <button onClick={handleSave} className={`save-button ${isSaved ? 'saved' : ''}`}>
        {isSaved ? <FaBookmark size={20} /> : <FaRegBookmark size={20} />}
      </button>
    </div>
  );
}

export default Save;
