import React from 'react';
import { FaRedo, FaSmile } from 'react-icons/fa'; // Importing happy icon
import './NoMoreCard.css';

function NoMoreCard({ onReload }) {
  return (
    <div className="no-more-card">
      <FaSmile size={80} className="no-more-icon" /> {/* Happy icon added */}
      <div className="ttr">We need reload</div>
      <button className="reload-button" onClick={onReload}>
        <FaRedo size={20} className="reload-icon"/>
        Show more
      </button>
    </div>
  );
}

export default NoMoreCard;


