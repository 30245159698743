// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"
 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJMmEARNKBwvCVJ1J2QQDiYCt9Gy2YB9w",
  authDomain: "jobsdatabace.firebaseapp.com",
  projectId: "jobsdatabace",
  storageBucket: "jobsdatabace.appspot.com",
  messagingSenderId: "767065182889",
  appId: "1:767065182889:web:220d69d9a0cf3028c8cfb8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
// Initialize Cloud Texto DB
export const db = getFirestore(app);
// Inicilida image, video
export const storage = getStorage(app);


export default app;