/*
import React from 'react';
import { useLocation } from 'react-router-dom';
 
import Sidenav from '../../components/navigation/Sidenav';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from 're-resizable';
import InputFinder from './InputFinder/InputFinder'
import SaveInterst from './SaveInterst/SaveInterst'
import QueryJobs from './QueryJobs/QueryJobs'

function MainInteresJobs() {
  const location = useLocation();
  const { postId } = location.state || {}; // Destructure postId from location.state
  
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div className="homepage5" style={{ display: 'flex', height: '100vh' }}>

      {isMobile ? (
        <div className="homepage__navWraper5">
          <Sidenav />
        </div>
      ) : (
        <Resizable
          className="homepage__navWraper5"
          defaultSize={{ width: '30%', height: '100%' }}
          minWidth="20%"
          maxWidth="70%"
          enable={{ right: true }}
        >
          <Sidenav />
        </Resizable>
      )}
      <div style={{ flex: 1 }}>
         <InputFinder/> 

         <SaveInterst/>
         
         <QueryJobs/> 

      </div>
    </div>
  );
}

export default MainInteresJobs;

*/


import React from 'react';
import { useLocation } from 'react-router-dom';
 
import Sidenav from '../../components/navigation/Sidenav';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from 're-resizable';
import InputFinder from './InputFinder/InputFinder'
import SaveInterst from './SaveInterst/SaveInterst'
import QueryJobs from './QueryJobs/QueryJobs'

function MainInteresJobs() {
  const location = useLocation();
  const { postId } = location.state || {}; // Destructure postId from location.state
  
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div className="homepage5" style={{ display: 'flex', height: '100vh' }}>

      {isMobile ? (
        <div className="homepage__navWraper5">
          <Sidenav />
        </div>
      ) : (
        <Resizable
          className="homepage__navWraper5"
          defaultSize={{ width: '30%', height: '100%' }}
          minWidth="20%"
          maxWidth="70%"
          enable={{ right: true }}
        >
          <Sidenav />
        </Resizable>
      )}
      <div style={{ flex: 1 }}>
         
         <InputFinder/> 
          
         <QueryJobs/> 
      </div>
    </div>
  );
}

export default MainInteresJobs;