/*

import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaIdBadge, FaCalendarAlt, FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function UserCvForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    age: '',
    gender: '',
    nationality: '',
    phone: '',
    postalCode: '',
    email: '',
    englishLevel: '',
    workExperience: '',
    uid: ''  // Added uid to formData
  });
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  // Update formData with uid when user is authenticated
  useEffect(() => {
    if (user) {
      setFormData(prevState => ({
        ...prevState,
        uid: user.uid
      }));
    }
  }, [user]);

  useEffect(() => {
    const loadFormData = async () => {
      if (user) {
        const docRef = doc(db, 'userCv', user.uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setFormData(prevState => ({
              ...prevState,
              ...docSnap.data()
            }));
          }
        } catch (error) {
          console.error('Error loading document:', error);
        }
      }
    };

    loadFormData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRoomSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const docRef = doc(db, 'userCv', user.uid);
      try {
        await setDoc(docRef, formData, { merge: true });
        setFormSubmitted(true);
        console.log('Document successfully written!');
      } catch (error) {
        console.error('Error saving document:', error);
      }
    }
  };

  const handleContinue = () => {
    navigate('/SearchJob');
  };

  return (
    <div className="ConCompanyCv">
      {!formSubmitted ? (
        <>
          <div className="progressBar45">
            <div className="progress" style={{ width: `${(Object.values(formData).filter(val => val !== '').length / 10) * 100}%` }}></div>
          </div>
          <div className="formScrollContainer">
          <form className="solicitudJob" onSubmit={handleRoomSubmit}>
            <div className="tCompanyCv">{t('Information')}</div>
            <div className="SubTexAplication">{t('Fill out your information')}</div>
            
            <div className="inputContainer">
              <FaUser className="inputIcon" />
              <input className='inputTextForm' type="text" name="fullName" onChange={handleChange} value={formData.fullName} placeholder={t('Full Name')} />
            </div>
            
            <div className="inputContainer">
              <FaCalendarAlt className="inputIcon" />
              <input className="inputTextForm" type="number" name="age" onChange={handleChange} value={formData.age} placeholder={t('age')}/>
            </div>

            <div className="inputContainer">
              <FaIdBadge className="inputIcon" />
              <input className="inputTextForm" type="text" name="gender" onChange={handleChange} value={formData.gender} placeholder={t('Gender')} />
            </div>
            
            <div className="inputContainer">
              <FaGlobe className="inputIcon" />
              <input className='inputTextForm' type="text" name="nationality" onChange={handleChange} value={formData.nationality} placeholder={t('Nationality')} />
            </div>
            
            <div className="inputContainer">
              <FaPhone className="inputIcon" />
              <input className='inputTextForm' type="text" name="phone" onChange={handleChange} value={formData.phone} placeholder={t('Phone')} />
            </div>
            
            <div className="inputContainer">
              <FaMapMarkerAlt className="inputIcon" />
              <input className='inputTextForm' type="text" name="postalCode" onChange={handleChange} value={formData.postalCode} placeholder={t('Address')} />
            </div>

            <div className="inputContainer">
              <FaEnvelope className="inputIcon" />
              <input className="inputTextForm" type="email" name="email" onChange={handleChange} value={formData.email} placeholder="Email" />
            </div>

            <div className="inputContainer">
              <FaUser className="inputIcon" />
              <input className="inputTextForm" type="text" name="englishLevel" onChange={handleChange} value={formData.englishLevel} placeholder={t('English Level')} />
            </div>

            <div className="inputContainer">
              <FaUser className="inputIcon " />
              <textarea className='inputTextForm noResize' name="workExperience" onChange={handleChange} value={formData.workExperience} placeholder={t('Work Experience')}></textarea>
            </div>

            <button className='btAplication' type="submit">
              {t('Next')}
            </button>
          </form>
          </div>
        </>
      ) : (
        <div className="submission-success">
          <div className="cheek">✔</div>
          <h2 className="tCompanyCv">{t('Your Information was saved!')}</h2>
          <p>{t('This will help find the right companies more easily.')}</p>
          <button className="btAplication" onClick={handleContinue}>
            {t('welcome')}
          </button>
        </div>
      )}
    </div>
  );
}

export default UserCvForm;


*/
import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaIdBadge, FaCalendarAlt, FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import successImage from '../../../assets/welcome.png'; 

import './UserCvForm.css';

function UserCvForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    age: '',
    gender: '',
    nationality: '',
    phone: '',
    postalCode: '',
    email: '',
    englishLevel: '',
    workExperience: '',
    uid: ''  // Added uid to formData
  });
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  // Update formData with uid when user is authenticated
  useEffect(() => {
    if (user) {
      setFormData(prevState => ({
        ...prevState,
        uid: user.uid
      }));
    }
  }, [user]);

  useEffect(() => {
    const loadFormData = async () => {
      if (user) {
        const docRef = doc(db, 'userCv', user.uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setFormData(prevState => ({
              ...prevState,
              ...docSnap.data()
            }));
          }
        } catch (error) {
          console.error('Error loading document:', error);
        }
      }
    };

    loadFormData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRoomSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const docRef = doc(db, 'userCv', user.uid);
      try {
        await setDoc(docRef, formData, { merge: true });
        setFormSubmitted(true);
        console.log('Document successfully written!');
      } catch (error) {
        console.error('Error saving document:', error);
      }
    }
  };

  const handleContinue = () => {
    navigate('/SearchJob');
  };

  return (
    <div className="ConCompanyCv">
      {!formSubmitted ? (
        <>
          <div className="progressBar45">
            <div className="progress" style={{ width: `${(Object.values(formData).filter(val => val !== '').length / 10) * 100}%` }}></div>
          </div>
          <div className="formScrollContainer">
          <form className="solicitudJob" onSubmit={handleRoomSubmit}>
            <div className="tCompanyCv">{t('Information')}</div>
            <div className="SubTexAplication">{t('Fill out your information')}</div>
            
            <div className="inputContainer">
              <FaUser className="inputIcon" />
              <input className='inputTextForm' type="text" name="fullName" onChange={handleChange} value={formData.fullName} placeholder={t('Full Name')} />
            </div>
            
            <div className="inputContainer">
              <FaCalendarAlt className="inputIcon" />
              <input className="inputTextForm" type="number" name="age" onChange={handleChange} value={formData.age} placeholder={t('age')}/>
            </div>

            <div className="inputContainer">
              <FaIdBadge className="inputIcon" />
              <input className="inputTextForm" type="text" name="gender" onChange={handleChange} value={formData.gender} placeholder={t('Gender')} />
            </div>
            
            <div className="inputContainer">
              <FaGlobe className="inputIcon" />
              <input className='inputTextForm' type="text" name="nationality" onChange={handleChange} value={formData.nationality} placeholder={t('Nationality')} />
            </div>
            
            <div className="inputContainer">
              <FaPhone className="inputIcon" />
              <input className='inputTextForm' type="text" name="phone" onChange={handleChange} value={formData.phone} placeholder={t('Phone')} />
            </div>
            
            <div className="inputContainer">
              <FaMapMarkerAlt className="inputIcon" />
              <input className='inputTextForm' type="text" name="postalCode" onChange={handleChange} value={formData.postalCode} placeholder={t('Address')} />
            </div>

            <div className="inputContainer">
              <FaEnvelope className="inputIcon" />
              <input className="inputTextForm" type="email" name="email" onChange={handleChange} value={formData.email} placeholder="Email" />
            </div>

            <div className="inputContainer">
              <FaUser className="inputIcon" />
              <input className="inputTextForm" type="text" name="englishLevel" onChange={handleChange} value={formData.englishLevel} placeholder={t('English Level')} />
            </div>

            <div className="inputContainer">
              <FaUser className="inputIcon " />
              <textarea className='inputTextForm noResize' name="workExperience" onChange={handleChange} value={formData.workExperience} placeholder={t('Work Experience')}></textarea>
            </div>

            <button className='btAplication' type="submit">
              {t('Next')}
            </button>
          </form>
          </div>
        </>
      ) : (
        <div className="submission-success">
          <img src={successImage} alt="Success" className="successImage" /> 
         
          <h2 className="tCompanyCv">{t('Your Information was saved!')}</h2>
          <p>{t('This will help find the right companies more easily.')}</p>
          <button className="btAplication" onClick={handleContinue}>
            {t('welcome')}
          </button>
        </div>
      )}
    </div>
  );
}

export default UserCvForm;
