
/*
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import './HistorysPage.css'; // Custom styles for carousel
import { useNavigate } from 'react-router-dom';

function HistorysPage() {
  const [storiesByUser, setStoriesByUser] = useState({});
  const [currentUserIndex, setCurrentUserIndex] = useState(0); // Track the user being displayed
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0); // Track the story for the current user
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use navigate at the top of the component
  const navigate = useNavigate();

  // The handleGoBack function will now use the navigate function directly
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page in the history stack
  };

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const storiesCollection = collection(db, "HistoryPosts");
        const storiesSnapshot = await getDocs(storiesCollection);
        const storiesList = storiesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const storiesGroupedByUser = storiesList.reduce((acc, story) => {
          const { uid } = story;
          if (!acc[uid]) {
            acc[uid] = [];
          }
          acc[uid].push(story);
          return acc;
        }, {});

        setStoriesByUser(storiesGroupedByUser);
        setLoading(false);
      } catch (err) {
        setError("Error fetching stories");
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  const handleNextStory = () => {
    const userIds = Object.keys(storiesByUser);
    const userStories = storiesByUser[userIds[currentUserIndex]];

    // Move to the next story for the current user, or move to the next user
    if (currentStoryIndex < userStories.length - 1) {
      setCurrentStoryIndex((prevIndex) => prevIndex + 1);
    } else if (currentUserIndex < userIds.length - 1) {
      setCurrentUserIndex((prevIndex) => prevIndex + 1);
      setCurrentStoryIndex(0); // Reset story index for the new user
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex((prevIndex) => prevIndex - 1);
    } else if (currentUserIndex > 0) {
      setCurrentUserIndex((prevIndex) => prevIndex - 1);
      const prevUserStories = storiesByUser[Object.keys(storiesByUser)[currentUserIndex - 1]];
      setCurrentStoryIndex(prevUserStories.length - 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const userIds = Object.keys(storiesByUser);
  const currentUserStories = storiesByUser[userIds[currentUserIndex]];

  // Progress bar width calculation
  const progressBarWidth = (currentUserStories.length > 1)
    ? `${((currentStoryIndex + 1) / currentUserStories.length) * 100}%`
    : '100%';

  return (
    <div className="historys-page">
      {currentUserStories && currentUserStories.length > 1 && (
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: progressBarWidth }}
          ></div>
        </div>
      )}

      <div className="carousel">
       
        <button className="go-back-button" onClick={handleGoBack}>
          X  
        </button>
        
        {currentUserStories && currentUserStories.length > 0 ? (
          <>
            <button className="carousel-button prev-button" onClick={handlePreviousStory}>&lt;</button>
            <div className="carousel-content">
              <div className="story-card">
                {currentUserStories[currentStoryIndex].selectedImage ? (
                  <img
                    src={currentUserStories[currentStoryIndex].selectedImage}
                    className="story-image"
                  />
                ) : (
                  null
                )}

                {currentUserStories[currentStoryIndex].videoPost && (
                  <video controls className="story-video">
                    <source src={currentUserStories[currentStoryIndex].videoPost} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                {!currentUserStories[currentStoryIndex].selectedImage && !currentUserStories[currentStoryIndex].videoPost && (
                  <p className="st22">{currentUserStories[currentStoryIndex].inputText}</p>
                )}
                <div className="story-footer">
                  <p className="st23">{new Date(currentUserStories[currentStoryIndex].createdAt.seconds * 1000).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
            <button className="carousel-button next-button" onClick={handleNextStory}>&gt;</button>
          </>
        ) : (
          <div className="no-stories-message">
            <p>No stories available.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default HistorysPage;
*/
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import './HistorysPage.css'; // Custom styles for carousel
import { useNavigate, useLocation } from 'react-router-dom';

function HistorysPage() {
  const [storiesByUser, setStoriesByUser] = useState({});
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Extract userId and storyId from query parameters
  const queryParams = new URLSearchParams(location.search);
  const selectedUserId = queryParams.get('userId');
  const selectedStoryId = queryParams.get('storyId');

  // Handle Go Back to previous page
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  // Fetch stories from Firestore
  useEffect(() => {
    const fetchStories = async () => {
      try {
        const storiesCollection = collection(db, "HistoryPosts");
        const storiesSnapshot = await getDocs(storiesCollection);
        const storiesList = storiesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Group stories by user
        const storiesGroupedByUser = storiesList.reduce((acc, story) => {
          const { uid } = story;
          if (!acc[uid]) {
            acc[uid] = [];
          }
          acc[uid].push(story);
          return acc;
        }, {});

        setStoriesByUser(storiesGroupedByUser);

        // Set initial user and story based on selected story
        if (selectedUserId && selectedStoryId) {
          const userIds = Object.keys(storiesGroupedByUser);
          const initialUserIndex = userIds.indexOf(selectedUserId);
          
          if (initialUserIndex !== -1) {
            setCurrentUserIndex(initialUserIndex);
            const userStories = storiesGroupedByUser[selectedUserId];
            const initialStoryIndex = userStories.findIndex(story => story.id === selectedStoryId);
            
            if (initialStoryIndex !== -1) {
              setCurrentStoryIndex(initialStoryIndex);
            }
          }
        }

        setLoading(false);
      } catch (err) {
        setError("Error fetching stories");
        setLoading(false);
      }
    };

    fetchStories();
  }, [selectedUserId, selectedStoryId]);

  // Handle navigation for next and previous stories
// Handle navigation for next and previous stories
// Handle navigation for next and previous stories
const handleNextStory = () => {
  const userIds = Object.keys(storiesByUser);
  const userStories = storiesByUser[userIds[currentUserIndex]];

  if (currentStoryIndex < userStories.length - 1) {
    // Move to the next story within the same user
    setCurrentStoryIndex((prevIndex) => prevIndex + 1);
  } else if (currentUserIndex < userIds.length - 1) {
    // Move to the next user and start from the first story
    setCurrentUserIndex((prevIndex) => prevIndex + 1);
    setCurrentStoryIndex(0);
  } else {
    // Wrap around to the first user and their first story
    setCurrentUserIndex(0);
    setCurrentStoryIndex(0);
  }
};

const handlePreviousStory = () => {
  const userIds = Object.keys(storiesByUser);

  if (currentStoryIndex > 0) {
    // Move to the previous story within the same user
    setCurrentStoryIndex((prevIndex) => prevIndex - 1);
  } else if (currentUserIndex > 0) {
    // Move to the previous user and their last story
    const prevUserStories = storiesByUser[userIds[currentUserIndex - 1]];
    setCurrentUserIndex((prevIndex) => prevIndex - 1);
    setCurrentStoryIndex(prevUserStories.length - 1);
  } else {
    // Wrap around to the last user and their last story
    const lastUserStories = storiesByUser[userIds[userIds.length - 1]];
    setCurrentUserIndex(userIds.length - 1);
    setCurrentStoryIndex(lastUserStories.length - 1);
  }
};


  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const userIds = Object.keys(storiesByUser);
  const currentUserStories = storiesByUser[userIds[currentUserIndex]];

  // Progress bar width calculation
  const progressBarWidth = (currentUserStories.length > 1)
    ? `${((currentStoryIndex + 1) / currentUserStories.length) * 100}%`
    : '100%';

  return (
    <div className="historys-page">
      {currentUserStories && currentUserStories.length > 1 && (
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: progressBarWidth }}
          ></div>
        </div>
      )}

      <div className="carousel">
        <button className="go-back-button" onClick={handleGoBack}>
          X {/* You can replace this with an icon */}
        </button>

        {currentUserStories && currentUserStories.length > 0 ? (
          <>
            <button className="carousel-button prev-button" onClick={handlePreviousStory}>
              &lt;
            </button>
            <div className="carousel-content">
              <div className="story-card">
                {currentUserStories[currentStoryIndex].selectedImage ? (
                  <img
                    src={currentUserStories[currentStoryIndex].selectedImage}
                    className="story-image"
                    alt="Story"
                  />
                ) : null}

                {currentUserStories[currentStoryIndex].videoPost && (
                  <video controls className="story-video">
                    <source src={currentUserStories[currentStoryIndex].videoPost} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}

                {!currentUserStories[currentStoryIndex].selectedImage && !currentUserStories[currentStoryIndex].videoPost && (
                  <p className="story-text">{currentUserStories[currentStoryIndex].inputText}</p>
                )}

                <div className="story-footer">
                  {currentUserStories[currentStoryIndex].createdAt ? (
                    <p className="story-date">
                      {new Date(currentUserStories[currentStoryIndex].createdAt.seconds * 1000).toLocaleDateString()}
                    </p>
                  ) : (
                    <p className="story-date">No date available</p>
                  )}
                </div>
              </div>
            </div>
            <button className="carousel-button next-button" onClick={handleNextStory}>
              &gt;
            </button>
          </>
        ) : (
          <div className="no-stories-message">
            <p>No stories available.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default HistorysPage;



/*


import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import './HistorysPage.css'; // Custom styles for carousel
import { useNavigate, useLocation } from 'react-router-dom';

function HistorysPage() {
  const [storiesByUser, setStoriesByUser] = useState({});
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Extract userId and storyId from query parameters
  const queryParams = new URLSearchParams(location.search);
  const selectedUserId = queryParams.get('userId');
  const selectedStoryId = queryParams.get('storyId');

  // Handle Go Back to previous page
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  // Fetch stories from Firestore
  useEffect(() => {
    const fetchStories = async () => {
      try {
        const storiesCollection = collection(db, "HistoryPosts");
        const storiesSnapshot = await getDocs(storiesCollection);
        const storiesList = storiesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Group stories by user
        const storiesGroupedByUser = storiesList.reduce((acc, story) => {
          const { uid } = story;
          if (!acc[uid]) {
            acc[uid] = [];
          }
          acc[uid].push(story);
          return acc;
        }, {});

        setStoriesByUser(storiesGroupedByUser);

        // Set initial user and story based on selected story
        if (selectedUserId && selectedStoryId) {
          const userIds = Object.keys(storiesGroupedByUser);
          const initialUserIndex = userIds.indexOf(selectedUserId);
          
          if (initialUserIndex !== -1) {
            setCurrentUserIndex(initialUserIndex);
            const userStories = storiesGroupedByUser[selectedUserId];
            const initialStoryIndex = userStories.findIndex(story => story.id === selectedStoryId);
            
            if (initialStoryIndex !== -1) {
              setCurrentStoryIndex(initialStoryIndex);
            }
          }
        }

        setLoading(false);
      } catch (err) {
        setError("Error fetching stories");
        setLoading(false);
      }
    };

    fetchStories();
  }, [selectedUserId, selectedStoryId]);

  // Handle navigation for next and previous stories
  const handleNextStory = () => {
    const userIds = Object.keys(storiesByUser);
    const userStories = storiesByUser[userIds[currentUserIndex]];

    if (currentStoryIndex < userStories.length - 1) {
      setCurrentStoryIndex((prevIndex) => prevIndex + 1);
    } else if (currentUserIndex < userIds.length - 1) {
      setCurrentUserIndex((prevIndex) => prevIndex + 1);
      setCurrentStoryIndex(0);
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex((prevIndex) => prevIndex - 1);
    } else if (currentUserIndex > 0) {
      setCurrentUserIndex((prevIndex) => prevIndex - 1);
      const prevUserStories = storiesByUser[Object.keys(storiesByUser)[currentUserIndex - 1]];
      setCurrentStoryIndex(prevUserStories.length - 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const userIds = Object.keys(storiesByUser);
  const currentUserStories = storiesByUser[userIds[currentUserIndex]];

  // Progress bar width calculation
  const progressBarWidth = (currentUserStories.length > 1)
    ? `${((currentStoryIndex + 1) / currentUserStories.length) * 100}%`
    : '100%';

  return (
    <div className="historys-page">
      {currentUserStories && currentUserStories.length > 1 && (
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: progressBarWidth }}
          ></div>
        </div>
      )}

      <div className="carousel">
        <button className="go-back-button" onClick={handleGoBack}>
          X  
          </button>

          {currentUserStories && currentUserStories.length > 0 ? (
            <>
              <button className="carousel-button prev-button" onClick={handlePreviousStory}>
                &lt;
              </button>
              <div className="carousel-content">
                <div className="story-card">
                  {currentUserStories[currentStoryIndex].selectedImage ? (
                    <img
                      src={currentUserStories[currentStoryIndex].selectedImage}
                      className="story-image"
                      alt="Story"
                    />
                  ) : null}
  
                  {currentUserStories[currentStoryIndex].videoPost && (
                    <video controls className="story-video">
                      <source src={currentUserStories[currentStoryIndex].videoPost} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
  
                  {!currentUserStories[currentStoryIndex].selectedImage && !currentUserStories[currentStoryIndex].videoPost && (
                    <p className="story-text">{currentUserStories[currentStoryIndex].inputText}</p>
                  )}
  
                  <div className="story-footer">
                    {currentUserStories[currentStoryIndex].createdAt ? (
                      <p className="story-date">
                        {new Date(currentUserStories[currentStoryIndex].createdAt.seconds * 1000).toLocaleDateString()}
                      </p>
                    ) : (
                      <p className="story-date">No date available</p>
                    )}
                  </div>
                </div>
              </div>
              <button className="carousel-button next-button" onClick={handleNextStory}>
                &gt;
              </button>
            </>
          ) : (
            <div className="no-stories-message">
              <p>No stories available.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
  
  export default HistorysPage;
  

*/