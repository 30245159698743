/* 




import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';

import SolicitudJob from '../../../../src/components/SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';

import ProfileImage from '../profileImage/profileImage';

import './QueryFeed.css';

// Function to calculate Levenshtein distance
function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1).toLowerCase() === a.charAt(j - 1).toLowerCase()) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function QueryFeed({ searchTerm, uids, employeesPostData, setNoResultsCount }) {
    const [user] = useAuthState(auth);
    const [searchResults, setSearchResults] = useState([]);
    const [currentUid, setCurrentUid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'employeesPost'));
            const querySnapshot = await getDocs(q);
            const results = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.inputText) { 
                    const inputText = data.inputText.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), inputText) / Math.max(searchTerm.length, inputText.length)) * 100;
                    if (similarity >= 50) {
                        results.push(data);
                    }
                }
            });

            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };  
        fetchData();
    }, [searchTerm, setNoResultsCount]);

    return (
        <div className="container-query-maind">
            {searchResults.map((result, index) => (
                <div className="item-query" key={index}>
                    {result.selectedImage && <img src={result.selectedImage} alt="Post" className="image-all-sizes" />}
                    <div className="post-00ADD">{result.inputText}</div> 
                    <div className="cityMONUNcon3">
                        <button
                            className="navigatePerfilButton"
                            onClick={() => handleNavigateToProfile(result.uid)} // Use result.uid directly
                        >
                            <ProfileImage queryPost={result.uid} currentUid={currentUid} />
                            <div>{result.uid}</div>
                        </button>
                        <div className="CCS4">
                            <SendMessage uidThePost={result.uid} currentUid={currentUid} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default QueryFeed;



*/

 
import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';

import SolicitudJob from '../../../../src/components/SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import ProfileImage from '../profileImage/profileImage';

import './QueryFeed.css';

// Function to calculate Levenshtein distance
function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1).toLowerCase() === a.charAt(j - 1).toLowerCase()) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function QueryFeed({ searchTerm,   setNoResultsCount }) {
    const [user] = useAuthState(auth);
    const [searchResults, setSearchResults] = useState([]);
    const [currentUid, setCurrentUid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'employeesPost'));
            const querySnapshot = await getDocs(q);
            const results = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.inputText) { 
                    const inputText = data.inputText.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), inputText) / Math.max(searchTerm.length, inputText.length)) * 100;
                    if (similarity >= 50) {
                        results.push(data);
                    }
                }
            });

            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };  
        fetchData();
    }, [searchTerm, setNoResultsCount]);

    return (
        <>
        {searchResults.length > 0 ? (
            <div className="container-query-maind">
                {searchResults.map((result, index) => (
                    <div className="item-query" key={index}>
                        {result.selectedImage && (
                            <img src={result.selectedImage} alt="Post" className="image-all-sizes" />
                        )}
                        <div className="post-00ADD">{result.inputText}</div>

                        {result.videoPost && (
                            <div className="video-container">
                                <video controls className="video-all-sizes">
                                    <source src={result.videoPost} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}

                        <div className="cityMONUNcon3">
                            <button
                                className="navigatePerfilButton"
                                onClick={() => handleNavigateToProfile(result.uid)}
                            >
                                <ProfileImage queryPost={result.uid} currentUid={currentUid} />
                            </button>
                            <div className="CCS4">
                                <SendMessage uidThePost={result.uid} currentUid={currentUid} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ) : (
           null // Optionally, use an empty fragment (`<> </>`) if you want to hide everything
        )}
    </>
    );
}

export default QueryFeed;
