/*


import React, { useState } from 'react';
import { storage, db } from '../../../../firebase/firebase'; // Ensure you have the correct path to your Firebase config
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { IoIosImage } from "react-icons/io";

function ImagesButton({ selectedChat, currentUid, messages, setMessages }) {
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Calculate progress percentage
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
      },
      async () => {
        // Handle successful uploads
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        if (selectedChat && currentUid) {
          await addDoc(collection(db, `chats/${selectedChat.id}/conversacion`), {
            imageUrl: downloadURL,
            sentBy: currentUid,
            timestamp: serverTimestamp(),
          });
          // Reset progress after successful upload
          setUploadProgress(0);
        }
      }
    );
  };

  return (
    <div>
      {uploadProgress > 0 && (
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${uploadProgress}%`, color: 'white', textAlign: 'center' }}
          >
            {Math.round(uploadProgress)}%
          </div>
        </div>
      )}

      <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>
        <IoIosImage className="search-icon" size={30} />
      </label>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        id="image-upload"
        style={{ display: 'none' }}
      />
    </div>
  );
}

export default ImagesButton;



*/
import React, { useState } from 'react';
import { storage, db } from '../../../../firebase/firebase'; // Ensure you have the correct path to your Firebase config
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { IoIosImage } from "react-icons/io";

function ImagesButton({ selectedChat, currentUid, messages, setMessages }) {
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Calculate progress percentage
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
      },
      async () => {
        // Handle successful uploads
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        if (selectedChat && currentUid) {
          await addDoc(collection(db, `chats/${selectedChat.id}/conversacion`), {
            imageUrl: downloadURL,
            sentBy: currentUid,
            timestamp: serverTimestamp(),
          });
          // Reset progress after successful upload
          setUploadProgress(0);
        }
      }
    );
  };

  return (
    <div>
      {uploadProgress > 0 && (
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${uploadProgress}%`, color: 'white', textAlign: 'center' }}
          >
            {Math.round(uploadProgress)}%
          </div>
        </div>
      )}

      <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>
        <IoIosImage className="search-icon" size={30} />
      </label>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        id="image-upload"
        style={{ display: 'none' }}
      />
    </div>
  );
}

export default ImagesButton;
