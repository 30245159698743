/*


import React, { useEffect, useState, useRef } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import './chatOneToOneFeed.css';

function ChatOneToOneFeed({ selectedChat, currentUid }) {
  const [messages, setMessages] = useState([]);
  const messageContainerRef = useRef(null);
  const messageEndRef = useRef(null);

  useEffect(() => {
    if (!selectedChat?.id || !currentUid) {
      console.error("Selected chat and current user ID must be defined.");
      return;
    }

    const originalString = selectedChat?.id;
    const rearrangedString = originalString.split('_').reverse().join('_');

    const messagesQuery = query(
      collection(db, `chats/${selectedChat.id}/conversacion`),
      orderBy('timestamp', 'desc'),
      limit(500)
    );

    const messagesQuery2 = query(
      collection(db, `chats/${rearrangedString}/conversacion`),
      orderBy('timestamp', 'desc'),
      limit(500)
    );

    const unsubscribe1 = onSnapshot(messagesQuery, (querySnapshot) => {
      const newMessages = querySnapshot.docChanges().map(change => ({
        ...change.doc.data(),
        id: change.doc.id
      })).reverse(); // Reverse the messages to display in correct order

      setMessages(prevMessages => {
        const merged = new Map([...prevMessages, ...newMessages].map(msg => [msg.id, msg]));
        return Array.from(merged.values());
      });
    });

    const unsubscribe2 = onSnapshot(messagesQuery2, (querySnapshot) => {
      const newMessages = querySnapshot.docChanges().map(change => ({
        ...change.doc.data(),
        id: change.doc.id
      })).reverse(); // Reverse the messages to display in correct order

      setMessages(prevMessages => {
        const merged = new Map([...prevMessages, ...newMessages].map(msg => [msg.id, msg]));
        return Array.from(merged.values());
      });
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [selectedChat, currentUid]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
      const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 1;
      if (isScrolledToBottom || !messages.length) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    setMessages([]);
  }, [selectedChat]);

  const downloadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'image.png'; // Set the default download name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  return (
    <div style={{ marginTop: "50px", marginBottom: '30px', maxHeight: '100%', minWidth: '100px' }} ref={messageContainerRef}>
      {selectedChat ? (
        <div className="messageContainer55">
          {messages.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0)).map((message, index, arr) => {
            const currentDate = new Date(message.timestamp?.seconds * 1000).toLocaleDateString();
            const previousDate = index > 0 ? new Date(arr[index - 1].timestamp?.seconds * 1000).toLocaleDateString() : null;
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const messageTextWithLinks = message.Text ? message.Text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`) : '';

            return (
              <React.Fragment key={message.id}>
                <div className={`message1 ${message.sentBy === currentUid ? 'sentByYou' : 'sentByPartner'}`}>
                  {message.imageUrl ? (
                    <div>
                      <img src={message.imageUrl} alt="Sent image" className="chat-image" />
                      <button onClick={() => downloadImage(message.imageUrl)} className="download-button">
                        Download
                      </button>
                    </div>
                  ) : message.videoUrl ? (
                    <video controls className="chat-video">
                      <source src={message.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : message.pdfUrl ? (
                    <div className="chat-pdf">
                      <a href={message.pdfUrl} target="_blank" rel="noopener noreferrer">View PDF</a>
                    </div>
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: messageTextWithLinks }} />
                  )}
                </div>
                {(index === 0 || currentDate !== previousDate) && (
                  <div className="date">{currentDate}</div>
                )}
              </React.Fragment>
            );
          })}
          <div ref={messageEndRef} />
        </div>
      ) : (
        <p>No chat selected.</p>
      )}
    </div>
  );
}

export default ChatOneToOneFeed;


*/import React, { useEffect, useState, useRef } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import './chatOneToOneFeed.css';

function ChatOneToOneFeed({ selectedChat, currentUid }) {
  const [messages, setMessages] = useState([]);
  const messageContainerRef = useRef(null);
  const messageEndRef = useRef(null);

  useEffect(() => {
    if (!selectedChat?.id || !currentUid) {
      console.error("Selected chat and current user ID must be defined.");
      return;
    }

    const originalString = selectedChat?.id;
    const rearrangedString = originalString.split('_').reverse().join('_');

    const messagesQuery = query(
      collection(db, `chats/${selectedChat.id}/conversacion`),
      orderBy('timestamp', 'desc'),
      limit(500)
    );

    const messagesQuery2 = query(
      collection(db, `chats/${rearrangedString}/conversacion`),
      orderBy('timestamp', 'desc'),
      limit(500)
    );

    const unsubscribe1 = onSnapshot(messagesQuery, (querySnapshot) => {
      const newMessages = querySnapshot.docChanges().map(change => ({
        ...change.doc.data(),
        id: change.doc.id
      })).reverse(); // Reverse the messages to display in correct order

      setMessages(prevMessages => {
        const merged = new Map([...prevMessages, ...newMessages].map(msg => [msg.id, msg]));
        return Array.from(merged.values());
      });
    });

    const unsubscribe2 = onSnapshot(messagesQuery2, (querySnapshot) => {
      const newMessages = querySnapshot.docChanges().map(change => ({
        ...change.doc.data(),
        id: change.doc.id
      })).reverse(); // Reverse the messages to display in correct order

      setMessages(prevMessages => {
        const merged = new Map([...prevMessages, ...newMessages].map(msg => [msg.id, msg]));
        return Array.from(merged.values());
      });
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [selectedChat, currentUid]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
      const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 1;
      if (isScrolledToBottom || !messages.length) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    setMessages([]);
  }, [selectedChat]);

  const downloadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'image.png'; // Set the default download name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  return (
    <div style={{ marginTop: "50px", marginBottom: '30px', maxHeight: '100%', minWidth: '100px' }} ref={messageContainerRef}>
      {selectedChat ? (
        <div className="messageContainer55">
          {messages.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0)).map((message, index, arr) => {
            const currentDate = new Date(message.timestamp?.seconds * 1000).toLocaleDateString();
            const previousDate = index > 0 ? new Date(arr[index - 1].timestamp?.seconds * 1000).toLocaleDateString() : null;
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const messageTextWithLinks = message.Text ? message.Text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`) : '';

            return (
              <React.Fragment key={message.id}>
                <div className={`message1 ${message.sentBy === currentUid ? 'sentByYou' : 'sentByPartner'}`}>
                  {message.imageUrl ? (
                    <div>
                      <img src={message.imageUrl} alt="Sent image" className="chat-image" />
                      <button onClick={() => downloadImage(message.imageUrl)} className="download-button">
                        Download
                      </button>
                    </div>
                  ) : message.videoUrl ? (
                    <video controls className="chat-video">
                      <source src={message.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : message.pdfUrl ? (
                    <div className="chat-pdf">
                      <a href={message.pdfUrl} target="_blank" rel="noopener noreferrer">View PDF</a>
                    </div>
                  ) : message.location ? (
                    // If the message contains location data, display the map
                    <iframe
                      src={`https://www.google.com/maps?q=${message.location.latitude},${message.location.longitude}&z=15&output=embed`}
                      title="Location"
                      className="chat-location"
                      allowFullScreen
                    />
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: messageTextWithLinks }} />
                  )}
                </div>
                {(index === 0 || currentDate !== previousDate) && (
                  <div className="date">{currentDate}</div>
                )}
              </React.Fragment>
            );
          })}
          <div ref={messageEndRef} />
        </div>
      ) : (
        <p>No chat selected.</p>
      )}
    </div>
  );
}

export default ChatOneToOneFeed;
