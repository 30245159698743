/*



import React, { useState, useEffect } from "react";
import { storage, db, auth } from "../../firebase/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import './SetUpAccCompany.css';
import { useTranslation } from 'react-i18next';
import { FaPhone, FaCity, FaBuilding, FaBriefcase, FaUser, FaImage  } from 'react-icons/fa';


function SetUpAccCompany() {
  const navigate = useNavigate();
  const [imgPerfil, setImgPerfil] = useState('');
  const [imgBack, setImgBack] = useState('');
  const [imgPerfilPreview, setImgPerfilPreview] = useState('');
  const [imgBackPreview, setImgBackPreview] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [city, setCity] = useState('');
  const [descriptionCompany, setDescriptionCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const [profileProgress, setProfileProgress] = useState(0);
  const [backgroundProgress, setBackgroundProgress] = useState(0);
  const [formProgress, setFormProgress] = useState(0); // Progress of input fields
  const [totalProgress, setTotalProgress] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/Login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    // Calculate form progress based on the completion of input fields
    let completedFields = 0;
    if (nameCompany) completedFields++;
    if (city) completedFields++;
    if (descriptionCompany) completedFields++;
    if (phone) completedFields++;

    const formProgress = (completedFields / 4) * 100; // 4 input fields
    setFormProgress(formProgress);

    // Determine if all fields are valid
    const isValid = completedFields === 4;
    setAllFieldsValid(isValid);
  }, [nameCompany, city, descriptionCompany, phone]);

  useEffect(() => {
    // Calculate total progress based on both form and image upload progress
    const totalProgress = (formProgress + profileProgress + backgroundProgress) / 3;
    setTotalProgress(totalProgress); // Update the totalProgress state
  }, [formProgress, profileProgress, backgroundProgress]);

  const handleUpload = async (file, imageType) => {
    const fileRef = ref(storage, `FotosPerfilCompany/${uuidv4()}-${imageType}`);
    try {
      const snapshot = await uploadBytes(fileRef, file);
      const url = await getDownloadURL(snapshot.ref);
      if (imageType === 'profile') {
        setImgPerfil(url);
        setImgPerfilPreview(url);
        setProfileProgress(100); // Update progress to 100% after successful upload
      } else if (imageType === 'background') {
        setImgBack(url);
        setImgBackPreview(url);
        setBackgroundProgress(100); // Update progress to 100% after successful upload
      }
    } catch (error) {
      console.error(`Error uploading ${imageType} image:`, error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Error',
        text: `There was a problem uploading the ${imageType} image.`,
      });
      // Reset progress to 0% on error
      if (imageType === 'profile') setProfileProgress(0);
      if (imageType === 'background') setBackgroundProgress(0);
    }
  };

  const handleImageChange = (event, imageType) => {
    const file = event.target.files[0];
    if (file) {
      if (imageType === 'profile') setProfileProgress(50); // Start progress for profile
      if (imageType === 'background') setBackgroundProgress(50); // Start progress for background
      handleUpload(file, imageType);
    }
  };

  const triggerFileInput = (imageType) => {
    if (imageType === 'profile') {
      document.getElementById('profile-image-upload').click();
    } else if (imageType === 'background') {
      document.getElementById('back-image-upload').click();
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!allFieldsValid) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: true,
      });
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No user is signed in.',
      });
      return;
    }

    const dataUser = {
      imgBack: imgBack || 'defaultBackgroundImageUrl',
      imgPerfil: imgPerfil || 'defaultProfileImageUrl',
      nameCompany,
      city,
      descriptionCompany,
      phone,
      uid: user.uid,
    };

    try {
      await addDoc(collection(db, "company"), dataUser);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: `${nameCompany}'s data has been added.`,
        timer: 1500,
      });
      navigate("/SearchJob");
    } catch (error) {
      console.error("Error adding document to Firestore:", error);
      Swal.fire({
        icon: 'error',
        title: 'Database Error',
        text: 'Failed to add the data to the database.',
      });
    }
  };

  return (
<div className='backGraudCompany'>
  <div className='containerFormCompany'>
    <div className="progress-bar-container">
      <div className="progressSet" style={{ width: `${totalProgress}%` }}>
        {Math.round(totalProgress)}%
      </div>
    </div>

    <form onSubmit={handleAdd}  >
      <div className='form-layout'>
      <div className='conteCompany'>
  <div className="input-with-icon">
    <FaBuilding className="icon"/>
    <input
      type="text"
      className="inputCompañi"
      value={nameCompany}
      onChange={(e) => setNameCompany(e.target.value)}
      placeholder={t("Company Name")}
    />
  </div>

  <div className="input-with-icon">
    <FaCity className="icon"/>
    <input
      type="text"
      className="inputCompañi"
      value={city}
      onChange={(e) => setCity(e.target.value)}
      placeholder={t("City")}
    />
  </div>

  <div className="input-with-icon">
    <FaBriefcase className="icon"/>
    <input
      type="text"
      className="inputCompañi"
      value={descriptionCompany}
      onChange={(e) => setDescriptionCompany(e.target.value)}
      placeholder={t("Description Company")}
    />
  </div>

  <div className="input-with-icon">
    <FaPhone className="icon"/>
    <input
      type="text"
      className="inputCompañi"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      placeholder={t("Phone")}
    />
  </div>
</div>


        <div className="profile-container">
          <div className="background-image-container1" onClick={() => triggerFileInput('background')}>
            {imgBackPreview ? (
              <img src={imgBackPreview} alt="Background" className="background-image" />
            ) : (
              <div className="no-image-back">
                <FaImage className="icon79"/>
              </div>
            )}
            <input
              id="back-image-upload"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, 'background')}
              style={{ display: 'none' }}
            />
          </div>

          <div className="profile-image-container" onClick={() => triggerFileInput('profile')}>
            {imgPerfilPreview ? (
              <img src={imgPerfilPreview} alt="Profile" className="profile-image" />
            ) : (
              <div className="no-image-profile">
               <FaUser className="icon79" />
              </div>
            )}
            <input
              id="profile-image-upload"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, 'profile')}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>

     
      <div className="conButtonApply1">
        <button type="submit" disabled={!allFieldsValid} className="b798">
          <div className="t768">
            {t("Next")}
          </div>
        </button>    
      </div>  
    </form>
  </div>
</div>

  );
}

export default SetUpAccCompany;

*/

import React, { useState, useEffect } from "react";
import { storage, db, auth } from "../../firebase/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, query, where, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import './SetUpAccCompany.css';
import { useTranslation } from 'react-i18next';
import { FaPhone, FaCity, FaBuilding, FaBriefcase, FaUser, FaImage } from 'react-icons/fa';

function SetUpAccCompany() {
  const navigate = useNavigate();
  const [imgPerfil, setImgPerfil] = useState('');
  const [imgBack, setImgBack] = useState('');
  const [imgPerfilPreview, setImgPerfilPreview] = useState('');
  const [imgBackPreview, setImgBackPreview] = useState('');
  const [username, setUsername] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [city, setCity] = useState('');
  const [descriptionCompany, setDescriptionCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const [profileProgress, setProfileProgress] = useState(0);
  const [backgroundProgress, setBackgroundProgress] = useState(0);
  const [formProgress, setFormProgress] = useState(0); // Progress of input fields
  const [totalProgress, setTotalProgress] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
    // Calculate form progress based on the completion of input fields
    let completedFields = 0;
    if (username) completedFields++;
    if (nameCompany) completedFields++;
    if (city) completedFields++;
    if (descriptionCompany) completedFields++;
    if (phone) completedFields++;

    const formProgress = (completedFields / 5) * 100; // 4 input fields
    setFormProgress(formProgress);

    // Determine if all fields are valid
    const isValid = completedFields === 5;
    setAllFieldsValid(isValid);
  }, [username, nameCompany, city, descriptionCompany, phone]);

  useEffect(() => {
    // Calculate total progress based on both form and image upload progress
    const totalProgress = (formProgress + profileProgress + backgroundProgress) / 3;
    setTotalProgress(totalProgress); // Update the totalProgress state
  }, [formProgress, profileProgress, backgroundProgress]);

  const handleUpload = async (file, imageType) => {
    const fileRef = ref(storage, `FotosPerfilCompany/${uuidv4()}-${imageType}`);
    try {
      const snapshot = await uploadBytes(fileRef, file);
      const url = await getDownloadURL(snapshot.ref);
      if (imageType === 'profile') {
        setImgPerfil(url);
        setImgPerfilPreview(url);
        setProfileProgress(100); // Update progress to 100% after successful upload
      } else if (imageType === 'background') {
        setImgBack(url);
        setImgBackPreview(url);
        setBackgroundProgress(100); // Update progress to 100% after successful upload
      }
    } catch (error) {
      console.error(`Error uploading ${imageType} image:`, error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Error',
        text: `There was a problem uploading the ${imageType} image.`,
      });
      // Reset progress to 0% on error
      if (imageType === 'profile') setProfileProgress(0);
      if (imageType === 'background') setBackgroundProgress(0);
    }
  };

  const handleImageChange = (event, imageType) => {
    const file = event.target.files[0];
    if (file) {
      if (imageType === 'profile') setProfileProgress(50); // Start progress for profile
      if (imageType === 'background') setBackgroundProgress(50); // Start progress for background
      handleUpload(file, imageType);
    }
  };

  const triggerFileInput = (imageType) => {
    if (imageType === 'profile') {
      document.getElementById('profile-image-upload').click();
    } else if (imageType === 'background') {
      document.getElementById('back-image-upload').click();
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!allFieldsValid) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: true,
      });
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No user is signed in.',
      });
      return;
    }

    // Check if the username already exists in the Firestore collection
    const usernameQuery = query(collection(db, "company"), where("username", "==", username));
    const querySnapshot = await getDocs(usernameQuery);

    if (!querySnapshot.empty) {
      Swal.fire({
        icon: 'error',
        title: 'Username Taken',
        text: 'This username is already in use. Please choose a different one.',
      });
      return; // Prevent form submission
    }

    const usernameWithSuffix = `${username}@laboor`;

    const dataUser = {
      imgBack: imgBack || 'defaultBackgroundImageUrl',
      imgPerfil: imgPerfil || 'defaultProfileImageUrl',
      username: usernameWithSuffix,
      nameCompany,
      city,
      descriptionCompany,
      phone,
      uid: user.uid,
    };

    try {
      await addDoc(collection(db, "company"), dataUser);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: `${nameCompany}'s data has been added.`,
        timer: 1500,
      });
      navigate("/CompanyContact");
    } catch (error) {
      console.error("Error adding document to Firestore:", error);
      Swal.fire({
        icon: 'error',
        title: 'Database Error',
        text: 'Failed to add the data to the database.',
      });
    }
  };

  return (
    <div className='backGraudCompany'>

            <button onClick={handleGoBack}  className="goback78">
              <div className="g223g">
                 {t("go back")}
              </div>
            </button>    

      <div className='containerFormCompany'>
        <div className="progress-bar-container">
          <div className="progressSet" style={{ width: `${totalProgress}%` }}>
            {Math.round(totalProgress)}%
          </div>
        </div>

        <form onSubmit={handleAdd}  >

          <div className="c790">{t("Company Account")}</div>
          <div className='form-layout'>
            <div className='conteCompany'>
              <div className="input-with-icon">
                <FaUser className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder={t("Username")}
                />
              </div>

              <div className="input-with-icon">
                <FaBuilding className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={nameCompany}
                  onChange={(e) => setNameCompany(e.target.value)}
                  placeholder={t("Company Name")}
                />
              </div>

              <div className="input-with-icon">
                <FaCity className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder={t("City")}
                />
              </div>

              <div className="input-with-icon">
                <FaBriefcase className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={descriptionCompany}
                  onChange={(e) => setDescriptionCompany(e.target.value)}
                  placeholder={t("Description Company")}
                />
              </div>

              <div className="input-with-icon">
                <FaPhone className="icon"/>
                <input
                  type="text"
                  className="inputCompañi"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder={t("Phone")}
                />
              </div>
            </div>

        <div className="profile-container">
          <div className="background-image-container1" onClick={() => triggerFileInput('background')}>
            {imgBackPreview ? (
              <img src={imgBackPreview} alt="Background" className="background-image" />
            ) : (
              <div className="no-image-back">
                <FaImage className="icon79"/>
              </div>
            )}
            <input
              id="back-image-upload"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, 'background')}
              style={{ display: 'none' }}
            />
          </div>

          <div className="profile-image-container" onClick={() => triggerFileInput('profile')}>
            {imgPerfilPreview ? (
              <img src={imgPerfilPreview} alt="Profile" className="profile-image" />
            ) : (
              <div className="no-image-profile">
               <FaUser className="icon79" />
              </div>
            )}
            <input
              id="profile-image-upload"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, 'profile')}
              style={{ display: 'none' }}
            />
          </div>
        </div>
          </div>

          <div className="conButtonApply1">
          <button type="submit" disabled={!allFieldsValid} className="b798">
            <div className="t768">
              {t("Next")}
            </div>
          </button>    
        </div>    
                
        </form>
      </div>
    </div>
  );
}

export default SetUpAccCompany;
