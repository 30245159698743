
/*

import React, { useState } from 'react';
import { auth } from '../../firebase/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './SignIn.css';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Import icons

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    // Check if password length is less than 6 characters
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      localStorage.setItem('token', userCredential.user.accessToken);
      localStorage.setItem('user', JSON.stringify(userCredential.user));

      setIsAuthenticated(true);

      navigate("/TipeAccount");
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage('An account with this email already exists.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
      console.error(error);
    }
  }

  return (
    <div className='login343'>
      <h1 style={{ color: '#fff', marginBottom:30, }}>{t('Create Account')}</h1>
      {errorMessage && <p style={{ margin: 20, color: 'red' }}>{errorMessage}</p>}
      <form style={{ maxWidth: 400, margin: "auto" }} onSubmit={handleSubmit} className='signup-form'>
        
          <div className="input-field1">
            <input
              className="password-input"
              type="email"
              placeholder={t('Your Email')}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input-field1">
            <input
              className="password-input"
              type={showPassword ? "text" : "password"}
              placeholder={t('Your Password')}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="toggle-password"
            >
              {showPassword ? <FaEyeSlash style={{ fill: '#000' }} /> : <FaEye style={{ fill: '#000' }} />}
            </button>
          </div>

          <div className="conTercondi">
            <p className="texTercondi" style={{ color: '#999' }}>{t('By creating the account, you agree to the')}<Link style={{ color: '#999' }} to="/termsAndCondition"> {t('terms and conditions')}</Link></p>
          </div>

          <div className="conButtonApply">
            <button type="submit" className="glow-on-hover">
              <div className="texANOW">
                {t('Create')}
              </div>
            </button>
          </div>
       
      </form>

      <p style={{ color: '#fff' }}>{t('Need to Login?')} <Link style={{ color: '#9741F2' }} to="/login">{t('Login')}</Link></p>

      <p className="contUs"><Link style={{ color: '#999' }} to="/ContactUs">{t('Help')}</Link></p>
    </div>
  );
}

export default SignUp;


*/

import React, { useState } from 'react';
import { auth } from '../../firebase/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './SignIn.css';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Import icons

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    // Check if password length is less than 6 characters
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      localStorage.setItem('token', userCredential.user.accessToken);
      localStorage.setItem('user', JSON.stringify(userCredential.user));

      setIsAuthenticated(true);

      navigate("/TipeAccount");
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage('An account with this email already exists.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
      console.error(error);
    }
  }

  return (
    <div className='login343'>
      <h1 style={{ color: '#fff', marginBottom:30, }}>{t('Create Account')}</h1>
      {errorMessage && <p style={{ margin: 20, color: 'red' }}>{errorMessage}</p>}
      <form style={{ maxWidth: 400, margin: "auto" }} onSubmit={handleSubmit} className='signup-form'>
        
          <div className="input-field1">
            <input
              className="password-input"
              type="email"
              placeholder={t('Your Email')}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input-field1 input-pass-cont">
              <input
                className="password-input"
                type={showPassword ? "text" : "password"}
                placeholder={t('Your Password')}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="toggle-password"
              >
                {showPassword ? <FaEyeSlash style={{ fill: '#000' }} /> : <FaEye style={{ fill: '#000' }} />}
              </button>
          </div>


          <div className="conTercondi">
            <p className="texTercondi" style={{ color: '#999' }}>{t('By creating the account, you agree to the')}<Link style={{ color: '#999' }} to="/termsAndCondition"> {t('terms and conditions')}</Link></p>
          </div>

          <div className="conButtonApply">
            <button type="submit" className="glow-on-hover">
              <div className="texANOW">
                {t('Create')}
              </div>
            </button>
          </div>
       
      </form>

      <p style={{ color: '#fff' }}>{t('Need to Login?')} <Link style={{ color: '#9741F2' }} to="/login">{t('Login')}</Link></p>

      <p className="contUs"><Link style={{ color: '#444' }} to="/ContactUs">{t('Help')}</Link></p>
    </div>
  );
}

export default SignUp;
