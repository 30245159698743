/* 


import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore'; 
import { db } from '../../../firebase/firebase';
import BuscadorFeed from '../BuscadorFeed';  
import QueryFeed from '../queryFeed/queryFeed';
import Sidenav from '../../../components/navigation/Sidenav';
import './bodyBuscar.css';
import QueryFeedTitle from '../queryFeedTitle/queryFeedTitle';
import QueryFeedUser from '../queryFeedUser/queryFeedUser';
import QueryCity from '../queryCity/queryCity';
import QueryDescription from '../queryDescription/queryDescription';
import QueryName from '../queryName/queryName';
import QueryLastName from '../queryLastName/queryLastName';
import QueryNameCompany from '../queryNameCompany/queryNameCompany';
import Numero from '../numero/numero'
import NumeroCompany from '../numeroCompany/numeroCompany'
import EtiquetasJobs from '../etiquetasJobs/etiquetasJobs';
import { FaSearch } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from "re-resizable";
import PostProfiles from '../../../../src/pages/postProfiles/postProfiles';
import MyComponent from '../../../components/comSearch/comSearch';
import PositionJob from '../positionJob/positionJob'
import CompanyaAddress from '../cmAddress/companyaAddress'
import CmWebsite from '../cmWebsite/cmWebsite'
import CmEmail from '../cmEmail/cmEmail'
import CmTagline from '../cmTagline/cmTagline'
import CmDescri from '../cmDescri/cmDescri'
import CdFoundedDate from '../cdFoundedDate/cdFoundedDate'
import CmPhone from '../cmPhone/cmPhone'

import UserAge from  '../UserAge/UserAge'
import UserPhone from '../userPhone/userPhone'
import UserEmail from '../UserEmail/UserEmail'
import UserGender from '../userGender/userGender'
import UserNationality from '../userNationality/userNationality'

import UserFullName from '../userFullName/userFullName'
import UserWorkExperience from '../userWorkExperience/userWorkExperience'

function BodyBuscar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [uids, setUids] = useState([]);
    const [employeesPostData, setEmployeesPostData] = useState([]);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [noResults, setNoResults] = useState(false);
    const [noResultsCount, setNoResultsCount] = useState(0);
    const [searchHistory, setSearchHistory] = useState([]);

    // Function to save search term to Firestore
    const saveSearchTermToFirestore = async (term) => {
        try {
            const q = query(collection(db, 'history'));
            const querySnapshot = await getDocs(q);
            let termExists = false;
            let termDocId = null;
    
            querySnapshot.forEach((docSnapshot) => {
                const data = docSnapshot.data();
                if (data.term === term) {
                    termExists = true;
                    termDocId = docSnapshot.id;
                }
            });
    
            if (termExists) {
                // Increment the counter if the term already exists
                const termDocRef = doc(db, 'history', termDocId);
                const termDocSnapshot = await getDoc(termDocRef);
                const currentData = termDocSnapshot.data();
    
                await updateDoc(termDocRef, {
                    count: currentData.count ? currentData.count + 1 : 2, // Increment or set to 2 if count doesn't exist
                    timestamp: new Date() // Update timestamp
                });
            } else {
                // Add new term if it doesn't exist
                await addDoc(collection(db, 'history'), {
                    term: term,
                    count: 1,
                    timestamp: new Date() // Store with timestamp
                });
            }
    
        } catch (e) {
            console.error('Error adding or updating document:', e);
        }
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        saveSearchTermToFirestore(term); // Save search term to Firestore
    };

    useEffect(() => {
        const fetchUidsAndEmployeesPostData = async () => {
            const uidsArray = [];
            const employeesPostDataArray = [];
    
            const q = query(collection(db, 'employeesPost'));
            const querySnapshot = await getDocs(q);
    
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.uid) {
                    uidsArray.push(data.uid);
                    employeesPostDataArray.push({ id: doc.id, ...data });  // Add the doc.id to the data object
                }
            });
            setUids(uidsArray);
            setEmployeesPostData(employeesPostDataArray);
        };
    
        fetchUidsAndEmployeesPostData();
    }, []);

    useEffect(() => {
        setNoResultsCount(0);
    }, [searchTerm]);

    return (
        <div className="con-chido-query" style={{   height: '100vh' }}>
            {isMobile ? (
                <div className="chido_bus-2">
                    <Sidenav />
                </div>
            ) : (
                <Resizable
                    className="chido_bus-2"
                    defaultSize={{ width: '30%', height: '100%' }}
                    minWidth="20%"
                    maxWidth="70%"
                    enable={{ right: true }}
                >
                    <Sidenav />
                </Resizable>
            )}
            
            <div className="chido-buscador-mind" style={{ flex: 1 }}>
                <BuscadorFeed onSearch={handleSearch} />
                
                
                {searchTerm ? (
                       <div className="query-feed-container">
                        <QueryFeed uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryFeedTitle uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryFeedUser uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryCity uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryDescription uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryName uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryLastName uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryNameCompany uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryFeedTitle uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <EtiquetasJobs uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        
                        <Numero  uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/> 
                        <NumeroCompany uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/> 
                        <CmPhone uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>

                        <PositionJob uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CompanyaAddress uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmWebsite uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmEmail uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmTagline uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmDescri uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CdFoundedDate uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        
                        <UserAge uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserPhone uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserEmail uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserGender uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserNationality uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserFullName uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserWorkExperience uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        </div>
                ) : (<>
                    <div className="horizontal-scroll-container">
                        <PostProfiles uids={uids} employeesPostData={employeesPostData} />
                    </div>
                    <div>
                        <MyComponent uids={uids} employeesPostData={employeesPostData} />
                    </div>
                </>
                )}

                {noResultsCount === 8 && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <FaSearch size={50} />  
                    <p className="noFind23" style={{ marginTop: '10px', textAlign: 'center' }}>Uh-oh, no results found!</p>
                  </div>
                )}
            </div>
        </div>
    );
}

export default BodyBuscar;


 


*/


import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore'; 
import { db } from '../../../firebase/firebase';
import BuscadorFeed from '../BuscadorFeed';  
import QueryFeed from '../queryFeed/queryFeed';
import Sidenav from '../../../components/navigation/Sidenav';
import './bodyBuscar.css';
import QueryFeedTitle from '../queryFeedTitle/queryFeedTitle';
import QueryFeedUser from '../queryFeedUser/queryFeedUser';
import QueryCity from '../queryCity/queryCity';
import QueryDescription from '../queryDescription/queryDescription';
import QueryName from '../queryName/queryName';
import QueryLastName from '../queryLastName/queryLastName';
import QueryNameCompany from '../queryNameCompany/queryNameCompany';
import Numero from '../numero/numero'
import NumeroCompany from '../numeroCompany/numeroCompany'
import EtiquetasJobs from '../etiquetasJobs/etiquetasJobs';
import { FaSearch } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from "re-resizable";
import PostProfiles from '../../../../src/pages/postProfiles/postProfiles';
import MyComponent from '../../../components/comSearch/comSearch';
import PositionJob from '../positionJob/positionJob'
import CompanyaAddress from '../cmAddress/companyaAddress'
import CmWebsite from '../cmWebsite/cmWebsite'
import CmEmail from '../cmEmail/cmEmail'
import CmTagline from '../cmTagline/cmTagline'
import CmDescri from '../cmDescri/cmDescri'
import CdFoundedDate from '../cdFoundedDate/cdFoundedDate'
import CmPhone from '../cmPhone/cmPhone'

import UserAge from  '../UserAge/UserAge'
import UserPhone from '../userPhone/userPhone'
import UserEmail from '../UserEmail/UserEmail'
import UserGender from '../userGender/userGender'
import UserNationality from '../userNationality/userNationality'

import UserFullName from '../userFullName/userFullName'
import UserWorkExperience from '../userWorkExperience/userWorkExperience'


function BodyBuscar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [uids, setUids] = useState([]);
    const [employeesPostData, setEmployeesPostData] = useState([]);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [noResults, setNoResults] = useState(false);
    const [noResultsCount, setNoResultsCount] = useState(0);
    const [searchHistory, setSearchHistory] = useState([]);

    // Function to save search term to Firestore
    const saveSearchTermToFirestore = async (term) => {
        try {
            const q = query(collection(db, 'history'));
            const querySnapshot = await getDocs(q);
            let termExists = false;
            let termDocId = null;
    
            querySnapshot.forEach((docSnapshot) => {
                const data = docSnapshot.data();
                if (data.term === term) {
                    termExists = true;
                    termDocId = docSnapshot.id;
                }
            });
    
            if (termExists) {
                // Increment the counter if the term already exists
                const termDocRef = doc(db, 'history', termDocId);
                const termDocSnapshot = await getDoc(termDocRef);
                const currentData = termDocSnapshot.data();
    
                await updateDoc(termDocRef, {
                    count: currentData.count ? currentData.count + 1 : 2, // Increment or set to 2 if count doesn't exist
                    timestamp: new Date() // Update timestamp
                });
            } else {
                // Add new term if it doesn't exist
                await addDoc(collection(db, 'history'), {
                    term: term,
                    count: 1,
                    timestamp: new Date() // Store with timestamp
                });
            }
    
        } catch (e) {
            console.error('Error adding or updating document:', e);
        }
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        saveSearchTermToFirestore(term); // Save search term to Firestore
    };

    useEffect(() => {
        const fetchUidsAndEmployeesPostData = async () => {
            const uidsArray = [];
            const employeesPostDataArray = [];
    
            const q = query(collection(db, 'employeesPost'));
            const querySnapshot = await getDocs(q);
    
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.uid) {
                    uidsArray.push(data.uid);
                    employeesPostDataArray.push({ id: doc.id, ...data });  // Add the doc.id to the data object
                }
            });
            setUids(uidsArray);
            setEmployeesPostData(employeesPostDataArray);
        };
    
        fetchUidsAndEmployeesPostData();
    }, []);

    useEffect(() => {
        setNoResultsCount(0);
    }, [searchTerm]);

    return (
        <div className="con-chido-query" style={{   height: '100vh' }}>
            {isMobile ? (
                <div className="chido_bus-2">
                    <Sidenav />
                </div>
            ) : (
                <Resizable
                    className="chido_bus-2"
                    defaultSize={{ width: '30%', height: '100%' }}
                    minWidth="20%"
                    maxWidth="70%"
                    enable={{ right: true }}
                >
                    <Sidenav />
                </Resizable>
            )}
            
            <div className="chido-buscador-mind" style={{ flex: 1 }}>
                <BuscadorFeed onSearch={handleSearch} />
                
                
                {searchTerm ? (
                       <div className="query-feed-container">
                        <QueryFeed uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryFeedTitle uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryFeedUser uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryCity uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryDescription uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryName uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} /> 
                        <QueryLastName uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <QueryNameCompany uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <EtiquetasJobs uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount} />
                        <NumeroCompany uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/> 
                        <CompanyaAddress uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>                   
                        <CmPhone uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <Numero  uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/> 
                        <PositionJob uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmWebsite uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmEmail uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmTagline uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CmDescri uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <CdFoundedDate uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserPhone uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserAge uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserEmail uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserGender uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserNationality uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserFullName uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        <UserWorkExperience uids={uids} employeesPostData={employeesPostData} searchTerm={searchTerm} setNoResultsCount={setNoResultsCount}/>
                        </div>
                ) : (<>
                    <div className="horizontal-scroll-container">
                        <PostProfiles uids={uids} employeesPostData={employeesPostData} />
                    </div>
                    <div>
                        <MyComponent uids={uids} employeesPostData={employeesPostData} />
                    </div>
                </>
                )}

                {noResultsCount === 8 && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <FaSearch size={50} />  
                 
                  </div>
                )}
            </div>
        </div>
    );
}

export default BodyBuscar;

/*            
             
               
                

*/