import React, { useState, useEffect, useCallback } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import ProfileImage from '../profileImage/profileImage';
import { useTranslation } from 'react-i18next';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';

// Function to calculate Levenshtein distance
function levenshteinDistance(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                );
            }
        }
    }

    return matrix[b.length][a.length];
}

function UserGender({ searchTerm, uids, employeesPostData, setNoResultsCount }) {
    const [searchResults, setSearchResults] = useState([]);
    const [user, loading, error] = useAuthState(auth);
    const [currentUid, setCurrentUid] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const {t} = useTranslation();

  const navigate = useNavigate();

    const openModal = useCallback((uid) => {
        if (user) {
          setSelectedUserId(uid);
          setModalOpen(true);
        } else {
          navigate('/SignUp');
        }
      }, [user, navigate]);
    
    

    useEffect(() => {
        // Set currentUid state when user object changes
        if (user) {
            setCurrentUid(user.uid);
        } else {
            setCurrentUid(null);
        }
    }, [user]);

    const handleNavigateToProfile = useCallback((uid) => {
        navigate(`/Perfil/${uid}`);
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'userCv'));
            const querySnapshot = await getDocs(q);
            const results = [];
        
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.gender) { // Check if inputText is defined
                    const gender = data.gender.toLowerCase();
                    const similarity = (1 - levenshteinDistance(searchTerm.toLowerCase(), gender) / Math.max(searchTerm.length, gender.length)) * 100;
                    if (similarity >= 100) {
                        results.push(data);
                    }
                }
            });
            setSearchResults(results);
            if (results.length === 0) {
                setNoResultsCount(count => count + 1);
            }
        };  
        fetchData();
    }, [searchTerm]);

    return (
        <>
            {searchResults.length > 0 ? (
                <div className="container-query-maind">
                    {searchResults.map((result, index) => (
                        <div className="item-query" key={index}>
                            <div className="cityMONUNcon3">
                                <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(result?.uid)}>
                                    <ProfileImage queryPost={result?.uid} currentUid={currentUid} />
                                </button>
                            </div>
    
                            <div className="Weeklyt">
                                {result.workExperience}
                            </div>
    
                            <div className="cityMONUNcon">
                                <div className="aaa55m">
                                    <span className="Weeklyt">Gender</span>
                                    <div className="Weeklyt">
                                        {result.gender}
                                    </div>
                                </div>
    
                                <div className="CCS4">
                                    <SendMessage uidThePost={result.uid} currentUid={currentUid} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
    
}

export default  UserGender;