// Modal.js
import React, { useRef, useEffect } from 'react';
import './Modal.css';

function Modal({ showModal, onClose, children }) {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div className="content55" ref={modalRef}>
        <button className="close-button" onClick={onClose}>
          close
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;

