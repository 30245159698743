/*
import React from 'react';
import { db } from '../../../../../firebase/firebase';
import { collection, addDoc, getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';

async function sendMessage({ uidThePost, currentUid, inputMessage, setInputMessage }) {
  try {
    // Create a chat ID by sorting and joining the user IDs
    const chatId = [currentUid, uidThePost].sort().join('_');

    const chatDocRef = doc(db, 'chats', chatId);
    const chatDoc = await getDoc(chatDocRef);
    if (!chatDoc.exists()) {
      // If a document doesn't exist, create a new one
      await setDoc(chatDocRef, {
        createdBy: currentUid,
        SendTo: uidThePost,
        createdAt: serverTimestamp(),
      });
    }

    // Add the message to the 'conversacion' subcollection
    await addDoc(collection(chatDocRef, 'conversacion'), {
      sentBy: currentUid,
      Text: inputMessage,
      timestamp: serverTimestamp(),
    });

    setInputMessage(''); // Clear input after sending
  } catch (error) {
    console.error('Error sending message:', error);
  }
}

function SendMessage({ uidThePost, currentUid, inputMessage, setInputMessage }) {
  const handleSendMessage = async () => {
    await sendMessage({ uidThePost, currentUid, inputMessage, setInputMessage });
  };

  return (
    <>
      <button className="glow-on-hover" onClick={handleSendMessage}>
         <div className="texANOW">APPLY NOW</div>
      </button>
    </>
  );
}

export default SendMessage;
*/
import React from 'react';
import { db, auth } from '../../../../../firebase/firebase';
import { collection, addDoc, getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

async function sendMessage({ uidThePost, currentUid, inputMessage, setInputMessage }) {
  try {
    // Create a chat ID by sorting and joining the user IDs
    const chatId = [currentUid, uidThePost].sort().join('_');

    const chatDocRef = doc(db, 'chats', chatId);
    const chatDoc = await getDoc(chatDocRef);


    if (!chatDoc.exists()) {
      // If a document doesn't exist, create a new one
      await setDoc(chatDocRef, {
        createdBy: currentUid,
        SendTo: uidThePost,
        createdAt: serverTimestamp(),
      });
    }

    // Add the message to the 'conversacion' subcollection
    await addDoc(collection(chatDocRef, 'conversacion'), {
      sentBy: currentUid,
      Text: inputMessage,
      timestamp: serverTimestamp(),
    });

    setInputMessage(''); // Clear input after sending
  } catch (error) {
    console.error('Error sending message:', error);
  }
}

function SendMessage({ uidThePost, currentUid, inputMessage, setInputMessage }) {
    const navigate = useNavigate(); // Change this line
    const {t} = useTranslation();
  
    const handleSendMessage = async () => {
      if (!auth.currentUser) {
        // If the user is not authenticated, navigate to the signup page
        navigate('/signup');
        return;
      }


      await sendMessage({ uidThePost, currentUid, inputMessage, setInputMessage });
      if (window.innerWidth <= 768) {
        navigate('/chat'); // Navigate to /chat on mobile devices
      }
    };


  return (
    <>
      <button className="glow-on-hover" onClick={handleSendMessage}>
         <div className="texANOW">{t('Apply Now')}</div>
      </button>
    </>
  );
}

export default SendMessage;






