import './SignUp.css'
import SignUp from "../components/auth/SignUp";
 
const Add = () => {
  
  return (
    <div className='login343'> 
      <div className='headerBlock__infoText'>
        <div className='contenedorForm'>
        
          <SignUp/>            
                  
          </div>       
     </div>    
   </div>
  );
};

export default Add;