import React from 'react';
import { FiWifiOff } from 'react-icons/fi'; // Import the WiFiOff icon
import './NoWifi.css';

function NoWifi() {
  return (
    <div className="no-wifi-container">
      <FiWifiOff className="no-wifi-icon" />
      <p>No Internet Connection</p>
    </div>
  );
}

export default NoWifi;
