import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase'; // Import your Firebase configuration
import PerfilUser from '../../../../src/pages/pagesUser/PerfilUser';
import SuscriptionOffer from '../../../../src/pages/payments/suscriptionOffer/suscriptionOffer';
import { IoIosAdd } from "react-icons/io"; // Importing the icon from react-icons
import './verificationProfile.css'; // Import your CSS file

function VerificationProfile() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      // Reset state before fetching new data
      setFormData(null);
      setFormType(null);
      setLoading(true);

      const currentUid = auth.currentUser.uid; // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        setLoading(false);
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        setLoading(false); // Set loading to false after data is fetched
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        setLoading(false); // Set loading to false after data is fetched
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchData();

    // Cleanup function to reset state when the component unmounts
    return () => {
      setFormData(null);
      setFormType(null);
      setLoading(true);
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <IoIosAdd size={50} className="loading-icon" />
      </div>
    );
  }

  return (
    <div>
      {formType === null && <SuscriptionOffer />}
      {formType === 'company' && <PerfilUser />}
      {formType === 'infoUser' && <PerfilUser />}
    </div>
  );
}

export default VerificationProfile;

