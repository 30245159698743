/*


import React, { useState, useEffect } from 'react';
import { auth } from '../firebase/firebase'; // Make sure this path is correct for your project structure
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './TipeAccount.css';
import { useTranslation } from 'react-i18next';

function TipeAccount() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  useEffect(() => {
    // Subscribe to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // No user is signed in, redirect to login
        navigate('/Login');
      }
    });

    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, [navigate]);

  function goSetUpAccount() {
    navigate("/SetUpAccount");
  }

  function goCompanyAccount() {
    navigate("/CompanyAccount");
  }

  function toggleModal(modalType) {
    if (modalType === 'job') {
      setIsJobModalOpen(!isJobModalOpen);
    } else if (modalType === 'company') {
      setIsCompanyModalOpen(!isCompanyModalOpen);
    }
  }

  return (
    <div className="containerTipeacount">
      <div className="column column-white">
        <button onClick={() => toggleModal('job')} className="futuristic-button-pulse1">
          <text className="texLookingJob">
            {t("Are you looking for a job?")}
          </text>
        </button>
      </div>

      <div className="column column-black">
        <button onClick={() => toggleModal('company')} className="texCompany">
          {t("You are looking for personnel to work?")}
        </button>
      </div>

      {isJobModalOpen && (
        <div className="modal" onClick={() => toggleModal('job')}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => toggleModal('job')}>×</span>
            <h2 >{t("Job Seeker Account")}</h2>
            <p>{t("As a job seeker, you can create")}</p>
            <button onClick={goSetUpAccount} className="modal-button">
              {t("Go to Set Up Account")}
            </button>
          </div>
        </div>
      )}

      {isCompanyModalOpen && (
        <div className="modal" onClick={() => toggleModal('company')}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => toggleModal('company')}>×</span>
            <h2>{t("Company Account")}</h2>
            <p>{t("As a company, you can post job")}</p>
            <button onClick={goCompanyAccount} className="modal-button">
              {t("Go to Company Account")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TipeAccount;


*/
import React, { useState, useEffect } from 'react';
import { auth } from '../firebase/firebase'; // Make sure this path is correct for your project structure
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './TipeAccount.css';
import { useTranslation } from 'react-i18next';

import CompanyImage from '../assets/empresa.png';
import UserImage from '../assets/userImage.png';

function TipeAccount() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  useEffect(() => {
    // Subscribe to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // No user is signed in, redirect to login
        navigate('/Login');
      }
    });

    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, [navigate]);

  function goSetUpAccount() {
    navigate("/SetUpAccount");
  }

  function goCompanyAccount() {
    navigate("/CompanyAccount");
  }

  function toggleModal(modalType) {
    if (modalType === 'job') {
      setIsJobModalOpen(!isJobModalOpen);
    } else if (modalType === 'company') {
      setIsCompanyModalOpen(!isCompanyModalOpen);
    }
  }

  return (
    <div className="containerTipeacount">
      <div className="column column-white">
        <button onClick={() => toggleModal('job')} className="futuristic-button-pulse1">
          <text className="texLookingJob">
            {t("Are you looking for a job?")}
          </text>
        </button>
      </div>

      <div className="column column-black">
        <button onClick={() => toggleModal('company')} className="texCompany">
          {t("You are looking for personnel to work?")}
        </button>
      </div>

      {isJobModalOpen && (
        <div className="modal" onClick={() => toggleModal('job')}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => toggleModal('job')}>×</span>

            <img src={UserImage} alt="Company" className="company-image" />

            <h2 >{t("Job Seeker Account")}</h2>
            <p>{t("As a job seeker, you can create")}</p>
            <button onClick={goSetUpAccount} className="modal-button">
              {t("Go to Set Up Account")}
            </button>
          </div>
        </div>
      )}

      {isCompanyModalOpen && (
        <div className="modal" onClick={() => toggleModal('company')}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => toggleModal('company')}>×</span>

            <img src={CompanyImage} alt="Company" className="company-image" />

            <h2>{t("Company Account")}</h2>
            <p>{t("As a company, you can post job")}</p>
            <button onClick={goCompanyAccount} className="modal-button">
              {t("Go to Company Account")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TipeAccount;
