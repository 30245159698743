/*



import React, { useState, useEffect } from 'react';
import './compPerfilUser.css';
import { auth } from '../../firebase/firebase';  
import { collection, getDocs, where, query, doc, updateDoc  } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import { GrMore } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './CartaPerfilUser.css'
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { MdMoreHoriz } from "react-icons/md";

import CountFollow from './countFollow/countFollow'

import Follow from '../../../src/pages/Follow/Follow';

function CartaPerfilUser({ handleEdit }) {
  const [val, setVal] = useState([]);
  const { uid } = useParams(); // Extract UID from the path
  const currentUser = auth.currentUser; // Get the current user from Firebase Auth

  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (uid) {
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const q2 = query(collection(db, 'company'), where('uid', '==', uid));

        try {
          const querySnapshot1 = await getDocs(q1);
          const querySnapshot2 = await getDocs(q2);
          
          let userData = {};
          let companyData = {};

          querySnapshot1.forEach(doc => {
            userData = doc.data();
          });

          querySnapshot2.forEach(doc => {
            companyData = doc.data();
          });

          // Combining data from both collections
          const combinedData = { ...userData, ...companyData };

          setVal([combinedData]); // Assuming you want to maintain the array structure
        } catch (error) {
          console.error('Error getting user data:', error);
        }
      }
    };

    getData();
  }, [uid]);

  function goQuery() {
    navigate(`/EditAccount`);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
  
    const userDoc = doc(db, 'infoUser', uid); // Replace 'infoUser' with the correct collection name
    await updateDoc(userDoc, {
      imgPerfil: fileURL
    });
  
    setSelectedFile(fileURL);
  };

  return (
    <div className="profiles-containe5">
      {val.map(values => (
        <div className="twitter-profile" key={values.uid}>
          {currentUser && currentUser.uid === uid && (
            <button onClick={goQuery}
              className="buttlogoPro">
              <MdMoreHoriz className="search-icon" size={32} />
            </button>
          )}

      <div className="background-image-container">
        {values.imgBack ? (
          <div 
          style={{
            width: '100%',
            height: '200px',
            backgroundImage: values.imgBack
              ? `url(${values.imgBack})`
              : `url('default_background_image.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
              }}
              
                />
              ) : (
                <div className="background-placeholder">
                  <span className="background-placeholder-icon">
                  
                  </span>
                  <p>No image available</p>
                </div>
              )}
            </div>

            <div className="profile-image5">
              {values.imgPerfil ? (
                <img 
                  className="conPimg" 
                  src={selectedFile || values.imgPerfil} 
                  alt="Profile" 
                  onError={(e) => { e.target.onerror = null; e.target.src="default_profile_icon.png"; }}
                />
              ) : (
                <div className="no-profile-placeholder">
                <FaUser size={64} className="placeholder-icon" />
                <Follow/>
              </div>
              )}
              <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange} />
            </div>

          <div className="profile-details">
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className="contProfileT"> 
                {values.nameCompany && (
                  <div className="profile-companyName">{values.nameCompany}</div>
                )}
                {values.firstName && (
                  <div className="profile-companyName">{values.firstName}</div>
                )}
                {values.city && (
                  <div className="profile-city-tex">@{values.city}</div>
                )}
                {values.username && (
                  <div className="use32">{values.username}</div>
                )}
                <div className="mobile-only">
                     <CountFollow  userUid={values.uid}/> 
                </div>
              </div>

              <div className="profile-container-names">
                {values.positionJob && (
                  <div className="descri">{values.positionJob}</div>
                )}
                {values.descriptionSkills && (
                  <p className="descri">{values.descriptionSkills}</p>
                )}
                {values.descriptionCompany && (
                  <p className="positionTitle">{values.descriptionCompany}</p>
                )}
                  
              </div>
              
            </div>
            <div className="pc-only">
              <CountFollow userUid={values.uid} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CartaPerfilUser;



*/
import React, { useState, useEffect } from 'react';
import './compPerfilUser.css';
import { auth } from '../../firebase/firebase';  
import { collection, getDocs, where, query, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import { GrMore } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './CartaPerfilUser.css';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { MdMoreHoriz } from "react-icons/md";

import CountFollow from './countFollow/countFollow';
import Follow from '../../../src/pages/Follow/Follow';

function CartaPerfilUser({ handleEdit }) {
  const [val, setVal] = useState([]);
  const { uid } = useParams(); // Extract UID from the path
  const currentUser = auth.currentUser; // Get the current user from Firebase Auth
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (uid) {
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const q2 = query(collection(db, 'company'), where('uid', '==', uid));

        try {
          const querySnapshot1 = await getDocs(q1);
          const querySnapshot2 = await getDocs(q2);
          
          let userData = {};
          let companyData = {};

          querySnapshot1.forEach(doc => {
            userData = doc.data();
          });

          querySnapshot2.forEach(doc => {
            companyData = doc.data();
          });

          // Combining data from both collections
          const combinedData = { ...userData, ...companyData };

          setVal([combinedData]); // Assuming you want to maintain the array structure
        } catch (error) {
          console.error('Error getting user data:', error);
        }
      }
    };

    getData();
  }, [uid]);

  function goQuery() {
    navigate(`/EditAccount`);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
  
    const userDoc = doc(db, 'infoUser', uid); // Replace 'infoUser' with the correct collection name
    await updateDoc(userDoc, {
      imgPerfil: fileURL
    });
  
    setSelectedFile(fileURL);
  };

  // Function to handle follow toggle (for any custom behavior you want to implement)
  const handleFollowToggle = (followeeUid, isFollowed) => {
    console.log(`Follow status changed for ${followeeUid}: ${isFollowed}`);
    // You can add custom behavior here, like updating state or sending analytics
  };

  return (
    <div className="profiles-containe5">
      {val.map(values => (
        <div className="twitter-profile" key={values.uid}>
          {currentUser && currentUser.uid === uid && (
            <button onClick={goQuery} className="buttlogoPro">
              <MdMoreHoriz className="search-icon" size={32} />
            </button>
          )}

          <div className="background-image-container">
            {values.imgBack ? (
              <div 
                style={{
                  width: '100%',
                  height: '200px',
                  backgroundImage: values.imgBack
                    ? `url(${values.imgBack})`
                    : `url('default_background_image.png')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }}
              />
            ) : (
              null
            )}
          </div>

          <div className="profile-image5">
            {values.imgPerfil ? (
              <img 
                className="conPimg" 
                src={selectedFile || values.imgPerfil} 
                alt="Profile" 
                onError={(e) => { e.target.onerror = null; e.target.src="default_profile_icon.png"; }}
              />
            ) : (
              <div className="no-profile-placeholder">
                <FaUser size={40} className="placeholder-icon" />
              </div>
            )}
            <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange} />
          </div>

          <div className="mobile-only">
              <CountFollow userUid={values.uid}/>
          </div>

          <div className="profile-details">
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className="contProfileT"> 
                {values.nameCompany && (
                  <div className="profile-companyName">{values.nameCompany}</div>
                )}
                {values.firstName && (
                  <div className="profile-companyName">{values.firstName}</div>
                )}
                {values.city && (
                  <div className="profile-city-tex">@{values.city}</div>
                )}
                {values.username && (
                  <div className="use32">{values.username}</div>
                )}    
            </div>
          </div>

            <div className="profile-container-names">
                {values.positionJob && (
                  <div className="descri">{values.positionJob}</div>
                )}
                {values.descriptionSkills && (
                  <p className="descri">{values.descriptionSkills}</p>
                )}
                {values.descriptionCompany && (
                  <p className="positionTitle">{values.descriptionCompany}</p>
                )}
              </div>

            <div className="pc-only">
              <CountFollow userUid={values.uid} />
            </div>

            {currentUser && currentUser.uid !== values.uid && (
              <div className="follow-container"> {/* Added container */}
                <Follow
                  followerUid={currentUser.uid} // Current user UID
                  followeeUid={values.uid}      // Profile user UID
                  initialFollowed={false}       // You can dynamically set this based on the follow status
                  onFollowToggle={handleFollowToggle}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CartaPerfilUser;
