import React, { useState, useEffect } from 'react';
import { db } from '../../../../src/firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FaPhone, FaEnvelope, FaGlobe, FaBuilding, FaCity, FaMoneyBillAlt } from 'react-icons/fa';
import './CompanyCv.css';

function CompanyCv({ uid }) {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, 'CompanyCv'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setCompanyData(doc.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!companyData) {
    return null;
  }

  return (
    <div className="company-cv-container435">
      <div className="company-cv-content435">
        
        <div className="company-cv-panel435 section-1">
          <FaBuilding className="header-icon" />
          <p className="cv-text"><strong>tagline:</strong></p>
          <p className="cv-text">{companyData.tagline || 'Company Information'}</p>
        </div>

        <div className="company-cv-panel435 section-1">
          <FaCity className="detail-icon435" />
          <p className="cv-text"><strong>City:</strong></p>
          <p className="cv-text">{companyData.addresses?.join(', ')}</p>
        </div>
        
        <div className="company-cv-panel435 section-1">
          <FaPhone className="detail-icon435" />
          <p className="cv-text"><strong>Phone:</strong></p>
          <p className="cv-text">{companyData.phone}</p>
        </div>
        
        <div className="company-cv-panel435 section-1">
          <FaEnvelope className="detail-icon435" />
          <p className="cv-text"><strong>Email:</strong></p>
          <p className="cv-text">{companyData.email}</p>
        </div>
        
        <div className="company-cv-panel435 section-2">
          <FaMoneyBillAlt className="detail-icon" />
          <p className="cv-text"><strong className="minTwhite">Founded Date:</strong></p>
          <p className="cv-text DTwhite">{companyData.foundedDate}</p>
        </div>
        
        <div className="company-cv-panel435 section-2" onClick={() => window.open(companyData.website, '_blank')}>
          <FaGlobe className="detail-icon" />
          <p className="cv-text DTwhite"><strong>Website</strong></p>
        </div>
        
        <div className="company-cv-panel435 section-3">
          <p className="cv-text minTwhite"><strong>Description:</strong></p>
          <p className="cv-text DTwhite">{companyData.description}</p>
       </div>  
      </div>
    </div>
  );
}

export default CompanyCv;

