/*
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Firebase imports
import { collection, getDocs, doc, deleteDoc, updateDoc, query, where } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Component imports
import Header from './Header';
import Table from './Table';
import Add from './Add';
import Edit from './Edit';

import Sidenav from '../../../src/components/navigation/Sidenav';

const Dashboard = ({ setIsAuthenticated }) => {

 

  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userUid, setUserUid] = useState(null);

  const auth = getAuth();

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUid(user.uid);
        getEmployeesAndCompanies(user.uid);
      } else {
        setUserUid(null);
        setEmployees([]);
        setCompanies([]);
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, [auth, setIsAuthenticated]);

  const getEmployeesAndCompanies = async (uid) => {
    const employeesRef = collection(db, "employeesPost");
    const companyRef = collection(db, "company");

    const employeesQuery = query(employeesRef, where("uid", "==", uid));
    const companyQuery = query(companyRef, where("uid", "==", uid));

    try {
      const employeesSnapshot = await getDocs(employeesQuery);
      const employees = employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employees);

      const companiesSnapshot = await getDocs(companyQuery);
      const companies = companiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCompanies(companies);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };


  
  const handleEdit = async (id) => {
    const [employee] = employees.filter((employee) => employee.id === id);

    const employeeDocRef = doc(db, "employeesPost", id);

    try {
      await updateDoc(employeeDocRef, {
        uid: userUid,
        firstName: "New First Name",
        lastName: "New Last Name",
      });

      setSelectedEmployee(employee);
      setIsEditing(true);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
  // ... existing handleEdit, handleDelete, etc.

  
  const handleDelete = id => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(result => {
      if (result.value) {
        const [employee] = employees.filter(employee => employee.id === id);

        deleteDoc(doc(db, "employeesPost", id));

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `${employee.firstName} ${employee.lastName}'s data has been deleted.`,
          showConfirmButton: false,
          timer: 1500,
        });

        const employeesCopy = employees.filter(employee => employee.id !== id);
        setEmployees(employeesCopy);
      }
    });
  };


  return (
    <>
      {!isAdding && !isEditing && (
        <>
         <Table
            employees={employees}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
      
        </>
      )}
{isAdding && !isEditing && (
  <Add
    employees={employees}
    setEmployees={setEmployees}
    setIsAdding={setIsAdding}
    getEmployees={() => getEmployeesAndCompanies(userUid)}
  />
)}

{isEditing && !isAdding && (
  <Edit
    employees={employees}
    selectedEmployee={selectedEmployee}
    setEmployees={setEmployees}
    setIsEditing={setIsEditing}
    getEmployees={() => getEmployeesAndCompanies(userUid)}
  />
  )}
     
    </>
  );
};

export default Dashboard;


*/

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { collection, getDocs, doc, deleteDoc, query, where } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Table from './Table';
import Header from './Header';

const Dashboard = ({ setIsAuthenticated }) => {
  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userUid, setUserUid] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUid(user.uid);
        getEmployeesAndCompanies(user.uid);
      } else {
        setUserUid(null);
        setEmployees([]);
        setCompanies([]);
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, [auth, setIsAuthenticated]);

  const getEmployeesAndCompanies = async (uid) => {
    const employeesRef = collection(db, "employeesPost");
    const companyRef = collection(db, "company");

    const employeesQuery = query(employeesRef, where("uid", "==", uid));
    const companyQuery = query(companyRef, where("uid", "==", uid));

    try {
      const employeesSnapshot = await getDocs(employeesQuery);
      const employeesData = employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeesData);

      const companiesSnapshot = await getDocs(companyQuery);
      const companiesData = companiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCompanies(companiesData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleDelete = id => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(result => {
      if (result.value) {
        const [employee] = employees.filter(employee => employee.id === id);

        deleteDoc(doc(db, "employeesPost", id));

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `${employee.firstName} ${employee.lastName}'s data has been deleted.`,
          showConfirmButton: false,
          timer: 1500,
        });

        const employeesCopy = employees.filter(employee => employee.id !== id);
        setEmployees(employeesCopy);
      }
    });
  };

  return (
    <>
      <Header/> 
      <Table
        employees={employees}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Dashboard;
