/*



import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
 
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './historisUser.css'
import YoutubeSongs from './youtubeSongs/youtubeSongs'

const HistorisUser = () => {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [inputText, setInputText] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    const handleAdd = async (e) => { 
        e.preventDefault();

        if (isSubmitted) {
            return;
        }

        if (!inputText) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const newEmployee = {
            uid: user.uid,
            inputText,
            selectedImage,
            videoPost: videoUrl, // Save the video URL
            createdAt: new Date()
        };

        try {
            await addDoc(collection(db, "HistoryPosts"), newEmployee);

            setIsSubmitted(true);
            navigate("/SearchJob");
        } catch (error) {
            console.log(error);
        }

        Swal.fire({
            icon: 'success',
            title: 'Added!',
            text: `${inputText}'s data has been Added.`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `HistoryPosts/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                setSelectedImage(downloadUrl);

                if (file.type.startsWith('video/')) {
                    setVideoUrl(downloadUrl);
                } else {
                    setVideoUrl(null);
                }
            }
        );
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedImage(file);
        }
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setInputText(e.target.value);
        adjustHeight(e.target);
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setSelectedImage(null);
        setVideoUrl(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container333">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />

                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>
                </div>

                <div className="rowContainer9">
                    <textarea
                        ref={descriptionRef}
                        className="inputPostChido98"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={inputText}
                        onChange={handleDescriptionChange}
                        placeholder={t('What do you have in mind')}
                    />
                </div>
            </div>

            <div className="column333 right-column333"> 
            {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                )}
                
            <div className="con-image-post43" style={{ cursor: "pointer" }}>
                    {videoUrl ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <video controls className="video-resize">
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        selectedImage ? (
                            <div className="media-container">
                                <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                                <img src={typeof selectedImage === 'string' ? selectedImage : URL.createObjectURL(selectedImage)} alt="upload image" className="image-post-add33" />
                            </div>
                        ) : (
                            <div className="con-sin-image-user-post" onClick={handleClick}>
                                <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                    <GrImage />
                                </IconContext.Provider>
                            </div>
                        )
                    )}
                    <input
                        id="image-upload-input"
                        type="file"
                        onChange={(e) => { handleImageChange(e); handleUpload(e); }}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>

            </div>
        </div>
    );
};

export default HistorisUser;


*/


import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import { collection, addDoc, doc, deleteDoc, serverTimestamp } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './historisUser.css';

const HistorisUser = () => {
    const descriptionRef = useRef(null);
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [inputText, setInputText] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    // Function to handle adding a post
    const handleAdd = async (e) => { 
        e.preventDefault();

        if (isSubmitted) return;

        if (!inputText) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        const user = auth.currentUser;
        if (!user) return;

        const newPost = {
            uid: user.uid,
            inputText,
            selectedImage,
            videoPost: videoUrl,
            createdAt: serverTimestamp(),  // Using Firestore's server timestamp
        };

        try {
            const docRef = await addDoc(collection(db, "HistoryPosts"), newPost);

            // Automatically delete the post after 24 hours (86400000 ms)
            setTimeout(async () => {
                try {
                    // Delete the Firestore document
                    await deleteDoc(doc(db, "HistoryPosts", docRef.id));
                    console.log('Post deleted after 24 hours');

                    // Delete the associated image or video from Firebase Storage
                    if (selectedImage || videoUrl) {
                        const fileRef = ref(storage, selectedImage || videoUrl);
                        await deleteObject(fileRef);
                        console.log('Associated media deleted from Firebase Storage');
                    }
                } catch (error) {
                    console.error("Error deleting post or media:", error);
                }
            }, 86400000);  // 24 hours

            setIsSubmitted(true);
            navigate("/SearchJob");

            Swal.fire({
                icon: 'success',
                title: 'Added!',
                text: `${inputText}'s data has been Added.`,
                showConfirmButton: false,
                timer: 1500,
            });

        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle file upload (image/video)
    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `HistoryPosts/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                setSelectedImage(downloadUrl);

                if (file.type.startsWith('video/')) {
                    setVideoUrl(downloadUrl);
                } else {
                    setVideoUrl(null);
                }
            }
        );
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedImage(file);
        }
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setInputText(e.target.value);
    };

    const handleCancel = () => {
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setSelectedImage(null);
        setVideoUrl(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container333">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />
                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>
                </div>

                <div className="rowContainer9">
                    <textarea
                        ref={descriptionRef}
                        className="inputPostChido98"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={inputText}
                        onChange={handleDescriptionChange}
                        placeholder={t('What do you have?')}
                    />
                </div>
            </div>

            <div className="column333 right-column333"> 
                {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="history-progress-bar">
                        <div className="history-progress" style={{ width: `${uploadProgress}%`, height: '20px' }}></div>
                    </div>
                )}

                <div className="con-image-post43" style={{ cursor: "pointer" }}>
                    {videoUrl ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <video controls className="video-resize">
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : selectedImage ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <img src={typeof selectedImage === 'string' ? selectedImage : URL.createObjectURL(selectedImage)} alt="upload image" className="image-post-add33" />
                        </div>
                    ) : (
                        <div className="con-sin-image-user-post" onClick={handleClick}>
                            <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                <GrImage />
                            </IconContext.Provider>
                        </div>
                    )}
                    
                    <input
                        id="image-upload-input"
                        type="file"
                        onChange={(e) => { handleImageChange(e); handleUpload(e); }}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default HistorisUser;



/*


import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, doc, deleteDoc  } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
 
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './historisUser.css'
import YoutubeSongs from './youtubeSongs/youtubeSongs'

const HistorisUser = () => {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [inputText, setInputText] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    const handleAdd = async (e) => { 
        e.preventDefault();
    
        if (isSubmitted) {
            return;
        }
    
        if (!inputText) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }
    
        const user = auth.currentUser;
        if (!user) {
            return;
        }
    
        const newEmployee = {
            uid: user.uid,
            inputText,
            selectedImage,
            videoPost: videoUrl, // Save the video URL
            createdAt: new Date()
        };
    
        try {
            const docRef = await addDoc(collection(db, "HistoryPosts"), newEmployee);  // Save post and get its document reference
    
            // Set a timer to delete the post after 30 seconds (30000 ms)
            setTimeout(async () => {
                try {
                    await deleteDoc(doc(db, "HistoryPosts", docRef.id));  // Delete the post using the document ID
                    console.log('Post deleted after 30 seconds');
                } catch (error) {
                    console.error("Error deleting post:", error);
                }
            }, 30000);
    
            setIsSubmitted(true);
            navigate("/SearchJob");
    
            Swal.fire({
                icon: 'success',
                title: 'Added!',
                text: `${inputText}'s data has been Added.`,
                showConfirmButton: false,
                timer: 1500,
            });
    
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `HistoryPosts/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                setSelectedImage(downloadUrl);

                if (file.type.startsWith('video/')) {
                    setVideoUrl(downloadUrl);
                } else {
                    setVideoUrl(null);
                }
            }
        );
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedImage(file);
        }
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setInputText(e.target.value);
        adjustHeight(e.target);
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setSelectedImage(null);
        setVideoUrl(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container333">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />

                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>
                </div>

                <div className="rowContainer9">
                    <textarea
                        ref={descriptionRef}
                        className="inputPostChido98"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={inputText}
                        onChange={handleDescriptionChange}
                        placeholder={t('What do you have?')}
                    />
                </div>
           
                
            {uploadProgress > 0 && uploadProgress < 100 && (
                            <div className="history-progress-bar">
                                <div className="history-progress" style={{ width: `${uploadProgress}%`, height: '20px' }}></div>
                            </div>
                        )}
         
            <div className="con-image-post43" style={{ cursor: "pointer" }}>


                {videoUrl ? (
                    <div className="media-container">
                 <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                        <video controls className="video-resize">
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ) : (
                    selectedImage ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <img src={typeof selectedImage === 'string' ? selectedImage : URL.createObjectURL(selectedImage)} alt="upload image" className="image-post-add33" />
                        </div>
                    ) : (
                        <div className="con-sin-image-user-post" onClick={handleClick}>
                            <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                <GrImage />
                            </IconContext.Provider>
                        </div>
                    )
                )}


                      
                <input
                    id="image-upload-input"
                    type="file"
                    onChange={(e) => { handleImageChange(e); handleUpload(e); }}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                />
            </div>
            </div>

            
        </div>
    );
};

export default HistorisUser;

*/