/*

import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, doc, setDoc, getDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import './FormCompanyCv.css';
import { FaUser, FaEnvelope, FaPhone, FaCalendarAlt, FaTag, FaGlobe, FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function FormCompanyCv() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    email: '',
    foundedDate: '',
    phone: '',
    tagline: '',
    website: '',
    address: '',
    uid: '',
  });
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  // If the user is authenticated, set the UID in formData
  useEffect(() => {
    if (user) {
      setFormData(prevState => ({
        ...prevState,
        uid: user.uid
      }));
    }
  }, [user]);

  // Load existing form data from Firestore
  useEffect(() => {
    const loadFormData = async () => {
      if (user) {
        const docRef = doc(db, 'CompanyCv', user.uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setFormData(prevState => ({
              ...prevState,
              ...docSnap.data()
            }));
          }
        } catch (error) {
          console.error('Error loading document:', error);
        }
      }
    };
    loadFormData();
  }, [user]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRoomSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const docRef = doc(db, 'CompanyCv', user.uid);
      try {
        await setDoc(docRef, formData, { merge: true });
        setFormSubmitted(true);
        console.log('Document successfully written!');
      } catch (error) {
        console.error('Error saving document:', error);
      }
    }
  };

  const handleContinue = () => {
    navigate('/SearchJob');
  };

  return (
    <div className="ConCompanyCv">
      {!formSubmitted ? (
        <>
          <div className="progressBar45">
            <div className="progress" style={{ width: `${(Object.values(formData).filter(val => val !== '').length / 8) * 100}%` }}></div>
          </div>
          <form className="solicitudJob" onSubmit={handleRoomSubmit}>
            <div className="tCompanyCv">{t("Company Information")}</div>
            <div className="SubTexAplication">{t("Fill out your company's")}</div>
            
            <div className="inputContainer">
              <FaUser className="inputIcon" />
              <input className='inputTextForm' type="text" name="description" onChange={handleChange} value={formData.description} placeholder={t("Company Description")} /> 
            </div>
            
            <div className="inputContainer">
              <FaEnvelope className="inputIcon" />
              <input className="emailSolicitudJob" type="email" name="email" onChange={handleChange} value={formData.email} placeholder={t("Company Email")}/>
            </div>
            
            <div className="inputContainer">
              <FaCalendarAlt className="inputIcon" />
              <input className='inputTextForm' type="text" name="foundedDate" onChange={handleChange} value={formData.foundedDate} placeholder={t("Founded Date")} />
            </div>
            
            <div className="inputContainer">
              <FaPhone className="inputIcon" />
              <input className='inputTextForm' type="text" name="phone" onChange={handleChange} value={formData.phone} placeholder={t("Phone")} />
            </div>
            
            <div className="inputContainer">
              <FaTag className="inputIcon" />
              <input className='inputTextForm' type="text" name="tagline" onChange={handleChange} value={formData.tagline} placeholder={t("Company Tagline")} />
            </div>

            <div className="inputContainer">
              <FaGlobe className="inputIcon" />
              <input className='inputTextForm' type="text" name="website" onChange={handleChange} value={formData.website} placeholder={t("Company Website")} />
            </div>

            <div className="inputContainer">
              <FaMapMarkerAlt className="inputIcon" />
              <input
                className='inputTextForm'
                type="text"
                name="address"
                onChange={handleChange}
                value={formData.address}
                placeholder={t("Company Address")}
              />
            </div>

            <button className='btAplication' type="submit">
               {t("Next")}
            </button>
          </form>
        </>
      ) : (
        <div className="submission-success">
          <div className="cheek">✔</div>
          <h2 className="tCompanyCv">{t("Your Company Information was saved!")}</h2>
          <p>{t("This will assist the company")}</p>
          <button className="btAplication" onClick={handleContinue}>
            {t("Next")}
          </button>
        </div>
      )}
    </div>
  );
}

export default FormCompanyCv;

*/

import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import './FormCompanyCv.css';
import { FaUser, FaEnvelope, FaPhone, FaCalendarAlt, FaTag, FaGlobe, FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function FormCompanyCv() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    email: '',
    foundedDate: '',
    phone: '',
    tagline: '',
    website: '',
    address: '',
    uid: '',
  });
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  // If the user is authenticated, set the UID in formData
  useEffect(() => {
    if (user) {
      setFormData(prevState => ({
        ...prevState,
        uid: user.uid
      }));
    }
  }, [user]);

  // Load existing form data from Firestore
  useEffect(() => {
    const loadFormData = async () => {
      if (user) {
        const docRef = doc(db, 'CompanyCv', user.uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setFormData(prevState => ({
              ...prevState,
              ...docSnap.data()
            }));
          }
        } catch (error) {
          console.error('Error loading document:', error);
        }
      }
    };
    loadFormData();
  }, [user]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRoomSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const docRef = doc(db, 'CompanyCv', user.uid);
      try {
        await setDoc(docRef, formData, { merge: true });
        setFormSubmitted(true);
        console.log('Document successfully written!');
      } catch (error) {
        console.error('Error saving document:', error);
      }
    }
  };

  const handleContinue = () => {
    navigate('/SearchJob');
  };

  return (
    <div className="ConCompanyCv">
      {!formSubmitted ? (
        <>
          <div className="progressBar45">
            <div className="progress" style={{ width: `${(Object.values(formData).filter(val => val !== '').length / 8) * 100}%` }}></div>
          </div>
          <form className="solicitudJob" onSubmit={handleRoomSubmit}>
            <div className="tCompanyCv">{t("Company Information")}</div>
            <div className="SubTexAplication">{t("Fill out your company's")}</div>
            
            <div className="inputContainer">
              <FaUser className="inputIcon" />
              <input className='inputTextForm' type="text" name="description" onChange={handleChange} value={formData.description} placeholder={t("Company Description")} /> 
            </div>
            
            <div className="inputContainer">
              <FaEnvelope className="inputIcon" />
              <input className="emailSolicitudJob" type="email" name="email" onChange={handleChange} value={formData.email} placeholder={t("Company Email")}/>
            </div>
            
            <div className="inputContainer">
              <FaCalendarAlt className="inputIcon" />
              <input className='inputTextForm' type="text" name="foundedDate" onChange={handleChange} value={formData.foundedDate} placeholder={t("Founded Date")} />
            </div>
            
            <div className="inputContainer">
              <FaPhone className="inputIcon" />
              <input className='inputTextForm' type="text" name="phone" onChange={handleChange} value={formData.phone} placeholder={t("Phone")} />
            </div>
            
            <div className="inputContainer">
              <FaTag className="inputIcon" />
              <input className='inputTextForm' type="text" name="tagline" onChange={handleChange} value={formData.tagline} placeholder={t("Company Tagline")} />
            </div>

            <div className="inputContainer">
              <FaGlobe className="inputIcon" />
              <input className='inputTextForm' type="text" name="website" onChange={handleChange} value={formData.website} placeholder={t("Company Website")} />
            </div>

            <div className="inputContainer">
              <FaMapMarkerAlt className="inputIcon" />
              <input
                className='inputTextForm'
                type="text"
                name="address"
                onChange={handleChange}
                value={formData.address}
                placeholder={t("Company Address")}
              />
            </div>

            <button className='btAplication' type="submit">
               {t("Next")}
            </button>
          </form>
        </>
      ) : (
        <div className="submission-success">
          <div className="cheek">✔</div>
          <h2 className="tCompanyCv">{t("Your Company Information was saved!")}</h2>
          <p>{t("This will assist the company")}</p>
          <button className="btAplication" onClick={handleContinue}>
            {t("Next")}
          </button>
        </div>
      )}
    </div>
  );
}

export default FormCompanyCv;
