 /*
import React, { useState } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import './ChatInputFeed.css';

function ChatInputFeed({ selectedChat, currentUid }) {
  const [inputValue, setInputValue] = useState('');

  const sendMessage = async () => {
    if (!inputValue.trim()) return; // Do not send empty messages

    try {
      const db = getFirestore(); // Get Firestore instance
      const chatsCollectionRef = collection(db, 'chats'); // Reference to 'chats1' collection

      const recipient = selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;

      // Check if chat document exists in 'chats1' collection
      const chatQuerySnapshot = await getDocs(query(chatsCollectionRef, where('SendTo', '==', recipient), where('createdBy', '==', currentUid)));
      
      if (chatQuerySnapshot.empty) {
        // If chat document doesn't exist, create a new one
        const chatDocRef = await addDoc(chatsCollectionRef, {
          SendTo: recipient,
          createdBy: currentUid,
        });
        
        // Add the message to the newly created document's 'conversacion' subcollection
        const conversacionCollectionRef = collection(chatDocRef, 'conversacion');
        await addDoc(conversacionCollectionRef, {
          Text: inputValue,
          sentBy: currentUid,
          timestamp: serverTimestamp(),
        });
      } else {
        // If chat document exists, use the first document found
        const chatDocRef = chatQuerySnapshot.docs[0].ref;

        // Add the message to the existing document's 'conversacion' subcollection
        const conversacionCollectionRef = collection(chatDocRef, 'conversacion');
        await addDoc(conversacionCollectionRef, {
          Text: inputValue,
          sentBy: currentUid,
          timestamp: serverTimestamp(),
        });
      }

      setInputValue(''); // Clear input after sending
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="ContInputFeed">
      <input
        type="text"
        className="inputFeed"
        placeholder="Type your message..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress} />
    </div>
  );
}

export default ChatInputFeed;*/
 
import React, { useState } from 'react';
import { getFirestore, collection, addDoc, setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import './ChatInputFeed.css';
import { IoArrowUpCircle } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

function ChatInputFeed({ selectedChat, currentUid }) {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
   
  const sendMessage = async () => {
   
      if (!inputValue.trim()) return;
    
      try {
        const db = getFirestore();
        const chatsCollectionRef = collection(db, 'chats');
    
        const recipient = 
        selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;
        const chatId = currentUid && recipient ? [currentUid, recipient].sort().join('_') : null;
    
        if (!chatId) return;
    
        const chatDocRef = doc(db, 'chats', chatId);
        const chatDoc = await getDoc(chatDocRef);
        if (!chatDoc.exists()) {
          await setDoc(chatDocRef, {
            createdBy: currentUid,
            SendTo: recipient,
          });
        }
    
        const conversacionCollectionRef = collection(chatDocRef, 'conversacion');
        await addDoc(conversacionCollectionRef, {
          Text: inputValue,
          sentBy: currentUid,
          timestamp: serverTimestamp(),
        });
    
        setInputValue(''); // Clear input after sending
       
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };
    

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="ContInputFeed">
      <input  
        type="text"
        className="inputFeed"
        placeholder={t('Send message')}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress} />
        <button onClick={sendMessage} className="sendButtonoffi"> 
           <IoArrowUpCircle className="search-icon" size={55}/>
        </button>
    </div>
  );
}

export default ChatInputFeed;
