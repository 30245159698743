/*


import React from 'react'
import Input from './input/input'

function Comentarios({postId, currentUid}) {
  return (
    <div>
      {currentUid}
        <Input></Input>
        {postId}
       
      </div>
  )
}

export default Comentarios

*/


import React, { useEffect, useState } from 'react';
import { db } from '../../../../firebase/firebase'; // Adjust the path to your Firebase config
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import Input from './input/input';
import './comentarios.css';
import ImgProfile from './imgProfile/imgProfile';

function Comentarios({ postId, currentUid }) {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (!postId) return;

    // Create a Firestore query to fetch comments for the current postId, ordered by creation date
    const commentsRef = collection(db, `posts/${postId}/comments`); // Corrected path here
    const q = query(commentsRef, orderBy('createdAt', 'asc'));

    // Set up a listener to fetch comments in real-time
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedComments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(fetchedComments);
    });

    // Clean up the listener when the component unmounts or postId changes
    return () => unsubscribe();
  }, [postId]);

  return (
    <div className="comments-section">
      <div className="comments-container">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className="comment-card">
              <ImgProfile useruid={comment.userId} />
              <p className="comment-text">{comment.text}</p>
            </div>
          ))
        ) : (
          <p className="no-comments-message">No comments yet. Be the first to comment!</p>
        )}
        <Input postId={postId} currentUid={currentUid} />
      </div>
    </div>
  );
}

export default Comentarios;



/*
import React, { useEffect, useState } from 'react';
import { db } from '../../../../firebase/firebase'; // Adjust the path to your Firebase config
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import Input from './input/input';
import './comentarios.css'
import ImgProfile from './imgProfile/imgProfile';

function Comentarios({ postId, currentUid }) {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (!postId) return;

    // Create a Firestore query to fetch comments for the current postId, ordered by creation date
    const commentsRef = collection(db, `comments/${postId}/commentsBy`);
    const q = query(commentsRef, orderBy('createdAt', 'asc'));

    // Set up a listener to fetch comments in real-time
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedComments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(fetchedComments);
    });

    // Clean up the listener when the component unmounts or postId changes
    return () => unsubscribe();
  }, [postId]);

  return (
    <div className="comments-section">
      <div className="comments-container">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className="comment-card">
              <ImgProfile  useruid={comment.userId}/>
              <p className="comment-text">{comment.text}</p>
            </div>
          ))
        ) : (
          <p className="no-comments-message">No comments yet. Be the first to comment!</p>
        )}

        <Input postId={postId} currentUid={currentUid} />
      </div>
    </div>
  );
}

export default Comentarios;

*/