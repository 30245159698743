import React from 'react';
import { FaShareAlt } from 'react-icons/fa';

function Share({ postId, currentUid }) {
  const postUrl = `https://laboor.website/SearchJob/${postId}`; 

  // Function to copy the URL to the clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(postUrl).then(() => {
      alert('Link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy the link: ', err);
    });
  };

  // Function to share the post using the native share API
  const handleSharePost = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check out this post!',
        url: postUrl
      }).then(() => {
        console.log('Post shared successfully');
      }).catch(err => {
        console.error('Error sharing the post: ', err);
      });
    } else {
      alert('Share API is not supported on your device.');
    }
  };

  return (
    <div className="cont54">
      <button className="save-button" onClick={handleSharePost}>
        <FaShareAlt size={20} />
      </button>
    </div>
  );
}

export default Share;
