/*
import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth  } from '../../../firebase/firebase';
import ChatListFeed from './chatListFeed/ChatListFeed';

import './MainChatFeed.css'

 
import { useNavigate } from 'react-router-dom';

function MainChatFeed() {
  const [chats, setChats] = useState([]);
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;


 
  // Use an object to hold the selected chat details
const [selectedChat, setSelectedChat] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUserUID) {
      console.error("No user logged in");
      return;
    }

    
    const chatsMap = new Map();

    const fetchChats = () => {
      const chatsRef = collection(db, 'chats');
      const createdByQuery = query(chatsRef, where('createdBy', '==', currentUserUID));
      const sendToQuery = query(chatsRef, where('SendTo', '==', currentUserUID));

      // Function to handle new chat data and update the map
      const handleNewChatData = (snapshot) => {
        snapshot.docs.forEach(doc => {
          chatsMap.set(doc.id, { id: doc.id, ...doc.data() });
        });
        // Convert the map to an array and update the state
        setChats(Array.from(chatsMap.values()));
      };

      const unsubscribeCreatedBy = onSnapshot(createdByQuery, handleNewChatData);
      const unsubscribeSendTo = onSnapshot(sendToQuery, handleNewChatData);

      // Return the unsubscribe functions to clean up the listeners
      return () => {
        unsubscribeCreatedBy();
        unsubscribeSendTo();
      };
    };

    const unsubscribe = fetchChats();

    // Unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentUserUID]);

  const handleChatSelect = (chat) => {
    setSelectedChat(chat); // Store the entire chat object
  };
  
  return (
      <div className="chatList1">
          <ChatListFeed  currentUid={currentUserUID} />
      </div>
  );
}
*/


import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth  } from '../../../firebase/firebase';
import ChatListFeed from './chatListFeed/ChatListFeed';
import './MainChatFeed.css'

function MainChatFeed() {
  const [chats, setChats] = useState([]);
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;
  

  useEffect(() => {
    if (!currentUserUID) {
      console.error("No user logged in");
      return;
    }
    const chatsMap = new Map();
    const fetchChats = () => {
      const chatsRef = collection(db, 'chats');
      const createdByQuery = query(chatsRef, where('createdBy', '==', currentUserUID));
      const sendToQuery = query(chatsRef, where('SendTo', '==', currentUserUID));

      // Function to handle new chat data and update the map
      const handleNewChatData = (snapshot) => {
        snapshot.docs.forEach(doc => {
          chatsMap.set(doc.id, { id: doc.id, ...doc.data() });
        });
        // Convert the map to an array and update the state
        setChats(Array.from(chatsMap.values()));
      };

      const unsubscribeCreatedBy = onSnapshot(createdByQuery, handleNewChatData);
      const unsubscribeSendTo = onSnapshot(sendToQuery, handleNewChatData);

      // Return the unsubscribe functions to clean up the listeners
      return () => {
        unsubscribeCreatedBy();
        unsubscribeSendTo();
      };
    };

    const unsubscribe = fetchChats();

    return () => {
      unsubscribe();
    };
  }, [currentUserUID]);
  
  return (
      <div className="chatList1">
          <ChatListFeed  currentUid={currentUserUID} />
      </div>
  );
}


export default MainChatFeed;