
/*

import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { db } from '../../../firebase/firebase';
import './marcoHistoria.css'; // Optional: Add a CSS file for styling.

function MarcoHistoria({ uid, children }) {
  const [hasStory, setHasStory] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const checkStoryExists = async () => {
      try {
        const storyQuery = query(collection(db, "HistoryPosts"), where("uid", "==", uid));
        const storySnapshot = await getDocs(storyQuery);
        setHasStory(!storySnapshot.empty);
      } catch (error) {
        console.error("Error checking story existence: ", error);
      }
    };

    if (uid) {
      checkStoryExists();
    }
  }, [uid]);

  // Handle click event to navigate to /stories with the user's uid
  const handleClick = () => {
    if (hasStory) {
      navigate(`/stories?uid=${uid}`); // Navigate to the /stories route with the uid as a query parameter
    }
  };

  return (
    <div 
      className={`marcoHistoria ${hasStory ? 'hasStory' : ''}`} 
      onClick={handleClick} // Add click handler to the container
    >
      {children}
      
      {hasStory && <div className="storyIndicator"></div>}
    </div>
  );
}

export default MarcoHistoria;

*/
import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/firebase';
import './marcoHistoria.css'; // Optional: Add a CSS file for styling.

function MarcoHistoria({ uid, children }) {
  const [hasStory, setHasStory] = useState(false);
  const [firstStoryId, setFirstStoryId] = useState(null); // Store the first story ID
  const navigate = useNavigate();

  useEffect(() => {
    const checkStoryExists = async () => {
      try {
        const storyQuery = query(collection(db, "HistoryPosts"), where("uid", "==", uid));
        const storySnapshot = await getDocs(storyQuery);
        
        if (!storySnapshot.empty) {
          setHasStory(true);
          // Set the first story's ID (or any other logic to select which story to pass)
          setFirstStoryId(storySnapshot.docs[0].id);
        } else {
          setHasStory(false);
        }
      } catch (error) {
        console.error("Error checking story existence: ", error);
      }
    };

    if (uid) {
      checkStoryExists();
    }
  }, [uid]);

  // Handle click event to navigate to /historys-page with the user's uid and the first storyId
  const handleClick = () => {
    if (hasStory && firstStoryId) {
      navigate(`/historys?userId=${uid}&storyId=${firstStoryId}`);
    }
  };

  return (
    <div 
      className={`marcoHistoria ${hasStory ? 'hasStory' : ''}`} 
      onClick={handleClick} // Add click handler to the container
    >
      {children}
      {/* Overlay element */}
      {hasStory && <div className="storyIndicator"></div>}
    </div>
  );
}

export default MarcoHistoria;
