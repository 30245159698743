/*import React, { useState } from 'react';
import { auth } from '../../firebase/firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './SignIn.css';
import { useTranslation } from 'react-i18next';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0); // State to track login attempts
  const [showReset, setShowReset] = useState(false); // State to manage showing the reset password section

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Successful login actions
      setLoginAttempts(0); // Reset the login attempts counter after successful login
      localStorage.setItem('token', userCredential.user.accessToken);
      localStorage.setItem('user', JSON.stringify(userCredential.user));
      navigate("/SearchJob");
    } catch (error) {
      // Failed login actions
      setErrorMessage(error.message);
      setLoginAttempts(prev => prev + 1); // Increment login attempts counter
    }
  }

  const handlePasswordReset = async () => {
    if (!resetEmail) {
      setErrorMessage('Please enter your email address for password reset.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setErrorMessage('Password reset email sent! Please check your inbox.');
      setShowReset(false); // Hide the reset password section after sending email
      setLoginAttempts(0); // Reset login attempts counter after password reset email is sent
      setResetEmail(''); // Clear the reset email field
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className='login343'>
     
     
      {!showReset && (
        <form style={{ maxWidth: 400, margin: "auto" }} onSubmit={handleSubmit} className='login-form'>
           <h1 style={{ color: '#fff' }}>{t('Login')}</h1>
          <div className='headerBlock__infoText'>
            <input
              className="input-field1"
              type="email"
              placeholder={t('Your Email')}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div className="conSut">
              <input
                className="input-field1"
                type="password"
                placeholder={t('Your Password')}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="conButtonApply">
              <button type="submit" className="glow-on-hover">
                <div className="texANOW">
                  {t('Login')}
                </div>
              </button>
            </div>
          </div>
        </form>
      )}

      {errorMessage && <p style={{margin:20, color: 'red' }}>{errorMessage}</p>}

      {errorMessage && loginAttempts >= 2 && !showReset && (
        <button onClick={() => setShowReset(true)} className="minimalistic-button">
           {t('Want to reset your password?')}
        </button>
      )}

      {showReset && (
            <div className="conSut1 centered-content">

              <div className="con-tit">
                  
                  <h3>{t('If you need to reset your password')}</h3>
                  <p>{t('Please enter your email below and click')}</p>

                </div>
            <input
              className="input-field1"
              type="email"
              placeholder="Enter email for password reset"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <div className="button-column">
                  <button className="minimalistic-button" onClick={handlePasswordReset}>Reset Password</button>
                  <button onClick={() => setShowReset(false)} className="minimalistic-button">
                  {t('Cancel')}
                  </button>
                </div>
            </div>
      )}

      <p style={{ color: '#fff' }} >{t('Need to Sign up')} <Link style={{ color: '#9741F2' }} to="/SignUp">{t('Create Account')}</Link></p>
      <p className="contUs"><Link style={{ color: '#999' }} to="/ContactUs">{t('Help')}</Link></p>
      
    </div>
  )
}

export default SignIn;*/
import React, { useState } from 'react';
import { auth } from '../../firebase/firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './SignIn.css';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Import icons

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0); // State to track login attempts
  const [showReset, setShowReset] = useState(false); // State to manage showing the reset password section
  const [passwordVisible, setPasswordVisible] = useState(false); // State to manage password visibility

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Successful login actions
      setLoginAttempts(0); // Reset the login attempts counter after successful login
      localStorage.setItem('token', userCredential.user.accessToken);
      localStorage.setItem('user', JSON.stringify(userCredential.user));
      navigate("/SearchJob");
    } catch (error) {
      // Failed login actions
      setErrorMessage(error.message);
      setLoginAttempts(prev => prev + 1); // Increment login attempts counter
    }
  }

  const handlePasswordReset = async () => {
    if (!resetEmail) {
      setErrorMessage('Please enter your email address for password reset.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setErrorMessage('Password reset email sent! Please check your inbox.');
      setShowReset(false); // Hide the reset password section after sending email
      setLoginAttempts(0); // Reset login attempts counter after password reset email is sent
      setResetEmail(''); // Clear the reset email field
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className='login343'>
      {!showReset && (
        <form style={{ maxWidth: 400, margin: "auto" }} onSubmit={handleSubmit} className='login-form'>
          <h1 style={{ color: '#fff' }}>{t('Login')}</h1>
          <div className='headerBlock__infoText'>
            <div className="input-field1">
              <input
                className="password-input"
                type="email"
                placeholder={t('Your Email')}
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-field1 input-pass-cont">
              <input
                className="password-input"
                type={passwordVisible ? "text" : "password"}
                placeholder={t('Your Password')}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="toggle-password"
              >
                {passwordVisible ? <FaEyeSlash style={{ fill: '#000' }} /> : <FaEye style={{ fill: '#000' }} />}
              </button>
            </div>

            <div className="conButtonApply">
              <button type="submit" className="glow-on-hover">
                <div className="texANOW">
                  {t('Login')}
                </div>
              </button>
            </div>
          </div>
        </form>
      )}

      {errorMessage && <p style={{ margin: 20, color: 'red' }}>{errorMessage}</p>}

      {errorMessage && loginAttempts >= 2 && !showReset && (
        <button onClick={() => setShowReset(true)} className="minimalistic-button">
          {t('Want to reset your password?')}
        </button>
      )}

      {showReset && (
        <div className="conSut1 centered-content">
          <div className="con-tit">
            <h3>{t('If you need to reset your password')}</h3>
            <p>{t('Please enter your email below and click')}</p>
          </div>
          <input
            className="input-field1"
            type="email"
            placeholder="Enter email for password reset"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
          <div className="button-column">
            <button className="minimalistic-button" onClick={handlePasswordReset}>Reset Password</button>
            <button onClick={() => setShowReset(false)} className="minimalistic-button">
              {t('Cancel')}
            </button>
          </div>
        </div>
      )}

      <p style={{ color: '#fff' }}>{t('Need to Sign up')} <Link style={{ color: '#9741F2' }} to="/SignUp">{t('Create Account')}</Link></p>
      <p className="contUs"><Link style={{ color: '#444' }} to="/ContactUs">{t('Help')}</Link></p>
    </div>
  )
}

export default SignIn;
