
/*


import React, { useState, useEffect } from 'react';
import { FaMoneyBillWave, FaBriefcase, FaClock, FaLaptopHouse } from 'react-icons/fa';
import CompanyCv from '../CompanyCv/CompanyCv';
import UserCv from '../UserCv/UserCv.js';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import './UiCards.css';
import ProfileImage from '../ProfileImage/ProfileImage.js';
import MapsLocation from '../UiCards/mapsLocation/mapsLocation.js';
import Comentarios from './comentarios/comentarios.js';
import Save from './save/save.js';
import Share from './share/share.js';
import Love from './love/love.js';
import { useNavigate } from 'react-router-dom'; // If you are using react-router for navigation
import { onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth
import { auth } from '../../../firebase/firebase'; // Import your auth instance
import SignUp from '../../../pages/SignUp.js'

function UiCards({ currentCard, currentUid, postId }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    // Check if user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleAuthCheck = (action) => {
    if (!isAuthenticated) {
      setShowSignUpModal(true); // Show the full-screen modal
      return false; // Prevent the action from continuing
    }
    return true; // Allow the action to continue
  };

  if (!currentCard) return null;

  return (
    <div className="post-details-43">
      <ProfileImage uid={currentCard.uid} />
      <div className="post-column-user-43">
        {currentCard.city && (
          <div className="post-likes-43">{currentCard.city}</div>
        )}
        {currentCard.firstName ? (
          <SendMessage uidThePost={currentCard.uid} currentUid={currentUid} />
        ) : (
          <SendMessageCompany uidThePost={currentCard.uid} currentUid={currentUid} />
        )}
      </div>

      <div className="post-column">
        {currentCard.salary && (
          <div className="post-icon-text">
            <FaMoneyBillWave size={20} />
            <p className="post-des2-43">{currentCard.salary}</p>
          </div>
        )}
      </div>

      <div className="post-columnr">
        {currentCard.positionJob || currentCard.descriptionCompany || currentCard.title ? (
          <div className="post-icon-text">
            <FaBriefcase size={20} />
            <p className="post-des2-43">{currentCard.positionJob || currentCard.descriptionCompany || currentCard.title}</p>
          </div>
        ) : null}
      </div>

      <div className="post-column">
        {currentCard.jobType && (
          <div className="post-icon-text">
            <FaClock size={20} />
            <p className="post-des2-43">{currentCard.jobType}</p>
          </div>
        )}
        {currentCard.workModality && (
          <div className="post-icon-text">
            <FaLaptopHouse size={20} />
            <p className="post-des2-43">{currentCard.workModality}</p>
          </div>
        )}
      </div>

      {currentCard.locationlocation || currentCard.inputText ? (
        <div className="post-columnd">
          <div className="post-icon-text">
            <p className="post-des2-43">{currentCard.description || currentCard.inputText}</p>
          </div>
        </div>
      ) : null}

      <div className="cv-container">
        <CompanyCv key={`company-${currentCard.uid}`} uid={currentCard.uid} />
        <UserCv key={`user-${currentCard.uid}`} uid={currentCard.uid} />

        {currentCard.location && currentCard.location.lat && currentCard.location.lng && (
          <MapsLocation lat={currentCard.location.lat} lng={currentCard.location.lng} />
        )}
      </div>

      <div className="action-row">
       
        <div onClick={() => handleAuthCheck() && <Love key={`${postId}-${currentUid}`} postId={postId} currentUid={currentUid} />}>
          <Love key={`${postId}-${currentUid}`} postId={postId} currentUid={currentUid} />
        </div>
        <div onClick={() => handleAuthCheck() && <Save postId={postId} currentUid={currentUid} />}>
          <Save postId={postId} currentUid={currentUid} />
        </div>
        <div onClick={() => handleAuthCheck() && <Share postId={postId} currentUid={currentUid} />}>
          <Share postId={postId} currentUid={currentUid} />
        </div>
      </div>

      <div className="con-comen">
        <Comentarios postId={postId} currentUid={currentUid} />
      </div>

       
      {showSignUpModal && (
  <div className="full-screen-modal1">
    <div className="modal-content1">
      <h2 className="modal-title">Sign Up</h2>
      <p className="modal-message">Please sign up to interact with this post.</p>
      <button className="sign-up-button1" onClick={() => navigate('/signup')}>Sign Up</button>
      <button className="close-button1" onClick={() => setShowSignUpModal(false)}>Close</button>
    </div>
  </div>
)}

    </div>
  );
}

export default UiCards;



*/
import React, { useEffect, useRef, useState } from 'react';
import { FaMoneyBillWave, FaBriefcase, FaClock, FaLaptopHouse } from 'react-icons/fa';
import CompanyCv from '../CompanyCv/CompanyCv';
import UserCv from '../UserCv/UserCv.js';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import './UiCards.css';
import ProfileImage from '../ProfileImage/ProfileImage.js';
import MapsLocation from '../UiCards/mapsLocation/mapsLocation.js';
import Comentarios from './comentarios/comentarios.js';
import Save from './save/save.js';
import Share from './share/share.js';
import Love from './love/love.js';
import { useNavigate } from 'react-router-dom'; // If you are using react-router for navigation
import { onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth
import { auth } from '../../../firebase/firebase'; // Import your auth instance
import SignUp from '../../../pages/SignUp.js'

function UiCards({ currentCard, currentUid, postId }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const navigate = useNavigate(); // Use navigate for redirection

  const [isScrollable, setIsScrollable] = useState(false);
  const postDetailsRef = useRef(null);

  useEffect(() => {
    // Check if content height exceeds the viewport height
    if (postDetailsRef.current) {
      const contentHeight = postDetailsRef.current.scrollHeight;
      const viewHeight = window.innerHeight;
      setIsScrollable(contentHeight > viewHeight);
    }
  }, [currentCard]);

  useEffect(() => {
    // Check if user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleAuthCheck = (action) => {
    if (!isAuthenticated) {
      setShowSignUpModal(true); // Show the full-screen modal
      return false; // Prevent the action from continuing
    }
    return true; // Allow the action to continue
  };

  if (!currentCard) return null;
  const handleProfileClick = (uid) => {
    navigate(`/Perfil/${uid}`);
  };

  return (
    <div
    ref={postDetailsRef}
    className={`post-details-43 ${isScrollable ? 'scrollable' : ''}`} // Add scrollable class conditionally
  >
    <div onClick={() => handleProfileClick(currentCard.uid)} style={{ cursor: 'pointer' }}>
        <ProfileImage uid={currentCard.uid} className="post-image-profile-43" />
    </div>

    <div className="post-column-user-43">
      {currentCard.city && (
        <div className="post-likes-43">{currentCard.city}</div>
      )}
      {currentCard.firstName ? (
        <SendMessage uidThePost={currentCard.uid} currentUid={currentUid} />
      ) : (
        <SendMessageCompany uidThePost={currentCard.uid} currentUid={currentUid} />
      )}
    </div>

    <div className="post-column">
      {currentCard.salary && (
        <div className="post-icon-text">
          <FaMoneyBillWave size={20} />
          <p className="post-des2-43">{currentCard.salary}</p>
        </div>
      )}
    </div>

    <div className="post-columnr">
      {(currentCard.positionJob || currentCard.descriptionCompany || currentCard.title) && (
        <div className="post-icon-text">
          <FaBriefcase size={20} />
          <p className="post-des2-43">{currentCard.positionJob || currentCard.descriptionCompany || currentCard.title}</p>
        </div>
      )}
    </div>

    <div className="post-column">
      {currentCard.jobType && (
        <div className="post-icon-text">
          <FaClock size={20} />
          <p className="post-des2-43">{currentCard.jobType}</p>
        </div>
      )}
      {currentCard.workModality && (
        <div className="post-icon-text">
          <FaLaptopHouse size={20} />
          <p className="post-des2-43">{currentCard.workModality}</p>
        </div>
      )}
    </div>

    {(currentCard.location || currentCard.inputText) && (
      <div className="post-columnd">
        <div className="post-icon-text">
          <p className="post-des2-43">{currentCard.description || currentCard.inputText}</p>
        </div>
      </div>
    )}

    <div className="cv-container">
      <CompanyCv key={`company-${currentCard.uid}`} uid={currentCard.uid} />
      <UserCv key={`user-${currentCard.uid}`} uid={currentCard.uid} />

      {currentCard.location && currentCard.location.lat && currentCard.location.lng && (
        <MapsLocation lat={currentCard.location.lat} lng={currentCard.location.lng} />
      )}
    </div>

    <div className="action-row">
      {/* Wrap actions with handleAuthCheck to ensure only authenticated users can interact */}
      <div onClick={() => handleAuthCheck() && <Love key={`${postId}-${currentUid}`} postId={postId} currentUid={currentUid} />}>
        <Love key={`${postId}-${currentUid}`} postId={postId} currentUid={currentUid} />
      </div>
      <div onClick={() => handleAuthCheck() && <Save postId={postId} currentUid={currentUid} />}>
        <Save postId={postId} currentUid={currentUid} />
      </div>
      <div onClick={() => handleAuthCheck() && <Share postId={postId} currentUid={currentUid} />}>
        <Share postId={postId} currentUid={currentUid} />
      </div>
    </div>

    <div className="con-comen">
      <Comentarios postId={postId} currentUid={currentUid} />
    </div>

    {/* Full-Screen Sign-Up Modal */}
    {showSignUpModal && (
      <div className="full-screen-modal1">
        <div className="modal-content1">
          <h2 className="modal-title">Sign Up</h2>
          <p className="modal-message">Please sign up to interact with this post.</p>
          <button className="sign-up-button1" onClick={() => navigate('/signup')}>Sign Up</button>
          <button className="close-button1" onClick={() => setShowSignUpModal(false)}>Close</button>
        </div>
      </div>
    )}
  </div>
  );
}

export default UiCards;
