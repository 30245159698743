/*


import React, { useState } from 'react';
import { getFirestore, collection, addDoc, setDoc, getDoc, doc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import './ChatInput.css';
import { IoArrowUpCircle } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

function ChatInput({ selectedChat, currentUid }) {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const sendMessageWeb = async () => {
    if (!inputValue.trim()) return;

    try {
      const db = getFirestore();

      // Determine the recipient based on the selected chat
      const recipient = 
        selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;
      const chatId = currentUid && recipient ? [currentUid, recipient].sort().join('_') : null;

      if (!chatId) return;

      // Check if the chat document exists, if not, create it
      const chatDocRef = doc(db, 'chats', chatId);
      const chatDoc = await getDoc(chatDocRef);
      if (!chatDoc.exists()) {
        await setDoc(chatDocRef, {
          createdBy: currentUid,
          SendTo: recipient,
        });
      }

      // Add the message to the conversation collection
      const conversacionCollectionRef = collection(chatDocRef, 'conversacion');
      await addDoc(conversacionCollectionRef, {
        Text: inputValue,
        sentBy: currentUid,
        timestamp: serverTimestamp(),
      });

      // Clear input after sending
      setInputValue('');

      // Fetch current user's firstName and imgPerfil from the infoUser collection
      const userQuery = query(collection(db, "infoUser"), where("uid", "==", currentUid));
      const userSnapshot = await getDocs(userQuery);

      let currentUserFirstName = 'Unknown';
      let currentUserImgPerfil = 'default_profile_image_url';
      if (!userSnapshot.empty) {
        userSnapshot.forEach((doc) => {
          currentUserFirstName = doc.data().firstName || 'Unknown';
          currentUserImgPerfil = doc.data().imgPerfil || 'default_profile_image_url';
        });
      } else {
        console.warn(`No user info found for uid: ${currentUid}`);
      }

      // Fetch current user's company name from the company collection
      const companyQuery = query(collection(db, "company"), where("uid", "==", currentUid));
      const companySnapshot = await getDocs(companyQuery);

      let currentUserNameCompany = '';
      if (!companySnapshot.empty) {
        companySnapshot.forEach((doc) => {
          currentUserNameCompany = doc.data().nameCompany || '';
        });
      } else {
        console.warn(`No company info found for uid: ${currentUid}`);
      }

      // Prepare the request body
      const requestBody = {
        chatId: chatId,
        sentBy: currentUid,
        sendTo: recipient,
        text: inputValue,
        firstName: currentUserFirstName,
        nameCompany: currentUserNameCompany,
        imgPerfil: currentUserImgPerfil,
      };
       
      // Notify the server
      const response = await fetch('https://us-central1-jobsdatabace.cloudfunctions.net/notification/notify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        console.error('Failed to notify server');
      } else {
        console.log('Notification sent successfully');
      }

    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessageWeb();
    }
  };

  return (
    <div className='inputTextCon'>
      <textarea
        type="text"
        className="inputText"
        placeholder={t('Send message')}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress} />
      <button onClick={sendMessageWeb} className="sendButtonoffi">
        <IoArrowUpCircle className="search-icon" size={55} />
      </button>
    </div>
  );
}

export default ChatInput;



*/

import React, { useState } from 'react';
import { getFirestore, collection, addDoc, setDoc, getDoc, doc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import './ChatInput.css';
import { IoArrowUpCircle } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

function ChatInput({ selectedChat, currentUid }) {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const sendMessageWeb = async () => {
    if (!inputValue.trim()) return;
  
    try {
      const db = getFirestore();
  
      // Determine the recipient based on the selected chat
      const recipient = 
        selectedChat.SendTo === currentUid ? selectedChat.createdBy : selectedChat.SendTo;
      const chatId = currentUid && recipient ? [currentUid, recipient].sort().join('_') : null;
  
      if (!chatId) return;
  
      // Check if the chat document exists, if not, create it
      const chatDocRef = doc(db, 'chats', chatId);
      const chatDoc = await getDoc(chatDocRef);
      if (!chatDoc.exists()) {
        await setDoc(chatDocRef, {
          createdBy: currentUid,
          SendTo: recipient,
        });
      }
  
      // Add the message to the conversation collection
      const conversacionCollectionRef = collection(chatDocRef, 'conversacion');
      await addDoc(conversacionCollectionRef, {
        Text: inputValue,
        sentBy: currentUid,
        timestamp: serverTimestamp(),
      });
  
      // Clear input after sending
      setInputValue('');
  
      // Fetch current user's firstName and imgPerfil from the infoUser collection
      const userQuery = query(collection(db, "infoUser"), where("uid", "==", currentUid));
      const userSnapshot = await getDocs(userQuery);
  
      let currentUserFirstName = 'Unknown';
      let currentUserImgPerfil = 'default_profile_image_url';
      if (!userSnapshot.empty) {
        userSnapshot.forEach((doc) => {
          currentUserFirstName = doc.data().firstName || 'Unknown';
          currentUserImgPerfil = doc.data().imgPerfil || 'default_profile_image_url';
        });
      } else {
        console.warn(`No user info found for uid: ${currentUid}`);
      }
  
      // Fetch current user's company name from the company collection
      const companyQuery = query(collection(db, "company"), where("uid", "==", currentUid));
      const companySnapshot = await getDocs(companyQuery);
  
      let currentUserNameCompany = '';
      if (!companySnapshot.empty) {
        companySnapshot.forEach((doc) => {
          currentUserNameCompany = doc.data().nameCompany || '';
        });
      } else {
        console.warn(`No company info found for uid: ${currentUid}`);
      }
  
      // Prepare the request body to notify the server
      const requestBody = {
        chatId: chatId,
        sentBy: currentUid,
        sendTo: recipient,
        text: inputValue,
        firstName: currentUserFirstName,
        nameCompany: currentUserNameCompany,
        imgPerfil: currentUserImgPerfil,
      };
      
      // Notify the server
      const response = await fetch('https://us-central1-jobsdatabace.cloudfunctions.net/notification/notify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        console.error('Failed to notify server');
      } else {
        console.log('Notification sent successfully');
      }
  
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessageWeb();
    }
  };

  return (
    <div className='inputTextCon'>
      <textarea
        type="text"
        className="inputText"
        placeholder={t('Send message')}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress} />
      <button onClick={sendMessageWeb} className="sendButtonoffi">
        <IoArrowUpCircle className="search-icon" size={55} />
      </button>
    </div>
  );
}

export default ChatInput;
