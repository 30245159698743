/*

import React, { useState, useEffect } from 'react';
import { db } from '../../../../firebase/firebase'; // Ensure your Firebase config is correctly imported
import { FaHeart } from 'react-icons/fa'; // Optional: Icon for the like button
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'; // Import Firestore functions

function Love({ postId, currentUid }) {
  const [likeCount, setLikeCount] = useState(0); // State for the number of likes
  const [userHasLiked, setUserHasLiked] = useState(false); // Track if the user has liked the post

  // Fetch initial like count and user like status from Firestore when the component mounts
  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const postRef = doc(db, 'likes', postId); // Reference to the specific post document
        const postSnapshot = await getDoc(postRef);

        if (postSnapshot.exists()) {
          const postData = postSnapshot.data();
          setLikeCount(postData.likes || 0); // Set the like count
          
          // Check if the user has already liked the post by checking the `likedBy` array
          const userLikes = postData.likedBy || [];
          setUserHasLiked(userLikes.includes(currentUid)); // Check if user is in the likedBy array
        } else {
          // Document doesn't exist; you may want to create it with initial data
          await setDoc(postRef, { likes: 0, likedBy: [] });
        }
      } catch (error) {
        console.error('Error fetching likes: ', error);
      }
    };

    fetchLikes();
  }, [postId, currentUid]); // Re-run effect if postId or currentUid changes

  // Function to handle like button click
  const handleLikeClick = async () => {
    try {
      const postRef = doc(db, 'likes', postId); // Reference to the specific post document

      if (userHasLiked) {
        // Unlike: Decrease the like count and remove the user from the likedBy array
        await updateDoc(postRef, {
          likes: likeCount - 1,
          likedBy: arrayRemove(currentUid) // Remove user ID from likedBy array
        });
        setLikeCount((prev) => prev - 1); // Update local state
      } else {
        // Like: Increase the like count and add the user to the likedBy array
        await updateDoc(postRef, {
          likes: likeCount + 1,
          likedBy: arrayUnion(currentUid) // Add user ID to likedBy array
        });
        setLikeCount((prev) => prev + 1); // Update local state
      }

      setUserHasLiked((prev) => !prev); // Toggle the liked state
    } catch (error) {
      console.error('Error updating likes: ', error);
    }
  };

  return (
    <div className="cont54"> 
      <button 
        className={`save-button ${userHasLiked ? 'saved' : ''}`} 
        onClick={handleLikeClick}
      >
        <FaHeart color={userHasLiked ? '#c6f312' : 'gray'} size={24} /> 
        <span>{likeCount}</span> 
      </button>
    </div>
  );
}

export default Love;

*/

import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../../firebase/firebase'; // Ensure Firebase config is correctly imported
import { FaHeart } from 'react-icons/fa'; // Optional: Icon for the like button
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'; // Firestore functions
import { onAuthStateChanged } from 'firebase/auth'; // Firebase auth listener

function Love({ postId }) {
  const [likeCount, setLikeCount] = useState(0); // State for the number of likes
  const [userHasLiked, setUserHasLiked] = useState(false); // Track if the user has liked the post
  const [currentUid, setCurrentUid] = useState(null); // State to track authenticated user's UID
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track if the user is authenticated

  // Check if user is authenticated
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUid(user.uid); // Set the authenticated user's UID
        setIsAuthenticated(true); // User is authenticated
      } else {
        setCurrentUid(null); // No user is authenticated
        setIsAuthenticated(false); // User is not authenticated
      }
    });

    // Clean up the auth listener on unmount
    return () => unsubscribe();
  }, []);

  // Fetch initial like count and user like status from Firestore when the component mounts
  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const postRef = doc(db, 'likes', postId); // Reference to the specific post document
        const postSnapshot = await getDoc(postRef);

        if (postSnapshot.exists()) {
          const postData = postSnapshot.data();
          setLikeCount(postData.likes || 0); // Set the like count
          
          // Check if the user has already liked the post by checking the `likedBy` array
          const userLikes = postData.likedBy || [];
          setUserHasLiked(userLikes.includes(currentUid)); // Check if user is in the likedBy array
        } else {
          // Document doesn't exist; create it with initial data
          await setDoc(postRef, { likes: 0, likedBy: [] });
        }
      } catch (error) {
        console.error('Error fetching likes: ', error);
      }
    };

    if (currentUid) {
      fetchLikes(); // Only fetch likes if the user is authenticated
    }
  }, [postId, currentUid]); // Re-run effect if postId or currentUid changes

  // Function to handle like button click
  const handleLikeClick = async () => {
    try {
      const postRef = doc(db, 'likes', postId); // Reference to the specific post document

      if (userHasLiked) {
        // Unlike: Decrease the like count and remove the user from the likedBy array
        await updateDoc(postRef, {
          likes: likeCount - 1,
          likedBy: arrayRemove(currentUid) // Remove user ID from likedBy array
        });
        setLikeCount((prev) => prev - 1); // Update local state
      } else {
        // Like: Increase the like count and add the user to the likedBy array
        await updateDoc(postRef, {
          likes: likeCount + 1,
          likedBy: arrayUnion(currentUid) // Add user ID to likedBy array
        });
        setLikeCount((prev) => prev + 1); // Update local state
      }

      setUserHasLiked((prev) => !prev); // Toggle the liked state
    } catch (error) {
      console.error('Error updating likes: ', error);
    }
  };

  return (
    <div className="cont54">
      {isAuthenticated ? (
        <button 
          className={`save-button ${userHasLiked ? 'saved' : ''}`} 
          onClick={handleLikeClick}
        >
          <FaHeart color={userHasLiked ? '#c6f312' : 'gray'} size={24} /> 
          <span>{likeCount}</span>
        </button>
      ) : (
        <button  className="save-button"><FaHeart color={userHasLiked ? '#c6f312' : 'gray'} size={24} /></button> // Show message if user is not logged in
      )}
    </div>
  );
}

export default Love;

