/*
import React from 'react'
import ImagesButton from './imagesButton/ImagesButton'
import PdfButton from './pdfButton/PdfButton'
import VideosButton from './videosButton/VideosButton'
import './BottonesFiles.css';
 
function BottonesFiles({ selectedChat, currentUid, messages, setMessages }) {
  return (
    <div>
      <ImagesButton 
        selectedChat={selectedChat} 
        currentUid={currentUid} 
        messages={messages} 
        setMessages={setMessages}
      />
      <PdfButton 
        selectedChat={selectedChat} 
        currentUid={currentUid} 
        messages={messages} 
        setMessages={setMessages}
      />
      <VideosButton 
        selectedChat={selectedChat} 
        currentUid={currentUid} 
        messages={messages} 
        setMessages={setMessages}
      />
    </div>
  )
}

export default BottonesFiles
*/import React, { useState } from 'react';
import ImagesButton from './imagesButton/ImagesButton';
import PdfButton from './pdfButton/PdfButton';
import VideosButton from './videosButton/VideosButton';
import './BottonesFiles.css';
import { IoIosAdd } from "react-icons/io";

function BottonesFiles({ selectedChat, currentUid, messages, setMessages }) {
  const [showButtons, setShowButtons] = useState(false);

  const handleToggle = () => {
    setShowButtons(!showButtons);
  };

  return (
    <div className="toggle-buttons-container">
      <button className="glow-on-hoverPost" onClick={handleToggle}>
        <IoIosAdd className="search-icon" size={30} />
      </button>
      <div className={`buttons-container ${showButtons ? 'active' : ''}`}>
        <ImagesButton 
          selectedChat={selectedChat} 
          currentUid={currentUid} 
          messages={messages} 
          setMessages={setMessages}
        />
        <PdfButton 
          selectedChat={selectedChat} 
          currentUid={currentUid} 
          messages={messages} 
          setMessages={setMessages}
        />
        <VideosButton 
          selectedChat={selectedChat} 
          currentUid={currentUid} 
          messages={messages} 
          setMessages={setMessages}
        />
      </div>
    </div>
  );
}

export default BottonesFiles;
