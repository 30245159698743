/*
import React, { useState } from 'react';
import { db } from '../../../../../firebase/firebase'; // Adjust the path to your Firebase config
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import './input.css'

function Input({ postId, currentUid }) {
  const [inputText, setInputText] = useState('');

  const handleCommentSubmit = async () => {
    if (!postId || !currentUid || !inputText.trim()) {
      console.error('Missing postId, currentUid, or input text');
      return;
    }

    try {
      // Add a new comment to the 'comments' subcollection of the current post
      await addDoc(collection(db, `comments/${postId}/commentsBy`), {
        userId: currentUid,
        text: inputText.trim(),
        createdAt: serverTimestamp(),
      });

      setInputText(''); // Clear the input field after submitting
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Write a comment..."
      />
      <button onClick={handleCommentSubmit}>Submit</button>
    </div>
  );
}

export default Input;
*/
import React, { useState } from 'react';
import { db } from '../../../../../firebase/firebase'; // Adjust the path to your Firebase config
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import './input.css';

function Input({ postId, currentUid }) {
  const [inputText, setInputText] = useState('');

  const handleCommentSubmit = async () => {
    if (!postId || !currentUid || !inputText.trim()) {
      console.error('Missing postId, currentUid, or input text');
      return;
    }

    try {
      // Add a new comment to the 'comments' subcollection of the current post
      await addDoc(collection(db, `posts/${postId}/comments`), { // Corrected path here
        userId: currentUid,
        text: inputText.trim(),
        createdAt: serverTimestamp(),
      });

      setInputText(''); // Clear the input field after submitting
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  return (
    <div className="comment-container">
      <input
        type="text"
        className="comment-input"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Write a comment..."
      />
      <button
        className="comment-button"
        onClick={handleCommentSubmit}
        disabled={!inputText.trim()}
      >
        Submit
      </button>
    </div>
  );
}

export default Input;
