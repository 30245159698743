/*

import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // Update with your Firebase configuration path
import './lastMessage.css';

function LastMessage({ profilesUids, currentUid }) {
  const [lastMessage, setLastMessage] = useState({ text: '', timestamp: null });

  useEffect(() => {
    const fetchLastMessage = async () => {
      try {
        const chatId = `${currentUid}_${profilesUids}`; // Assuming chatId format is currentUid_profilesUids
        const messagesQuery = query(
          collection(db, `chats/${chatId}/conversacion`),
          orderBy('timestamp', 'desc'), // Order messages by timestamp in descending order
          limit(1) // Limit the query to only retrieve the last message
        );
        const querySnapshot = await getDocs(messagesQuery);
        if (!querySnapshot.empty) {
          const lastMessageData = querySnapshot.docs[0].data();
          const { Text, timestamp } = lastMessageData;
          setLastMessage({ text: Text, timestamp: timestamp });
        }
      } catch (error) {
        console.error('Error fetching last message:', error);
      }
    };

    fetchLastMessage();
  }, [currentUid, profilesUids]);

  return (
    <div className="last-message-container">
        <div className="last-message-timestamp">
         {lastMessage.timestamp && new Date(lastMessage.timestamp.seconds * 1000).toLocaleString('en-US', { weekday: 'long', hour: 'numeric', minute: 'numeric' })}
      </div>

      <div className="last-message-text">
        {lastMessage.text && lastMessage.text.length > 20 ? `${lastMessage.text.slice(0, 35)}...` : lastMessage.text}
      </div>
    </div>
  );
}

export default LastMessage;
*/

import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // Update with your Firebase configuration path
import './lastMessage.css';

function LastMessage({ profilesUids, currentUid }) {
  const [lastMessage, setLastMessage] = useState({ text: '', timestamp: null });

  useEffect(() => {
    const fetchLastMessage = async () => {
      try {
        const chatId1 = `${currentUid}_${profilesUids}`; 
        const chatId2 = `${profilesUids}_${currentUid}`; 
    
        const messagesQuery1 = query(
          collection(db, `chats/${chatId1}/conversacion`),
          orderBy('timestamp', 'desc'), 
          limit(1) 
        );
    
        const messagesQuery2 = query(
          collection(db, `chats/${chatId2}/conversacion`),
          orderBy('timestamp', 'desc'), 
          limit(1) 
        );
    
        const querySnapshot1 = await getDocs(messagesQuery1);
        const querySnapshot2 = await getDocs(messagesQuery2);
    
        let lastMessageData;
        if (!querySnapshot1.empty) {
          lastMessageData = querySnapshot1.docs[0].data();
        } else if (!querySnapshot2.empty) {
          lastMessageData = querySnapshot2.docs[0].data();
        }
    
        if (lastMessageData) {
          const { Text, timestamp } = lastMessageData;
          setLastMessage({ text: Text, timestamp: timestamp });
        }
      } catch (error) {
        console.error('Error fetching last message:', error);
      }
    };
    

    fetchLastMessage();
  }, [currentUid, profilesUids]);

  return (
    <div className="last-message-container">
        <div className="last-message-timestamp">
         {lastMessage.timestamp && new Date(lastMessage.timestamp.seconds * 1000).toLocaleString('en-US', { weekday: 'long', hour: 'numeric', minute: 'numeric' })}
      </div>

      <div className="last-message-text">
        {lastMessage.text && lastMessage.text.length > 20 ? `${lastMessage.text.slice(0, 35)}...` : lastMessage.text}
      </div>
    </div>
  );
}

export default LastMessage;
