/*
import React, { useState } from 'react';
import { db } from '../../firebase/firebase';
import { collection, addDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../src/firebase/firebase'; 
import './CompanyCv.css';
import { FaUser, FaEnvelope, FaPhone, FaBriefcase, FaGlobe, FaTransgender, FaIdCard, FaMapMarkerAlt, FaKey } from 'react-icons/fa';

function CompanyCv({ postingUserId }) {
  const [formSubmitted, setFormSubmitted] = useState(false); 

  const [fullName, setFullName] = useState('');
  const [curp, setCurp] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [age, setAge] = useState('');
  const [nationality, setNationality] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [rfc, setRfc] = useState('');
  const [skills, setSkills] = useState('');
  const [englishLevel, setEnglishLevel] = useState('');

  const [user] = useAuthState(auth);
  const userId = user ? user.uid : null;

  const handleRoomSubmit = async (e) => {
    e.preventDefault();

    const roomsRef = collection(db, "solicitud de empleo");

    const newRoomData = {
      fullName,
      curp,
      gender,
      email,
      phone,
      workExperience,
      age,
      nationality,
      postalCode,
      socialSecurityNumber,
      rfc,
      skills,
      englishLevel,
      createdBy: userId,
      postingUser: postingUserId,
      createdAt: new Date()
    };

    try {
      const docRef = await addDoc(roomsRef, newRoomData);
      console.log("Room created with ID: ", docRef.id);
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error creating room: ", error);
    }
  };

  return (
    <div className="ConCompanyCv">
      {!formSubmitted ? (
        <form className="solicitudJob" onSubmit={handleRoomSubmit}>
          <div >Job application</div>
          <div className="SubTexAplication">If you do not have any information, you can fill it out and update it at another time.</div>
          <div className="SubTexAplication">You will find a job before the rooster crows</div>
          
 
          <div className="inputContainer">
            <FaUser className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" />
          </div>
          
          <div className="inputContainer">
            <FaBriefcase className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setWorkExperience(e.target.value)} placeholder="Work Experience" />
          </div>
          
          <div className="inputContainer">
            <FaTransgender className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setGender(e.target.value)} placeholder="Gender"/>
          </div>
          
          <div className="inputContainer">
            <FaGlobe className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
          </div>
          
          <div className="inputContainer">
            <FaMapMarkerAlt className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setPostalCode(e.target.value)} placeholder="Postal Code" />
          </div>
          
          <div className="inputContainer">
            <FaKey className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setEnglishLevel(e.target.value)} placeholder="English Level" />
          </div>
          
         
          <div className="inputContainer">
            <FaEnvelope className="inputIcon" />
            <input className="emailSolicitudJob" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email to contact you" />
          </div>
          
          <div className="inputContainer">
            <FaPhone className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setPhone(e.target.value)} placeholder="Phone to contact you" />
          </div>
          
          
          <div className="inputContainer">
            <FaBriefcase className="inputIcon" />
            <input className='inputTextForm' type="text" onChange={(e) => setSkills(e.target.value)} placeholder="Describe your skills" />
          </div>
          
          <button className='btAplication' type="submit">Submit Application</button>
        </form>
      ) : (
        <div className="submission-success">
          <div className="cheek">✔</div>
          <h2>Thank you for your application!</h2>
          <p>Your information has been received and we will be in touch shortly.</p>
        </div>
      )}
    </div>
  );
}

export default CompanyCv;
*/

import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidenav from '../../components/navigation/Sidenav';
import { useMediaQuery } from 'react-responsive';
import { Resizable } from 're-resizable';
import FormCompanyCv from './FormCompanyCv/FormCompanyCv'

function CompanyCvMain() {
  const location = useLocation();
  const { postId } = location.state || {}; // Destructure postId from location.state
  
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div className="homepage5" style={{ display: 'flex', height: '100vh' }}>
 
      {isMobile ? (
        <div className="homepage__navWraper5">
          <Sidenav />
        </div>
      ) : (
        <Resizable
          className="homepage__navWraper5"
          defaultSize={{ width: '30%', height: '100%' }}
          minWidth="20%"
          maxWidth="70%"
          enable={{ right: true }}
        >
          <Sidenav />
        </Resizable>
      )}
      <div style={{ flex: 1 }}>
        <FormCompanyCv />


      </div>
    </div>
  );
}

export default CompanyCvMain;

