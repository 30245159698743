/*

import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, useLoadScript, Autocomplete } from '@react-google-maps/api';


const mapContainerStyle = {
  width: '100%',
  height: '300px',
  borderRadius:'20px'
};

const center = {
    lat: 32.5149, // Default center (Tijuana)
    lng: -117.0382,
};

function Location() {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [searchLocation, setSearchLocation] = useState(center); // State for searched location
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null); // Ref for input field

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCbM2Gx_OMitbo_IeZ1LA2MEHGuT6bZ2zk', // Replace with your Google Maps API key
    libraries: ['places'], // Add 'places' to use Autocomplete
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const newLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setSearchLocation(newLocation);
      map.panTo(newLocation); // Pan the map to the searched location
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      // Manually trigger place change on enter key
      handlePlaceChanged();
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div>
      <h2 style={{ fontFamily: 'Arial, sans-serif', fontSize:'15px', color: '#fff', marginLeft:'20px', marginBottom:'10px'}}>Job Location</h2>
      <div style={{ marginBottom: '10px' }}>
     
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder="Search for a location"
            style={{
              width: '100%',
              height: '40px',
              fontSize: '16px',
              padding: '20px',
              borderRadius: '16px',
              border: 'none',
              outline: 'none',
              backgroundColor: '#333',
              color: '#fff',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              transition: 'border-color 0.2s',
            }}
            onFocus={(e) => (e.target.style.borderColor = '#333')}
            onBlur={(e) => (e.target.style.borderColor = '#e0e0e0')}
            onKeyPress={handleEnterPress}
          />
        </Autocomplete>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={currentLocation || searchLocation}
        onLoad={onLoad}
      >
        {currentLocation && <Marker position={currentLocation} />}
        {searchLocation && <Marker position={searchLocation} />}
      </GoogleMap>
    </div>
  );
}

export default Location;


*/


import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, useLoadScript, Autocomplete } from '@react-google-maps/api';


const mapContainerStyle = {
  width: '100%',
  height: '300px',
  borderRadius:'20px'
};

const center = {
    lat: 32.5149, // Default center (Tijuana)
    lng: -117.0382,
};

function Location({ onLocationSelect }) {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [searchLocation, setSearchLocation] = useState(center); // State for searched location
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null); // Ref for input field

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCbM2Gx_OMitbo_IeZ1LA2MEHGuT6bZ2zk', // Replace with your Google Maps API key
    libraries: ['places'], // Add 'places' to use Autocomplete
  });

 
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = { lat: latitude, lng: longitude };
          setCurrentLocation(location);
          onLocationSelect(location); // Pass the current location to the parent
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  }, []);

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const newLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setSearchLocation(newLocation);
      onLocationSelect(newLocation); // Pass the selected location to the parent component
      map.panTo(newLocation);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handlePlaceChanged();
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;
  

  return (
    <div>
      <h2 style={{ fontFamily: 'Arial, sans-serif', fontSize:'15px', color: '#fff', marginLeft:'20px', marginBottom:'10px'}}>Job Location</h2>
      <div style={{ marginBottom: '10px' }}>
     
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder="Search for a location"
            style={{
              width: '100%',
              height: '40px',
              fontSize: '16px',
              padding: '20px',
              borderRadius: '16px',
              border: 'none',
              outline: 'none',
              backgroundColor: '#333',
              color: '#fff',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              transition: 'border-color 0.2s',
            }}
            onFocus={(e) => (e.target.style.borderColor = '#333')}
            onBlur={(e) => (e.target.style.borderColor = '#e0e0e0')}
            onKeyPress={handleEnterPress}
          />
        </Autocomplete>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={currentLocation || searchLocation}
        onLoad={onLoad}
      >
        {currentLocation && <Marker position={currentLocation} />}
        {searchLocation && <Marker position={searchLocation} />}
      </GoogleMap>
    </div>
  );
}

export default Location;
