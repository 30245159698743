/*import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

const EditPost = () => {
  const [post, setPost] = useState({ Name: '', Place: '', salary: '', Description: '' });
  const { postId } = useParams(); // Get the postId from the URL
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPostData = async () => {
      if (postId) {
        const postRef = doc(db, 'employeesPost', postId);
        try {
          const docSnap = await getDoc(postRef);
          if (docSnap.exists()) {
            setPost(docSnap.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching post:", error);
        }
      }
    };

    fetchPostData();
  }, [postId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postRef = doc(db, 'employeesPost', postId);
    try {
      await updateDoc(postRef, { ...post });
      navigate('/'); // Navigate back to the home page or wherever you want after the update
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" name="Name" value={post.Name} onChange={handleInputChange} />
      </label>
      <label>
        Place:
        <input type="text" name="Place" value={post.Place} onChange={handleInputChange} />
      </label>
      <label>
        Salary:
        <input type="text" name="salary" value={post.salary} onChange={handleInputChange} />
      </label>
      <label>
        Description:
        <textarea name="Description" value={post.Description} onChange={handleInputChange} />
      </label>
      <button type="submit">Update Post</button>
    </form>
  );
};

export default EditPost;
 

*/

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';

const EditPost = () => {
    const [post, setPost] = useState({ Name: '', Place: '', salary: '', Description: '' });
    const [error, setError] = useState('');
    const { postId } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchPostData = async () => {
        if (postId) {
          const postRef = doc(db, 'employeesPost', postId);
          try {
            const docSnap = await getDoc(postRef);
            if (docSnap.exists()) {
              setPost(docSnap.data());
            } else {
              console.log("No such document!");
              navigate('/'); // Navigate back if there's no document
            }
          } catch (error) {
            console.error("Error fetching post:", error);
            setError('Error fetching post data.');
          }
        }
      };
  
      fetchPostData();
    }, [postId, navigate]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setPost({ ...post, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (validateForm()) {
        const postRef = doc(db, 'employeesPost', postId);
        try {
          await updateDoc(postRef, { ...post });
          navigate('/'); // Navigate back to the home page after the update
        } catch (error) {
          console.error("Error updating post:", error);
          setError('Error updating post.');
        }
      }
    };
  
    // Simple validation function
    const validateForm = () => {
      if (post.Name.trim() && post.Place.trim() && post.salary.trim() && post.Description.trim()) {
        return true;
      }
      setError('All fields are required.');
      return false;
    };

  return (
    <form onSubmit={handleSubmit}>
        {error && <p className="error">{error}</p>}
      <label>
        Name:
        <input type="text" name="Name" value={post.Name} onChange={handleInputChange} />
      </label>
      <label>
        Place:
        <input type="text" name="Place" value={post.Place} onChange={handleInputChange} />
      </label>
      <label>
        Salary:
        <input type="text" name="salary" value={post.salary} onChange={handleInputChange} />
      </label>
      <label>
        Description:
        <textarea name="Description" value={post.Description} onChange={handleInputChange} />
      </label>
      <button type="submit">Update Post</button>
    </form>
  );
};

export default EditPost;



