/*



import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../../firebase/firebase.js';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './company.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Location from '../location/location.js';

const Company = () => {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    const [imgPost, setImgPost] = useState(null);
    const [videoPost, setVideoPost] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [city, setCity] = useState('');
    const [salary, setSalary] = useState('');
    const [workModality, setWorkModality] = useState('');
    const [shift, setShift] = useState('');
    const [jobType, setJobType] = useState('');
    const [suitableFor, setSuitableFor] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    const handleAdd = async (e) => { 
        e.preventDefault();

        if (isSubmitted) {
            return;
        }

        if (!title || !description || !salary || !city || !workModality || !shift || !jobType || !suitableFor) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const newEmployee = {
            uid: user.uid,
            imgPost,
            videoPost,
            title,
            description,
            salary,
            city,
            workModality,
            shift,
            jobType,
            suitableFor,
            createdAt: new Date()
        };

        try {
            await addDoc(collection(db, "employeesPost"), newEmployee);

            setIsSubmitted(true);
            navigate("/SearchJob");
        } catch (error) {
            console.log(error);
        }

        Swal.fire({
            icon: 'success',
            title: 'Added!',
            text: `${title} ${description}'s data has been added.`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `postJobs/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        setUploadProgress(0);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                if (file.type.startsWith('video/')) {
                    setVideoPost(downloadUrl);
                } else {
                    setImgPost(downloadUrl);
                }
                setUploadProgress(0);
            }
        );
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        adjustHeight(e.target);
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setImgPost(null);
        setVideoPost(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container345">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />
                    <div className="textContainer">
                        <p className="text-muted">{t('New Job')}</p>
                    </div>
                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>  
                </div>
                
                <div className="c345">

                <Location/>

                <div className="contenedorTexto">
                    <label htmlFor="name" className="textoCampos22">{t('Title')}</label>
                </div>
                <div>
                    <input
                        className="entrada"
                        id="title"
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="name" className="textoCampos22">{t('Description')}</label>
                    <label className="textoCamposMini">{t('Schedule, Benefits, Responsibilities')}</label>
                </div>
                <div>
                    <textarea
                        ref={descriptionRef}
                        className="entrada"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="city" className="textoCampos22">{t('City')}</label>
                </div>
                <div>
                    <input
                        className="entrada"
                        id="city"
                        type="text"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="salary" className="textoCampos22">{t('Salary')}</label>
                    <label htmlFor="salary" className="textoCamposMini">{t('Weekly salary')}</label>
                </div>
                <div>
                <input
                    className="entrada"
                    id="salary"
                    type="text"
                    name="salary"
                    value={`$${salary}`}
                    onChange={e => {
                        const value = e.target.value.replace(/^\$/, ''); // Remove the dollar sign if present
                        if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and one dot for decimal point
                            setSalary(value);
                        }
                    }}
                />

                </div>

                <div className="rowContainerInputs">
                    <div className="contenedorTexto">
                        <label htmlFor="workModality" className="textoCampos22">{t('Work Modality')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="workModality"
                            name="workModality"
                            onChange={e => setWorkModality(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="On-site">{t('On-site')}</option>
                            <option className="optionTex" value="Work from Home">{t('Work from Home')}</option>
                            <option className="optionTex" value="Remote">{t('Remote')}</option>
                        </select>
                    </div>

                    <div className="contenedorTexto">
                        <label htmlFor="shift" className="textoCampos22">{t('Shift')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="shift"
                            name="shift"
                            onChange={e => setShift(e.target.value)}
                        >
                            <option className="optionTex" value="">{t('Select...')}</option>
                            <option className="optionTex" value="Matutino">{t('Morning Shift')}</option>
                            <option className="optionTex" value="Vespertino">{t('Evening Shift')}</option>
                            <option className="optionTex" value="Nocturno">{t('Night Shift')}</option>
                        </select>
                    </div>
                </div>

                <div className="rowContainerInputs">
                    <div className="contenedorTexto">
                        <label htmlFor="jobType" className="textoCampos22">{t('Schedule')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="jobType"
                            name="jobType"
                            onChange={e => setJobType(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="Full Time">{t('Full Time')}</option>
                            <option className="optionTex" value="Half Time">{t('Part Time')}</option>
                        </select>
                    </div>

                    <div className="contenedorTexto">
                        <label htmlFor="suitableFor" className="textoCampos22">{t('Suitable for')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="suitableFor"
                            name="suitableFor"
                            onChange={e => setSuitableFor(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="Student">{t('Student')}</option>
                            <option className="optionTex" value="Veterans">{t('Veterans')}</option>
                            <option className="optionTex" value="Foreigners">{t('Foreigners')}</option>
                        </select>
                    </div>
                </div>
                </div>
            </div>


            <div className="column345 right-column345"> 

            {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                )}

                <div className="con-image-post" style={{ cursor: "pointer" }}>
                    {videoPost ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <video controls className="video-resize">
                                <source src={videoPost} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        imgPost ? (
                            <div className="media-container">
                                <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                                <img src={typeof imgPost === 'string' ? imgPost : URL.createObjectURL(imgPost)} alt="upload image" className="image-post-add" />
                            </div>
                        ) : (
                            <div className="con-sin-image-user-post" onClick={() => handleClick(hiddenFileInput)}>
                                <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                    <GrImage />
                                </IconContext.Provider>
                            </div>
                        )
                    )}
                    <input
                        id="image-upload-input"
                        type="file"
                        accept="image/*, video/*"
                        onChange={handleUpload}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>

               
            </div>
        </div>
    );
};

export default Company;
import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../../firebase/firebase.js';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './company.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Location from '../location/location.js';

const Company = () => {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    const [imgPost, setImgPost] = useState(null);
    const [videoPost, setVideoPost] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [city, setCity] = useState('');
    const [salary, setSalary] = useState('');
    const [workModality, setWorkModality] = useState('');
    const [shift, setShift] = useState('');
    const [jobType, setJobType] = useState('');
    const [suitableFor, setSuitableFor] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    const handleAdd = async (e) => { 
        e.preventDefault();

        if (isSubmitted) {
            return;
        }

        if (!title || !description || !salary || !city || !workModality || !shift || !jobType || !suitableFor) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const newEmployee = {
            uid: user.uid,
            imgPost,
            videoPost,
            title,
            description,
            salary,
            city,
            workModality,
            shift,
            jobType,
            suitableFor,
            createdAt: new Date()
        };

        try {
            await addDoc(collection(db, "employeesPost"), newEmployee);

            setIsSubmitted(true);
            navigate("/SearchJob");
        } catch (error) {
            console.log(error);
        }

        Swal.fire({
            icon: 'success',
            title: 'Added!',
            text: `${title} ${description}'s data has been added.`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `postJobs/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        setUploadProgress(0);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                if (file.type.startsWith('video/')) {
                    setVideoPost(downloadUrl);
                } else {
                    setImgPost(downloadUrl);
                }
                setUploadProgress(0);
            }
        );
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        adjustHeight(e.target);
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setImgPost(null);
        setVideoPost(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container345">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />
                    <div className="textContainer">
                        <p className="text-muted">{t('New Job')}</p>
                    </div>
                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>  
                </div>
                
                <div className="c345">

                <Location/>

                <div className="contenedorTexto">
                    <label htmlFor="name" className="textoCampos22">{t('Title')}</label>
                </div>
                <div>
                    <input
                        className="entrada"
                        id="title"
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="name" className="textoCampos22">{t('Description')}</label>
                    <label className="textoCamposMini">{t('Schedule, Benefits, Responsibilities')}</label>
                </div>
                <div>
                    <textarea
                        ref={descriptionRef}
                        className="entrada"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="city" className="textoCampos22">{t('City')}</label>
                </div>
                <div>
                    <input
                        className="entrada"
                        id="city"
                        type="text"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="salary" className="textoCampos22">{t('Salary')}</label>
                    <label htmlFor="salary" className="textoCamposMini">{t('Weekly salary')}</label>
                </div>
                <div>
                <input
                    className="entrada"
                    id="salary"
                    type="text"
                    name="salary"
                    value={`$${salary}`}
                    onChange={e => {
                        const value = e.target.value.replace(/^\$/, ''); // Remove the dollar sign if present
                        if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and one dot for decimal point
                            setSalary(value);
                        }
                    }}
                />

                </div>

                <div className="rowContainerInputs">
                    <div className="contenedorTexto">
                        <label htmlFor="workModality" className="textoCampos22">{t('Work Modality')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="workModality"
                            name="workModality"
                            onChange={e => setWorkModality(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="On-site">{t('On-site')}</option>
                            <option className="optionTex" value="Work from Home">{t('Work from Home')}</option>
                            <option className="optionTex" value="Remote">{t('Remote')}</option>
                        </select>
                    </div>

                    <div className="contenedorTexto">
                        <label htmlFor="shift" className="textoCampos22">{t('Shift')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="shift"
                            name="shift"
                            onChange={e => setShift(e.target.value)}
                        >
                            <option className="optionTex" value="">{t('Select...')}</option>
                            <option className="optionTex" value="Matutino">{t('Morning Shift')}</option>
                            <option className="optionTex" value="Vespertino">{t('Evening Shift')}</option>
                            <option className="optionTex" value="Nocturno">{t('Night Shift')}</option>
                        </select>
                    </div>
                </div>

                <div className="rowContainerInputs">
                    <div className="contenedorTexto">
                        <label htmlFor="jobType" className="textoCampos22">{t('Schedule')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="jobType"
                            name="jobType"
                            onChange={e => setJobType(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="Full Time">{t('Full Time')}</option>
                            <option className="optionTex" value="Half Time">{t('Part Time')}</option>
                        </select>
                    </div>

                    <div className="contenedorTexto">
                        <label htmlFor="suitableFor" className="textoCampos22">{t('Suitable for')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="suitableFor"
                            name="suitableFor"
                            onChange={e => setSuitableFor(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="Student">{t('Student')}</option>
                            <option className="optionTex" value="Veterans">{t('Veterans')}</option>
                            <option className="optionTex" value="Foreigners">{t('Foreigners')}</option>
                        </select>
                    </div>
                </div>
                </div>
            </div>


            <div className="column345 right-column345"> 

            {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                )}

                <div className="con-image-post" style={{ cursor: "pointer" }}>
                    {videoPost ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <video controls className="video-resize">
                                <source src={videoPost} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        imgPost ? (
                            <div className="media-container">
                                <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                                <img src={typeof imgPost === 'string' ? imgPost : URL.createObjectURL(imgPost)} alt="upload image" className="image-post-add" />
                            </div>
                        ) : (
                            <div className="con-sin-image-user-post" onClick={() => handleClick(hiddenFileInput)}>
                                <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                    <GrImage />
                                </IconContext.Provider>
                            </div>
                        )
                    )}
                    <input
                        id="image-upload-input"
                        type="file"
                        accept="image/*, video/*"
                        onChange={handleUpload}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>

               
            </div>
        </div>
    );
};

export default Company;


*/


import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { db, storage, auth } from '../../../firebase/firebase.js';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore"; 
import { v4 } from "uuid";
import { GrImage } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './company.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Location from '../location/location.js';

const Company = () => {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    const [imgPost, setImgPost] = useState(null);
    const [videoPost, setVideoPost] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [city, setCity] = useState('');
    const [salary, setSalary] = useState('');
    const [workModality, setWorkModality] = useState('');
    const [shift, setShift] = useState('');
    const [jobType, setJobType] = useState('');
    const [suitableFor, setSuitableFor] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();

    const [location, setLocation] = useState(null); 

    const handleAdd = async (e) => { 
        e.preventDefault();

        if (isSubmitted) {
            return;
        }

        if (!title || !description || !salary || !city || !workModality || !shift || !jobType || !suitableFor) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'All fields are required.',
                showConfirmButton: true,
            });
        }

        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const newEmployee = {
            uid: user.uid,
            imgPost,
            videoPost,
            title,
            description,
            salary,
            city,
            workModality,
            shift,
            jobType,
            suitableFor,
            location,
            createdAt: new Date()
        };

        try {
            await addDoc(collection(db, "employeesPost"), newEmployee);

            setIsSubmitted(true);
            navigate("/SearchJob");
        } catch (error) {
            console.log(error);
        }

        Swal.fire({
            icon: 'success',
            title: 'Added!',
            text: `${title} ${description}'s data has been added.`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `postJobs/${v4()}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        setUploadProgress(0);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading file:", error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                if (file.type.startsWith('video/')) {
                    setVideoPost(downloadUrl);
                } else {
                    setImgPost(downloadUrl);
                }
                setUploadProgress(0);
            }
        );
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        adjustHeight(e.target);
    };

    const handleCancel = () => {
        console.log("Cancel button was clicked");
        navigate("/SearchJob");
    };

    const handleChangeMedia = () => {
        setImgPost(null);
        setVideoPost(null);
        hiddenFileInput.current.value = null;
    };

    return (
        <div className="container345">
            <div className="column345 left-column345 ">
                <div className="rowContainer">
                    
                    <input
                        className="buttonCancel"
                        type="button"
                        value={t('Cancel')}
                        onClick={handleCancel}
                    />
                    <div className="textContainer">
                        <p className="text-muted">{t('New Job')}</p>
                    </div>
                    <div className="contbuttonSudmitAdd">
                        <button className="buttonSudmitAdd" onClick={handleAdd}>
                            {t('Add')}
                        </button>
                    </div>  
                </div>
                
                <div className="c345">

                <Location onLocationSelect={(loc) => setLocation(loc)}/>

                <div className="contenedorTexto">
                    <label htmlFor="name" className="textoCampos22">{t('Title')}</label>
                </div>
                <div>
                    <input
                        className="entrada"
                        id="title"
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="name" className="textoCampos22">{t('Description')}</label>
                    <label className="textoCamposMini">{t('Schedule, Benefits, Responsibilities')}</label>
                </div>
                <div>
                    <textarea
                        ref={descriptionRef}
                        className="entrada"
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="city" className="textoCampos22">{t('City')}</label>
                </div>
                <div>
                    <input
                        className="entrada"
                        id="city"
                        type="text"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                    />
                </div>

                <div className="contenedorTexto">
                    <label htmlFor="salary" className="textoCampos22">{t('Salary')}</label>
                    <label htmlFor="salary" className="textoCamposMini">{t('Weekly salary')}</label>
                </div>
                <div>
                <input
                    className="entrada"
                    id="salary"
                    type="text"
                    name="salary"
                    value={`$${salary}`}
                    onChange={e => {
                        const value = e.target.value.replace(/^\$/, ''); // Remove the dollar sign if present
                        if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and one dot for decimal point
                            setSalary(value);
                        }
                    }}
                />

                </div>

                <div className="rowContainerInputs">
                    <div className="contenedorTexto">
                        <label htmlFor="workModality" className="textoCampos22">{t('Work Modality')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="workModality"
                            name="workModality"
                            onChange={e => setWorkModality(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="On-site">{t('On-site')}</option>
                            <option className="optionTex" value="Work from Home">{t('Work from Home')}</option>
                            <option className="optionTex" value="Remote">{t('Remote')}</option>
                        </select>
                    </div>

                    <div className="contenedorTexto">
                        <label htmlFor="shift" className="textoCampos22">{t('Shift')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="shift"
                            name="shift"
                            onChange={e => setShift(e.target.value)}
                        >
                            <option className="optionTex" value="">{t('Select...')}</option>
                            <option className="optionTex" value="Matutino">{t('Morning Shift')}</option>
                            <option className="optionTex" value="Vespertino">{t('Evening Shift')}</option>
                            <option className="optionTex" value="Nocturno">{t('Night Shift')}</option>
                        </select>
                    </div>
                </div>

                <div className="rowContainerInputs">
                    <div className="contenedorTexto">
                        <label htmlFor="jobType" className="textoCampos22">{t('Schedule')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="jobType"
                            name="jobType"
                            onChange={e => setJobType(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="Full Time">{t('Full Time')}</option>
                            <option className="optionTex" value="Half Time">{t('Part Time')}</option>
                        </select>
                    </div>

                    <div className="contenedorTexto">
                        <label htmlFor="suitableFor" className="textoCampos22">{t('Suitable for')}</label>
                    </div>
                    <div>
                        <select
                            className="entrada"
                            id="suitableFor"
                            name="suitableFor"
                            onChange={e => setSuitableFor(e.target.value)}
                        >
                            <option value="">{t('Select...')}</option>
                            <option className="optionTex" value="Student">{t('Student')}</option>
                            <option className="optionTex" value="Veterans">{t('Veterans')}</option>
                            <option className="optionTex" value="Foreigners">{t('Foreigners')}</option>
                        </select>
                    </div>
                </div>
                </div>
            </div>


            <div className="column345 right-column345"> 

            {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                )}

                <div className="con-image-post" style={{ cursor: "pointer" }}>
                    {videoPost ? (
                        <div className="media-container">
                            <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                            <video controls className="video-resize">
                                <source src={videoPost} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        imgPost ? (
                            <div className="media-container">
                                <button className="change-media-button" onClick={handleChangeMedia}>CHANGE</button>
                                <img src={typeof imgPost === 'string' ? imgPost : URL.createObjectURL(imgPost)} alt="upload image" className="image-post-add" />
                            </div>
                        ) : (
                            <div className="con-sin-image-user-post" onClick={() => handleClick(hiddenFileInput)}>
                                <IconContext.Provider style={{ color: 'blue', padding: '5px' }} value={{ size: '35px' }}>
                                    <GrImage />
                                </IconContext.Provider>
                            </div>
                        )
                    )}
                    <input
                        id="image-upload-input"
                        type="file"
                        accept="image/*, video/*"
                        onChange={handleUpload}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>

               
            </div>
        </div>
    );
};

export default Company;