/*



import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase'; // Import your Firebase configuration
import PostUser from '../../../../src/pages/post/user/user';
import FreeTrial from '../../Suscription/FreeTrial/FreeTrial';
import Login from '../../../pages/Login';
import { useTranslation } from 'react-i18next';
import HistorisUser from '../../../../src/pages/historisUser/historisUser';

function VerificationProfile() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);
  const [currentUid, setCurrentUid] = useState(null); // Add this line
  const [selectedAction, setSelectedAction] = useState(null); // Track user selection
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setCurrentUid(auth.currentUser.uid); // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, [currentUid]);

  // Function to handle the user's action selection
  const handleSelectAction = (action) => {
    setSelectedAction(action);
  };

  return (
    <div style={{ backgroundColor: '#111', 
      width: '100vw', 
      height: '100vh', 
      margin: 0, 
      padding: 0 }}>
      {formType === null && <Login />}

      {!selectedAction && (
        <div className="modalContSetting">
          <button className="texEditA" onClick={() => handleSelectAction('createJob')}>
            {t('Create Job')}
          </button>
          <button className="btn" onClick={() => handleSelectAction('createPost')}>
            {t('Create post')}
          </button>
          <button className="btn" onClick={() => handleSelectAction('createHistory')}>
            {t('Create History')}
          </button>
        </div>
      )}

  
      {selectedAction === 'createJob' && <FreeTrial currentUid={currentUid} />} 
      {selectedAction === 'createPost' && (
        <>
          <PostUser />
        </>
      )}
      {selectedAction === 'createHistory' && <HistorisUser />}
     
    </div>
  );
}

export default VerificationProfile;
*/
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase'; // Import your Firebase configuration
import PostUser from '../../../../src/pages/post/user/user';
import FreeTrial from '../../Suscription/FreeTrial/FreeTrial';
import Login from '../../../pages/Login';
import { useTranslation } from 'react-i18next';
import HistorisUser from '../../../../src/pages/historisUser/historisUser';

function VerificationProfile() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);
  const [currentUid, setCurrentUid] = useState(null); // Add this line
  const [selectedAction, setSelectedAction] = useState(null); // Track user selection
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setCurrentUid(auth.currentUser.uid); // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, [currentUid]);

  // Function to handle the user's action selection
  const handleSelectAction = (action) => {
    setSelectedAction(action);
  };

  return (
    <div style={{ backgroundColor: '#111', 
      width: '100vw', 
      height: '100vh', 
      margin: 0, 
      padding: 0 }}>
      {formType === null && <Login />}

      {!selectedAction && (
        <div className="modalContSetting">
          {/* Show "Create Job" button only if the user is NOT from "infoUser" */}
          {formType !== 'infoUser' && (
            <button className="texEditA" onClick={() => handleSelectAction('createJob')}>
              {t('Create Job')}
            </button>
          )}
          <button className="btn" onClick={() => handleSelectAction('createPost')}>
            {t('Create post')}
          </button>
          <button className="btn" onClick={() => handleSelectAction('createHistory')}>
            {t('Create History')}
          </button>
        </div>
      )}

  
      {selectedAction === 'createJob' && <FreeTrial currentUid={currentUid} />} 
      {selectedAction === 'createPost' && (
        <>
          <PostUser />
        </>
      )}
      {selectedAction === 'createHistory' && <HistorisUser />}
     
    </div>
  );
}

export default VerificationProfile;
